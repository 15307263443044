<ng-select
  bindLabel="name"
  bindValue="code"
  [items]="countries"
  [multiple]="multiple"
  [clearable]="clearable"
  [placeholder]="placeholder"
  [(ngModel)]="selected"
  class="my-selector country-selector"
  (change)="selectionChanged($event)">

  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <!--Single Selection -->
    <ng-container *ngIf="!multiple">
      <lib-country [code]="item.code"></lib-country>
    </ng-container>

    <!--Multiple Selection -->
    <ng-container *ngIf="multiple">
      <span class="multi">
        <lib-country [code]="item.code"></lib-country>
        <mat-icon class="icon" (click)="clear(item)">close</mat-icon>
      </span>
    </ng-container>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <lib-country [code]="item.code"></lib-country>
  </ng-template>
</ng-select>

<div class="input-error">
  <formly-validation-message
    *ngIf="!hideLocalErrorDetection && !formlyAttributes.wrappers.length"
    [field]="formlyAttributes">
  </formly-validation-message>
</div>

<!--<mat-error [id]="null" class="error-section" *ngIf="!hideLocalErrorDetection">-->
<!--  <formly-validation-message [field]="formlyAttributes"></formly-validation-message>-->
<!--</mat-error>-->
