import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';

@Exclude()
export class BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  id: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isDeleted: Boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  rowVersion: number;

  constructor(data: Partial<BaseModel>) {
    Object.assign(this, data);
  }
}
