import { ChangeDetectorRef, Directive, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QuickDialog } from '../components/dialog/dialog/shared/quick-dialog.class';
import { ResultDialog } from '../components/dialog/dialog/shared/result-dialog.interface';
import { singularLabel } from '../helpers/helpers';
import { IActionKey, IError } from '../interfaces';
import { QuickPanelCloseStatus } from '../layout/quick-panel/shared/quick-panel-close-status.enum';
import { EntityCrudService } from '../services/api/entity-crud.service';
import { EntityNavigationService } from '../services/navigation/entity-navigation.service';
import { ToastService } from '../services/toast/toast.service';
import { BaseForm } from './base-form.directive';

type Entity<T> = Pick<T, keyof T> & { id: string; rowVersion: number; isActive?: boolean };

interface IContent {
  title: string;
  description: string;
  toastResponse: string;
}

@Directive()
export abstract class BaseEntityDialogForm<T> extends BaseForm<T> implements OnInit {
  abstract entity: T;
  abstract entityName: string;
  abstract actionKey: IActionKey;

  public redirectTo: boolean = true;

  public dialogRef: MatDialogRef<T>;
  public isEdit: boolean;

  get label(): string {
    return this.isEdit ? 'update' : 'create';
  }

  get singularEntity(): string {
    return this.entityName ? singularLabel(this.entityName) : '';
  }

  constructor(
    public mdDialogRef: MatDialogRef<T>,
    public toastService: ToastService,
    public cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: QuickDialog,
    public entityService: EntityCrudService<T>,
    public entityNavigation: EntityNavigationService<T>,
  ) {
    super(cdr, toastService, null);
    this.dialogRef = this.mdDialogRef;
    this.isEdit = !!this.data.gridRowIndex || !!this.data.id;
  }

  ngOnInit(): void {
    this.entity = { ...this.data } as T;

    this.cdr.markForCheck();
    this.preventPickList ? this.createForm() : this.callEntityPickListEndpoint();
  }

  public createForm(): void {}

  public cancel(): void {
    this.close({ status: QuickPanelCloseStatus.CANCEL });
  }

  public close(resultDialog: ResultDialog<T>): void {
    this.dialogRef.close(resultDialog);
  }

  public confirm(resultDialog: ResultDialog<T>): void {
    this.close(resultDialog);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close({ status: QuickPanelCloseStatus.CANCEL });
  }

  public clearProperties() {}

  save(): void {
    this.setSaving(true);
    this.clearProperties();

    this.subscription = this.entityService.save(this.entity as Entity<T>).subscribe({
      next: (entity: T) => this._handleSuccess(entity),
      error: (err: IError) => this._handleError(err),
    });
  }

  public removeFieldValue(field: FormlyFieldConfig): void {
    const key = field.key;

    field.formControl.markAsUntouched();
    field.formControl.reset();

    delete this.entity[key as string];
  }

  content(): IContent {
    return this.isEdit
      ? {
          title: `Update ${this.singularEntity.toLowerCase()}`,
          description: `Edit this ${this.singularEntity.toLowerCase()}`,
          toastResponse: `<b>Done</b>: ${this.singularEntity} updated`,
        }
      : {
          title: `Create ${this.singularEntity.toLowerCase()}`,
          description: `Add a new ${this.singularEntity.toLowerCase()}`,
          toastResponse: `<b>Done</b>: ${this.singularEntity} created`,
        };
  }

  public _handleSuccess(entity: T): void {
    this.entity = entity;
    this.toastService.showSuccess(this.content().toastResponse);
    this.handleComplete();
    const res = {
      key: this.isEdit && !!this.actionKey.update ? this.actionKey.update : this.actionKey.create,
      result: entity,
      status: QuickPanelCloseStatus.SUCCESS,
      gridRowIndex: this.data.gridRowIndex || undefined,
      isNew: !this.isEdit,
    };
    this.close(res);
    this.entityService && this.entityService.sendMessage(res);
    this._redirection();
  }

  public _handleError(error: IError) {
    const { message } = error.error;
    this.toastService.showError(`<b>Error</b>: ${message}`);
    this.handleComplete();
  }

  private _redirection(): void {
    !this.isEdit && this.redirectTo && this.entityNavigation.goToDetails((this.entity as Entity<T>).id);
  }
}
