import { Component, Input } from '@angular/core';

import { CountryService } from '../shared/country.service';

@Component({
  selector: 'lib-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent {

  @Input() useSvg = false;

  @Input() set code(val) {
    if (!val) {
      this._reset();
      return;
    }

    const country = this.countryService.get(val);

    if (!country) {
      this._reset();
      return;
    }

    this.countryName = country.name;
    this.countryCode = country.code.toLowerCase();
  }

  countryName: string = '';
  countryCode: string = '';

  constructor(private countryService: CountryService) {}

  private _reset(): void {
    this.countryName = '';
    this.countryCode = '';
  }
}
