import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IError } from '@lib/interfaces';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, Observable, of, zip } from 'rxjs';
import { SvgIcons } from '../assets/svgs.enum';
import { BaseComponent } from '../classes/base-component.class';
import { DialogService } from '../components/dialog/dialog/shared/dialog.service';
import { ResultDialog } from '../components/dialog/dialog/shared/result-dialog.interface';
import { FilterModel } from '../filters/filter-model.class';
import { goBack } from '../helpers/helpers';
import { QuickPanelCloseStatus } from '../layout/quick-panel/shared/quick-panel-close-status.enum';
import { FormlyPickValue, PickList, PickListValue, PickValue } from '../models';
import { EntityCrudService } from '../services/api/entity-crud.service';
import { ToastService } from '../services/toast/toast.service';

type Entity<T> = Pick<T, keyof T> & { id: string; rowVersion: number; isActive?: boolean };
interface IContent {
  title: string;
  description: string;
  toastResponse: string;
}

const DEL_FORM = 'del-form';

@Directive()
export abstract class BaseForm<T> extends BaseComponent implements OnInit, AfterViewInit {
  abstract entity: T;
  abstract entityService: EntityCrudService<T>;
  public dialogService: DialogService<T>;

  public pickList$: Observable<PickList> = new Observable();
  public pickLists$: Observable<PickList>[];

  public isEdit: boolean;
  public saving: boolean = false;

  public svgIcons = SvgIcons;

  public form = new FormGroup({});
  public model: T;
  public options: FormlyFormOptions = {};
  public formFields: FormlyFieldConfig[] = [];
  public relatedFilter: FilterModel;

  public preventPickList: boolean = false;

  private defPickValues: FormlyPickValue[];

  constructor(public cdr: ChangeDetectorRef, public toastService: ToastService, public el: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.preventPickList ? this.createForm() : this.callEntityPickListEndpoint();
  }

  ngAfterViewInit(): void {
    this._subscribeToDialog();

    if (this.pickList$) {
      this.pickList$.subscribe((pickList: PickList) => {
        !!pickList && this.defValues(pickList);
      });
    }

    if (this.pickLists$ && this.pickLists$.length) {
      zip(this.pickLists$).subscribe((pickLists: PickList[]) => {
        pickLists.map((pickList: PickList) => {
          !!pickList && this.defValues(pickList);
        });
      });
    }
  }

  abstract createForm(): void;

  cancel(): void {
    goBack();
  }

  submit(): void {
    if (!this.form.valid) {
      if (!!this.el) {
        const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

        if (invalidControl) {
          invalidControl[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      return;
    }

    this.save();
  }

  update(): void {
    this.model = Object.assign({}, this.model);
  }

  save(): void {
    this.setSaving(true);
    this.subscription = this.entityService.save(this.entity as Entity<T>).subscribe({
      next: (entity: T) => this._handleFormSuccess(entity),
      error: (err: IError) => this._handleFormError(err),
    });
  }

  resetForm(): void {
    this.entity = {} as T;
    this.createForm();
    this.cdr.detectChanges();

    // setInterval(() => {
    //   this.form.reset();
    // }, 0);
  }

  delete(): void {
    this.dialogService.delete(DEL_FORM, this.entityService.delete((this.entity as Entity<T>).id));
  }

  getDetails(id?: string): void {
    id ? this.getDetailsById(id) : this.getDetailsList();
  }

  getDetailsById(id: string): void {
    this.subscription = this.entityService.getById(id, [], this.relatedFilter).subscribe({
      next: (entity: T) => this.handleGetDetailsSuccess(entity),
      error: (err: IError) => this.handleGetDetailsError(err),
    });
  }

  getDetailsList(): void {
    this.subscription = this.entityService.getList().subscribe({
      next: (res: T[]) => {
        if (res && res.length) {
          this.entity = res[0];
          this.cdr.detectChanges();
        }
      },
      error: (err: IError) => this.handleGetDetailsError(err),
    });
  }

  // tslint:disable-next-line:cognitive-complexity
  findField(fieldId: string, formFields: FormlyFieldConfig[] = this.formFields): FormlyFieldConfig {
    let field = null;
    formFields.forEach((item) => {
      let keyParts = [];
      if (item.key && typeof item.key === 'string') {
        keyParts = item.key.split('.');
      }

      if (item.fieldGroup && item.fieldGroup.length > 0) {
        if (!field) {
          field = this.findField(fieldId, item.fieldGroup);
        }
      } else {
        if (
          !field &&
          (item.key === fieldId || (keyParts.length && keyParts[keyParts.length - 1] === fieldId))
        ) {
          field = item;
        }
      }
    });

    return field;
  }

  findFieldByLabel(label: string, formFields: FormlyFieldConfig[]): FormlyFieldConfig {
    return formFields.find((item) => {
      return item.templateOptions.label === label;
    });
  }

  getFieldOptions(field: string): Observable<any> {
    const options = this.findField(field, this.formFields).templateOptions.options;
    return Array.isArray(options) ? of(options) : (options as Observable<any>);
  }

  getFieldOptionModel(field: string, value: unknown): Observable<any> {
    return this.getFieldOptions(field).pipe(
      map((items: object[]) => items.find((item) => item[field] === value)),
    );
  }

  disableForm(formFields: FormlyFieldConfig[]) {
    formFields.forEach((item) => {
      if (item.templateOptions) {
        item.templateOptions.disabled = true;
      }
    });
  }

  getFormKeys(formFields: FormlyFieldConfig[]): string[] {
    const fields = [];

    formFields.forEach((item) => {
      if (item.fieldGroup && item.fieldGroup.length > 0) {
        fields.push(...this.getFormKeys(item.fieldGroup));
      } else {
        if (typeof item.key !== 'undefined') {
          fields.push(item.key);
        }
      }
    });

    return fields;
  }

  setSaving(saving: boolean): void {
    this.saving = saving;
  }

  handleComplete(): void {
    this.setLoading(false);
    this.setSaving(false);
    this.cdr.markForCheck();
  }

  pick(key: string, validFor?: string): Observable<PickListValue[]> {
    return this.pickList$.pipe(
      map((res: PickList) => {
        return !!res
          ? !!validFor && res[key].values
            ? res[key].values.filter((v) => v.validFor === validFor)
            : res[key].values
          : [];
      }),
    );
  }

  pickBy(key: string, entity: string): Observable<PickListValue[]> {
    return zip(this.pickLists$).pipe(
      map((res: PickList[]) => {
        const found = res.find((item: PickList) => !!item && item[key] && item[key].entity === entity);
        return !!found ? found[key].values : [];
      }),
    );
  }

  pickBypassing(key: string, pickList: Observable<PickList>): Observable<PickListValue[]> {
    return pickList.pipe(
      map((res: PickList) => {
        return !!res ? res[key].values : [];
      }),
    );
  }

  defValues(pickList: PickList): void {
    const keys: string[] = Object.keys(pickList);
    const values: PickValue[] = Object.values(pickList);
    const convertedPickList = keys.map((key: string, index: number) => ({
      key,
      definition: values[index],
    }));

    this.defPickValues = convertedPickList.filter((list: FormlyPickValue) => list.definition.defaultValue);
    this.formFields.map((formField) => this._updateFieldValue(formField));
  }

  handleGetDetailsSuccess(entity: T): void {
    this.entity = entity;
    this.isEdit = true;
    this.cdr.markForCheck();
  }

  handleGetDetailsError(err: IError, redirectBack = true): void {
    const { message, name } = err.error || err;
    this.toastService.showWarning(`${name}: ${message}`);
    redirectBack && goBack();
  }

  detectPickList = (field: FormlyFieldConfig, relatedFieldKey: string, pickList: string) =>
    this.detectPickListChanges(field, relatedFieldKey, pickList);

  detectPickListChanges(field: FormlyFieldConfig, relatedFieldKey: string, pickList: string) {
    const relatedField = field.form.get(relatedFieldKey);
    this.subscription = relatedField.valueChanges.subscribe((val) => {
      field.formControl.setValue(null);
      field.templateOptions.options = val ? this.pick(pickList, val) : [];
    });
  }

  callEntityPickListEndpoint(): void {
    this.subscription = this.entityService.pickList().subscribe({
      next: (pickList: PickList) => {
        this.pickList$ = of(pickList);
        this.createForm();
        setTimeout(() => this.defValues(pickList), 500);
      },
    });
  }

  private _handleFormSuccess(entity: T): void {
    this.entity = entity;
    this.toastService.showSuccess('Success');
    this.setLoading(false);
    this.setSaving(false);
    this.cdr.markForCheck();
    this._handleComplete();
  }

  private _handleFormError(error: IError) {
    const { message, name } = error.error;
    this.toastService.showError(`<b>${name}</b>: ${message}`);
    this.setLoading(false);
    this.setSaving(false);
    this.cdr.markForCheck();
  }

  private _handleComplete(): void {}

  private _updateFieldValue(formlyField: FormlyFieldConfig): void {
    const _defField = this.defPickValues.find((defVal: FormlyPickValue) => defVal.key === formlyField.key);
    _defField && formlyField.formControl.setValue(_defField.definition.defaultValue);

    formlyField.fieldGroup &&
      formlyField.fieldGroup.length &&
      formlyField.fieldGroup.map((childField: FormlyFieldConfig) => this._updateFieldValue(childField));
  }

  private _subscribeToDialog(): void {
    if (!!this.dialogService && this.entityService) {
      this.subscription = this.dialogService.getResult().subscribe({
        next: (value: ResultDialog<T>) => {
          if (value && value.status === QuickPanelCloseStatus.SUCCESS && value.key === DEL_FORM) {
            this.resetForm();
          }
        },
      });
    }
  }
}
