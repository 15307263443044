/* tslint:disable:no-unused-expression */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { GenericErrors } from '@org/common-cross-lib';
import { AgGridAngular } from 'ag-grid-angular';
import {
  AgGridEvent,
  CellFocusedEvent,
  CellValueChangedEvent,
  ColDef,
  FilterChangedEvent,
  FilterModifiedEvent,
  GridApi,
  GridOptions,
  IGetRowsParams,
  RowNode,
  SelectionChangedEvent,
  SideBarDef,
} from 'ag-grid-community';
import { ColumnApi } from 'ag-grid-community/dist/lib/columns/columnApi';
import { IDatasource } from 'ag-grid-community/dist/lib/interfaces/iDatasource';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { Observable } from 'rxjs';
import { SvgIcons } from '../../assets/svgs.enum';
import { EventAction } from '../../classes/event-action.class';
import { QuickAction } from '../../classes/quick-action.class';
import { FilterModel } from '../../filters/filter-model.class';
import { singularLabel } from '../../helpers/helpers';
import { IError } from '../../interfaces';
import { EntityCrudService } from '../../services/api/entity-crud.service';
import { Cursor, GridModel } from '../../services/api/grid-model.class';
import { ToastService } from '../../services/toast/toast.service';
import { Entity } from '../../types';
import { DropdownButton } from '../buttons/dropdown/shared/dropdown-button';
import CellRendering from './cell-templates';
import { BooleanTemplateComponent } from './filter-templates/boolean-template/boolean-template.component';
import { GridDisplayControls } from './grid-header/shared/grid-display-controls.enum';
import { GridHeader } from './grid-header/shared/grid-header';
import { GridColumn, GridColumnExpand } from './shared/grid-column.interface';
import { GridService } from './shared/grid.service';

@Component({
  selector: 'lib-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent<T> implements OnInit, OnDestroy {
  @ViewChild('agGrid') agGrid!: AgGridAngular;

  @Input() sideMode: 'client-side' | 'server-side' = 'server-side';
  @Input() shortMode: boolean = false;
  @Input() rowDragManaged: boolean = false;
  @Input() expandedInfo: string[] = [];
  @Input() initFilter: FilterModel;
  @Input() gridHeader: GridHeader;
  @Input() cellActions: DropdownButton[];
  @Input() columns: ColDef[] | GridColumn[];
  @Input() apiService: EntityCrudService<T>;
  @Input() enableRangeSelection: boolean = true;
  @Input() rowSelection: string = 'multiple'; //  single or multiple.
  @Input() emptyResults: QuickAction;
  @Input() nested: string;
  @Input() autoSizeColumns: boolean = true;
  @Input() classes: string[];
  @Input() storeAction: (records: T[]) => { records: T[] } & TypedAction<string>;

  @Output() handleHeaderActionClick: EventEmitter<EventAction<QuickAction>> = new EventEmitter<
    EventAction<QuickAction>
  >();

  @Output() handleBulkClick: EventEmitter<EventAction<T[]>> = new EventEmitter<EventAction<T[]>>();
  @Output() handleCellClick: EventEmitter<EventAction<T>> = new EventEmitter<EventAction<T>>();

  public agGridApi: AgGridEvent;
  public gridOptions: GridOptions;
  public sideBar: SideBarDef;
  public defaultColDef: ColDef;
  public gridColumns: ColDef[];
  public serverSideStoreType: 'full' | 'partial' = 'partial';
  public bulkIsEnable: boolean;
  public displayGridAsTable: boolean;
  public selectedRows: T[];
  public total: number = 0;
  public rowData: T[] | null;
  public isCheckBoxesMode: boolean = false;
  public filterExist: boolean;
  public showEmpty: boolean;
  public error: IError;

  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private cursor: Cursor<T>;

  private updatedRowColumns: T[] = [];
  public isObjectCheckBoxesMode: boolean = false;

  svgIcons = SvgIcons;

  get isServerSide(): boolean {
    return this.sideMode === 'server-side';
  }

  get hasUpdatedColumns(): boolean {
    return !!this.updatedRowColumns.length;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private gridService: GridService,
    private toastService: ToastService,
    private store: Store,
  ) {
    this._initDefaultColumns();
    this._subscribeToGridDisplay();
    this._configGridSideBar();
  }

  ngOnInit(): void {
    this._configGridOptions();
    this.gridService.setGridName(this.gridHeader.name);
    this._getLocalStorageSettings();
    this._setupCellActions();
  }

  clearBulk(): void {
    this.gridApi.deselectAll();
  }

  bulkActionClick(eventAction: EventAction<QuickAction>): void {
    const records: T[] = this.gridApi.getSelectedRows();
    const event: EventAction<T[]> = {
      data: records,
      key: eventAction.key,
    };
    this.handleBulkClick.emit(event);
  }

  headerActionClick(eventAction: EventAction<QuickAction>): void {
    this.handleHeaderActionClick.emit(eventAction);
  }

  emptyResultsActionClick(): void {
    this.handleHeaderActionClick.emit({ key: this.emptyResults.key, data: this.emptyResults });
  }

  submitCheckboxes(): void {
    this.updatedRowColumns.length
      ? this.isObjectCheckBoxesMode
        ? this.submitObjectCheckboxes()
        : this._submitUpdates()
      : this.toastService.showWarning('<b>Warning:</b> Please make some changes before submit');
  }

  private _submitUpdates(): void {
    this.handleBulkClick.emit({ data: this.updatedRowColumns, key: 'edit-mode' });
  }

  private submitObjectCheckboxes(): void {
    const selectedNodes: T[] = [];
    this.gridApi.forEachNode((node: RowNode) => node.data.touched && selectedNodes.push(node.data));

    this.handleBulkClick.emit({ data: selectedNodes, key: 'checkboxes' });
  }

  onFilterChanged(): void {
    this.gridApi.showLoadingOverlay();
    this.cursor = undefined;
    this.total = 0;
  }

  onGridReady(agGridEvent: AgGridEvent): void {
    this.agGridApi = agGridEvent;
    this.gridApi = agGridEvent.api;
    this.gridColumnApi = agGridEvent.columnApi;

    const savedView = this.gridService.getSelectedViewFromLocalStorage();
    savedView && savedView.filters && this.gridApi.setFilterModel(savedView.filters);

    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();

    this.cdr.markForCheck();
    this.isServerSide ? this._serverSide(agGridEvent) : this._clientSide(agGridEvent);

    if (this.autoSizeColumns) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  }

  public updateRecord(event: EventAction<T>): void {
    const nodeToBeUpdated = this.gridApi.getRowNode(event.gridRowIndex);
    nodeToBeUpdated.setData(event.data);
  }

  public reloadGrid(): void {
    // this.gridApi.showLoadingOverlay();
    this.cursor = undefined;
    this.total = 0;
    this.updatedRowColumns.length && this.clearBulk();
    this.updatedRowColumns = [];
    !!this.selectedRows && this.selectedRows.length && this.clearBulk();
    this.gridApi && this.gridApi.refreshInfiniteCache();
    this.cdr.detectChanges();
  }

  public purgeGrid(): void {
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.cursor = undefined;
    this.total = 0;
    this.updatedRowColumns.length && this.clearBulk();
    this.updatedRowColumns = [];
    !!this.selectedRows && this.selectedRows.length && this.clearBulk();
    this.cdr.detectChanges();
    this.gridApi && this.gridApi.purgeInfinitePageCache();
  }

  public resetEmptyFilters(): void {
    this.gridApi.setFilterModel(null);
    this.showEmpty = false;
  }

  private _handleCellValueChange(record: Entity<T>): void {
    const row: Entity<T> = this.updatedRowColumns.find((r: Entity<T>) => r.id === record.id);

    if (!!row) {
      this.updatedRowColumns = this.updatedRowColumns.map((_row: Entity<T>) =>
        _row.id === record.id ? record : _row,
      );
    } else {
      this.updatedRowColumns = [...this.updatedRowColumns, record];
    }
  }

  private _manipulateGridColumns(expandable: string[]): void {
    this.gridColumns = this.gridColumns.filter(
      (column: GridColumnExpand) =>
        column.expandableId === this.gridHeader.name || expandable.includes(column.expandableId),
    );
  }

  private _serverSide(agGridEvent: AgGridEvent): void {
    const datasource: IDatasource = {
      getRows: (params: IGetRowsParams) => {
        this._getRowData(params).subscribe({
          next: (response: GridModel<T>) => {
            const { data, cursor, expandable } = response;

            // this._manipulateGridColumns(expandable);

            this.cursor = cursor;
            this.total = this.total + data.length;

            this.total ? this.gridApi.hideOverlay() : this.gridApi.showNoRowsOverlay();
            this.showEmpty = !this.total;

            this.storeAction && this.store.dispatch(this.storeAction(data));

            this.cdr.detectChanges();
            // console.warn(
            //   'Start:',
            //   params.startRow,
            //   ' End:',
            //   params.endRow,
            //   '  Data:',
            //   data.length,
            //   '  Total:',
            //   this.total,
            // );
            return cursor?.next ? params.successCallback(data) : params.successCallback(data, this.total);
          },
          error: (error: IError) => {
            if (error.name === GenericErrors.SERVICE_SETUP_MISSING) {
              this.error = error;
            } else {
              const { message, name } = error;
              name && this.toastService.showError(`<b>${name.upperCaseFirst()}</b>: ${message}`);
            }
          },
        });
      },
    };

    agGridEvent.api.setDatasource(datasource);
  }

  private _clientSide(agGridEvent: AgGridEvent): void {
    this.gridApi = agGridEvent.api;

    (this.apiService as EntityCrudService<T>).getClientSide(this.nested).subscribe({
      next: (records: T[]) => {
        agGridEvent.api.setRowData(records);
        records.length ? this.gridApi.hideOverlay() : this.gridApi.showNoRowsOverlay();
        this.showEmpty = !records.length;
      },
      error: () => {},
    });
  }

  private _getRowData(params: IGetRowsParams): Observable<GridModel<T>> {
    this.filterExist = !!Object.keys(params.filterModel).length;
    const cursor = this.cursor?.next ? { next: this.cursor.next } : undefined;
    const _params: IGetRowsParams = {
      ...params,
      filterModel: {
        ...params.filterModel,
        ...this.initFilter,
      },
    };
    return this.apiService.getGridList(_params, cursor, this.expandedInfo);
  }

  private _configGridOptions(): void {
    this.gridOptions = {
      //  Components / Templates
      frameworkComponents: {
        booleanFilter: BooleanTemplateComponent,
      },
      // Properties
      serverSideFilteringAlwaysResets: this.isServerSide,
      rowBuffer: 0,
      rowModelType: this.isServerSide ? 'infinite' : 'clientSide',
      rowSelection: this.rowSelection,
      enableRangeSelection: this.enableRangeSelection,
      paginationPageSize: 100,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 50,
      maxBlocksInCache: 30,
      animateRows: true,
      suppressRowClickSelection: false,
      // rowHeight: 60,

      // Events
      onFilterModified: (event: FilterModifiedEvent) => {
        // console.log(event);
      },
      onFilterChanged: (event: FilterChangedEvent) => this.onFilterChanged(),
      onSortChanged: () => this.onFilterChanged(),
      onSelectionChanged: (event: SelectionChangedEvent) => {
        this.selectedRows = event.api.getSelectedRows();
        this.cdr.markForCheck();
      },
    };
  }

  private _configGridSideBar(): void {
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: false,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: false,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: true,
            suppressFilterSearch: true,
          },
        },
      ],
    };
  }

  private _getLocalStorageSettings(): void {
    this.displayGridAsTable = this.gridService.getDisplayInLocalStorage() === GridDisplayControls.TABLE;
    this._showHideToolbar(!this.displayGridAsTable);
  }

  private _showHideToolbar(flag: boolean): void {
    // this.sideBar = {...this.sideBar, hiddenByDefault: flag};
    this.sideBar = { ...this.sideBar, hiddenByDefault: false };
  }

  private _initDefaultColumns(): void {
    this.defaultColDef = {
      editable: false,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      cellStyle: { display: 'flex' },
      cellClass: 'items-center',
      filterParams: {
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (cell: ICellRendererParams) => (!!cell.value ? cell.value : '-'),
    };
  }

  private _setupCellActions(): void {
    this._setupCellActionsForTable();
    // this.displayGridAsTable ? this._setupCellActionsForTable() : this._setupCellActionsForSplitView();
    this.bulkIsEnable = (this.columns ?? []).some((_col: ColDef) => _col.checkboxSelection);
    this.isCheckBoxesMode = (this.columns ?? []).some(
      (_col: ColDef) =>
        _col.cellRenderer === CellRendering.checkBox ||
        _col.cellRenderer === CellRendering.objectPermissionCheckbox,
    );
    this.isObjectCheckBoxesMode = (this.columns ?? []).some(
      (_col: ColDef) => _col.cellRenderer === CellRendering.objectPermissionCheckbox,
    );
  }

  private _setupCellActionsForTable(): void {
    const cell: ColDef = {
      headerName: 'Actions',
      field: 'actions',
      colId: 'cell-actions',
      width: 80,
      headerClass: 'actions-header',
      checkboxSelection: false,
      pinned: 'right',
      sortable: false,
      menuTabs: [],
      filter: false,
      lockPinned: true,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'top',
      cellClass: 'lock-pinned actions-container actions-button-cell justify-center items-center',
      cellRenderer: CellRendering.dropdownActions,
      cellRendererParams: {
        buttons: this.cellActions,
        clicked: (response: EventAction<T>) => this.handleCellClick.emit(response),
      },
    };

    this.gridColumns =
      this.cellActions && this.cellActions.length
        ? ([...this.columns, cell] as ColDef[])
        : ([...this.columns] as ColDef[]);

    this.gridOptions = {
      ...this.gridOptions,
      onCellFocused: (event: CellFocusedEvent) =>
        event.api.setSuppressRowClickSelection(
          (event.column && event.column.getId() === 'cell-actions') || event.column.isPinnedRight(),
        ),
      onCellValueChanged: (event: CellValueChangedEvent) => this._handleCellValueChange(event.data),
    };
  }

  private _setupCellActionsForSplitView(): void {
    const column: ColDef = this.columns[0] as ColDef;
    column.checkboxSelection = false;
    this.gridColumns = [column];
  }

  private _subscribeToGridDisplay(): void {
    this.gridService.getGridDisplay().subscribe((value: string) => {
      this.displayGridAsTable = value === GridDisplayControls.TABLE;
      this.displayGridAsTable ? this._setupCellActions() : this._setupCellActionsForSplitView();
      // this._showHideToolbar(!this.displayGridAsTable);
    });
  }

  ngOnDestroy(): void {}

  public getEmptyBtnName(): string {
    return !!this.emptyResults && this.emptyResults?.label
      ? this.emptyResults.label
      : 'Create ' + this.gridHeader?.name.toLocaleLowerCase()
      ? singularLabel(this.gridHeader?.name).upperCaseFirst()
      : '';
  }
}
