import { Injectable } from '@angular/core';
import { OrgUserModel } from '@lib/models';
import { EntityCrudService } from '@lib/services/api/entity-crud.service';
import { environment } from '../../../environments/environment';
import { RouterConstant } from '../../core/route-constants';

@Injectable({
  providedIn: 'root',
})
export class OrgUserService extends EntityCrudService<OrgUserModel> {
  public baseUrl = environment.apiBaseHref;
  public resourceType = OrgUserModel;
  public resourcePath = RouterConstant.orgUser.apiEndpoint;

  updateLastLogin(): void {
    const url: string = this.getPath() + '/login';
    this.http.post<boolean>(url, {}).subscribe();
  }
}
