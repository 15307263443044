import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Currency } from './shared/currency.class';
import { CurrencyType } from './shared/currency-type.enum';
import { Observable } from 'rxjs';
import { DropdownInfo } from '../../interfaces/info.interface';

@Component({
  selector: 'lib-currency-picker',
  templateUrl: './currency-picker.component.html',
  styleUrls: ['./currency-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CurrencyPickerComponent),
    },
  ],
})
export class CurrencyPickerComponent implements OnInit, ControlValueAccessor {
  @Input() multiple: boolean = true;
  @Input() clearable: boolean = true;
  @Input() placeholder: string;
  @Input() type: CurrencyType = CurrencyType.FIAT;
  @Input() formControl: FormControl;

  currencies: Observable<DropdownInfo[]>;
  selected: string | string[];

  constructor() {}

  ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = this.multiple ? 'Select Currencies' : 'Select Currency';
    }

    // this.currencies = this.commonService.getCurrencies();
  }

  selectionChanged(currency: Currency | Currency[]): void {
    if (currency instanceof Array) {
      this.propagateChange(currency.map((item) => item.code));
    } else if (!currency) {
      this.propagateChange(null);
    } else {
      this.propagateChange(currency.code);
    }
  }

  filterCurrencies(term: string, item: any): any {
    term = term.toLocaleLowerCase();
    return (
      item.shortCode.toLocaleLowerCase().indexOf(term) > -1 ||
      item.name.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: string | string[]): void {
    if (value !== undefined) {
      this.selected = value;
    }
  }
}
