import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'lib-additional-fields',
  styleUrls: ['./additional-fields.component.css'],
  template: `
    <div
      class="additional-optional-fields mt-8 font-medium justify-end flex text-xs cursor-pointer text-primary hover:text-primary-active"
    >
      <h6 (click)="handler()">{{ show ? 'Hide' : 'Show' }} additional optional fields</h6>
    </div>
  `,
})
export class AdditionalFieldsComponent extends FieldType {
  public show: boolean = false;

  handler(): void {
    this.show = !this.show;
    this.formControl.setValue(this.show);
  }
}
