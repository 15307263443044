import { Directive, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EventAction } from '../classes/event-action.class';
import { QuickAction } from '../classes/quick-action.class';
import { InfoItem } from '../components/details-preview/shared/info-item.class';
import { DialogService } from '../components/dialog/dialog/shared/dialog.service';
import { ResultDialog } from '../components/dialog/dialog/shared/result-dialog.interface';
import { ITab } from '../components/navigation/tabs/shared/tab.interface';
import { IError } from '../interfaces/error';
import { QuickPanelCloseStatus } from '../layout/quick-panel/shared/quick-panel-close-status.enum';
import { EntityCrudService } from '../services/api/entity-crud.service';
import { EntityNavigationService } from '../services/navigation/entity-navigation.service';
import { ToastService } from '../services/toast/toast.service';
import { BaseComponent } from './base-component.directive';

type EntityType<T> = Pick<T, keyof T> & { id: string; rowVersion: number };

@Directive()
export abstract class BaseView<Entity> extends BaseComponent implements OnInit {
  public id: string;
  public entity: Entity;
  public navigatorLinks: ITab[];
  public quickActions: QuickAction[];
  public hideRelated: boolean = false;
  public headerInfoItems$: Observable<InfoItem[]>;

  abstract routePath: string;
  abstract expands: string[];

  constructor(
    public route: ActivatedRoute,
    public toastService: ToastService,
    public service: EntityCrudService<Entity>,
    public navigation: EntityNavigationService<Entity>,
    public dialogService: DialogService<Entity>,
  ) {
    super();
  }

  ngOnInit(): void {
    this._subscribeToRouter();
    this._subscribeToDialog();
    this._subscribeToEntityMessage();
    this.setEntityNavigatorLinks();
  }

  public setEntityNavigatorLinks(): void {
    const detailsTab: ITab = {
      key: 'details',
      label: 'Details',
      visible: true,
      routerLink: `/${this.routePath}/${this.id}/details`,
    };

    const relatedTab: ITab = {
      key: 'related',
      label: 'Related',
      visible: true,
      routerLink: `/${this.routePath}/${this.id}/related`,
    };

    this.navigatorLinks = this.hideRelated ? [detailsTab] : [detailsTab, relatedTab];
  }

  private _subscribeToRouter(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this._getDetails();
  }

  public _getDetails(): void {
    this.setLoading(true);
    this.subscription = this.service.getById(this.id, this.expands).subscribe({
      next: (entity: Entity) => this._handleSuccess(entity),
      error: (err: IError) => this.handleError(err),
      complete: () => this.setLoading(false),
    });
  }

  private _handleSuccess(entity: Entity): void {
    this._saveEntity(entity);
    this.configQuickActions();
    this.setLoading(false);
  }

  public updateEntity(entity: Entity): void {
    this.entity = entity;
  }

  private _saveEntity(entity: Entity): void {
    this.entity = entity;
    this.saveEntity(entity);
    this.setHeaderBasicInfo();
  }

  abstract saveEntity(entity: Entity): void;

  abstract configQuickActions(): void;

  abstract onAction(action: EventAction<QuickAction>): void;

  public setHeaderBasicInfo(): void {}

  public handleError(error: IError): void {
    const { name, message } = error.error || error;
    this.toastService.showError(`<b>${name}</b>: ${message}`);
    this.navigation.goToGrid();
    this.setLoading(false);
  }

  private _subscribeToDialog(): void {
    if (!!this.dialogService && !!this.service) {
      this.subscription = this.dialogService.getResult().subscribe({
        next: (value: ResultDialog<Entity>) => {
          if (value && value.status === QuickPanelCloseStatus.SUCCESS && !value.isNew) {
            this._getDetails();

            // if (value.reload) {
            //   this._getDetails();
            // } else {
            //   const newValue: EntityType<Entity> = value.result as EntityType<Entity>;
            //   const oldValue: EntityType<Entity> = this.entity as EntityType<Entity>;

            //   if (newValue.id === oldValue.id && newValue.rowVersion === oldValue.rowVersion + 1) {
            //     const _entity: Entity = value.result;
            //     Object.keys(_entity).forEach((key) => _entity[key] === undefined && delete _entity[key]);

            //     const _updatedEntity: Entity = {
            //       ...this.entity,
            //       ..._entity,
            //     };

            //     this._saveEntity(_updatedEntity);
            //   }
            // }
          }
        },
      });
    }
  }

  private _subscribeToEntityMessage(): void {
    this.subscription = this.service.getMessage().subscribe({
      next: (value) => {
        if (value && value.status === QuickPanelCloseStatus.SUCCESS && !value.isNew) {
          this._getDetails();
        }
      },
    });
  }
}
