import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';

@Exclude()
export class DealModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  dealer: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  externalID: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  login: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  symbol: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  action: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  digits: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  digitsCurrency: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  reason: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  contractSize: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  volume: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  profit: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  storage: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  rateProfit: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  rateMargin: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  expertID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  comment: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  modificationFlags: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  positionID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  order: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  deal: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  entry: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  price: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  profitRaw: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  pricePosition: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  volumeClosed: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  tickValue: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  tickSize: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  flags: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  gateway: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceGateway: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  timeMsc: Date;

  constructor(deal: DealModel) {
    Object.assign(this, deal);
  }
}
