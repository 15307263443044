import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginStorage } from '@lib/enums/login-storage.enum';
import { removeItemFromLocalStorage, setItemToLocalStorage } from '@lib/helpers/helpers';
import { Store } from '@ngrx/store';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthActions } from '../../state-management/actions';
import { IAuthAppState, IAuthState } from '../../state-management/models';
import { environment } from '../../../environments/environment';
import { OrgUserService } from '../../org-users/shared/org-user.service';
import { PlatformUser } from './logged-user-models/platform-user.model';
import { WorkspaceUserMinimal } from './logged-user-models/workspace-user.model';
import { LoggedUser } from './logged-user.model';
import { Login } from './login.class';
import { Register } from './register.class';
import { ResetPassword } from './reset-password.class';
import { ShortOrganization } from './short-organization.class';

@Injectable()
export class AuthService {
  baseUrl: string;

  constructor(
    public http: HttpClient,
    private orgUserService: OrgUserService,
    private authStore: Store<IAuthAppState>,
  ) {
    this.baseUrl = environment.apiBaseHref;
  }

  isLoggedIn(): boolean {
    return !!this.getUser();
  }

  getUser(): PlatformUser {
    try {
      const jsonUser = localStorage.getItem(LoginStorage.USER_NAME);
      const userObject = JSON.parse(jsonUser);

      if (!userObject) {
        return;
      }

      return new PlatformUser(userObject);
    } catch (e) {
      return null;
    }
  }

  getWorkspaces(): WorkspaceUserMinimal[] {
    try {
      const jsonWorkspaces = localStorage.getItem(LoginStorage.WORKSPACES);
      return !!jsonWorkspaces
        ? (JSON.parse(jsonWorkspaces) as WorkspaceUserMinimal[]).map((w) =>
            plainToClass(WorkspaceUserMinimal, w),
          )
        : [];
    } catch (e) {
      return null;
    }
  }

  getWorkspaceID(): string {
    try {
      const workspaceId = localStorage.getItem(LoginStorage.WORKSPACE);
      return !!workspaceId ? workspaceId : '';
    } catch (e) {
      return null;
    }
  }

  selectOrganization(organization: ShortOrganization): void {
    setItemToLocalStorage(LoginStorage.ORGANIZATION, organization.id);
    this._storeAuthState(organization);
  }

  selectWorkspace(workspace: WorkspaceUserMinimal): void {
    setItemToLocalStorage(LoginStorage.ORGANIZATION, workspace.orgId);
    setItemToLocalStorage(LoginStorage.WORKSPACE, workspace.workspaceId);

    this._storeAuthState(workspace.organization);
    this.orgUserService.updateLastLogin();
  }

  exportOrganizationsFromWorkspaceUser(workspaceUsers: WorkspaceUserMinimal[]): ShortOrganization[] {
    const unique: WorkspaceUserMinimal[] = [
      ...new Map(workspaceUsers.map((item) => [item.orgId, item])).values(),
    ];
    return unique.map((u) => u.organization);
  }

  getOrganization(): string | null {
    return localStorage.getItem(LoginStorage.ORGANIZATION) || null;
  }

  getAuthToken(): string {
    return localStorage.getItem(LoginStorage.ID_TOKEN_NAME) || null;
  }

  getAccessToken(): string {
    return localStorage.getItem(LoginStorage.ACCESS_TOKEN_NAME) || null;
  }

  getRefreshToken(): string {
    return localStorage.getItem(LoginStorage.REFRESH_TOKEN_NAME) || null;
  }

  login(login: Login): Observable<LoggedUser> {
    return this.http.post<LoggedUser>(this.baseUrl + '/auth/login', login).pipe(
      map((response: LoggedUser) => {
        if (response && response.auth.idToken) {
          this._clear();
          setItemToLocalStorage(LoginStorage.ID_TOKEN_NAME, response.auth.idToken);
          setItemToLocalStorage(LoginStorage.REFRESH_TOKEN_NAME, response.auth.refreshToken);
          setItemToLocalStorage(LoginStorage.ACCESS_TOKEN_NAME, response.auth.accessToken);
        }

        const user: PlatformUser = response.user;
        const workspaces = response.workspaceUsers;

        setItemToLocalStorage(LoginStorage.USER_NAME, JSON.stringify(user));
        setItemToLocalStorage(LoginStorage.WORKSPACES, JSON.stringify(workspaces));

        return response;
      }),
    );
  }

  register(register: Register): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + '/auth/register', register).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  forgotPassword(username: string): Observable<boolean> {
    return this.http.post<any>(this.baseUrl + '/auth/forgot-password', { username }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  resetPassword(resetPassword: ResetPassword): Observable<boolean> {
    return this.http.post<any>(this.baseUrl + '/auth/confirm-forgot-password', resetPassword).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  confirmRegistration(data: any): Observable<boolean> {
    return this.http.post<any>(this.baseUrl + '/auth/confirm-registration', data).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  changePassword(profile: any): Observable<boolean> {
    return this.http.post<any>(this.baseUrl + '/auth/change-password', profile).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  resentConfirmationCode(email: string): Observable<boolean> {
    return this.http.post<any>(this.baseUrl + '/auth/resend-confirmation-code', { username: email }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  refreshToken(): Observable<LoggedUser> {
    const refreshT = this.getRefreshToken();
    return this.http
      .post<any>(this.baseUrl + '/auth/refresh-token', { refreshToken: refreshT })
      .pipe(map((response: LoggedUser) => this.setRefreshedToken(response)));
  }

  setRefreshedToken(loggedUser: LoggedUser): LoggedUser {
    const workspaceUsers = loggedUser.workspaceUsers;
    setItemToLocalStorage(LoginStorage.WORKSPACES, JSON.stringify(workspaceUsers));
    setItemToLocalStorage(LoginStorage.ID_TOKEN_NAME, loggedUser.auth.idToken);
    setItemToLocalStorage(LoginStorage.ACCESS_TOKEN_NAME, loggedUser.auth.accessToken);
    this._storeAuthState(loggedUser.workspaceUsers[0].organization);

    return loggedUser;
  }

  logout(): void {
    this._clear();
    location.reload();
  }

  private _storeWorkspaceUsers(_workspaces?: WorkspaceUserMinimal[]): void {}

  private _storeAuthState(organization: ShortOrganization): void {
    const platformUser: PlatformUser = this.getUser();
    const workspaceUsers: WorkspaceUserMinimal[] = this.getWorkspaces();
    const organizations: ShortOrganization[] = this.exportOrganizationsFromWorkspaceUser(workspaceUsers);

    const workspaceID = this.getWorkspaceID();

    const workspace: WorkspaceUserMinimal = !!workspaceID
      ? workspaceUsers.find((w) => w.workspaceId === workspaceID)
      : null;

    const authState: IAuthState = {
      actions: [],
      organization,
      organizationUser: workspace ? workspace.orgUser : workspaceUsers[0].orgUser,
      organizations: organizations,
      platformUser,
      workspaces: workspaceUsers,
      workspace: workspace,
      workspaceID: workspaceID || null,
    };

    this.authStore.dispatch(AuthActions.updateSate({ auth: authState }));
  }

  private _clear(): void {
    removeItemFromLocalStorage([
      LoginStorage.ORGANIZATION,
      LoginStorage.WORKSPACES,
      LoginStorage.WORKSPACE,
      LoginStorage.USER_NAME,
      LoginStorage.ID_TOKEN_NAME,
      LoginStorage.ACCESS_TOKEN_NAME,
      LoginStorage.REFRESH_TOKEN_NAME,
    ]);
  }

  createWorkspace(workspace: any) {
    const url = this.baseUrl + '/org/workspace-provision';
    return this.http.post(url, workspace);
  }
}
