import { Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterModel } from '../filters/filter-model.class';

@Directive()
export class BaseComponent {
  public loading = false;
  private subscriptions: Subscription[] = [];

  // @ts-ignore
  set subscription(subscription: Subscription) {
    this._addSubscription(subscription);
  }

  ngOnDestroy(): void {
    this._removeSubscriptions();
  }

  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public getIdFilter(filterValue: string, key: string = 'id'): FilterModel {
    return {
      [key]: {
        filterType: 'id',
        filter: filterValue,
        type: 'equals',
      },
    };
  }

  private _addSubscription(sub: Subscription): void {
    this.subscriptions.push(sub);
  }

  protected _removeSubscriptions(): void {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }
}
