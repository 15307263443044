import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';

@Exclude()
export class TargetModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  language: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  city: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  zip: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  companyName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  address: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  subject: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fromName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fromEmail: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isActive: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  externalListId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  externalWebhookId: string;

  constructor(target: Partial<TargetModel>) {
    super(target);
    Object.assign(this, target);
  }
}
