import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material.module';

import { ToastService } from './toast.service';
import { ToastTemplateComponent } from './toast-template/toast-template.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule
    ],
  declarations: [
    ToastTemplateComponent
  ],
  providers: [
    ToastService
  ],
  entryComponents: [
    ToastTemplateComponent
  ]
})
export class ToastModule {}
