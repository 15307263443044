<div *ngIf="actions && actions.length" class="select relative inline-block text-left container {{ classes }}">
  <div class="">
    <button
      *ngIf="name"
      type="button"
      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-offset-gray-100"
      [id]="name"
      aria-expanded="true"
      aria-haspopup="true"
    >
      {{ name }}
      <svg
        class="-mr-1 ml-2 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <button
      *ngIf="!name && !img"
      type="button"
      class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
    >
      <span class="sr-only">Open options</span>
      <svg
        *ngIf="!icon"
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
        />
      </svg>
      <span *ngIf="icon" [innerHTML]="icon | safe"></span>
    </button>

    <ng-container *ngIf="img">
      <button
        type="button"
        class="max-w-xs bg-gray-850 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
      >
        <span class="sr-only">Open user menu</span>
        <img
          class="h-8 w-8 rounded-full"
          style="min-width: 2rem"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      </button>
    </ng-container>
  </div>

  <div
    class="dropdown origin-top-right absolute mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
    [ngClass]="xPosition === 'right' ? 'left-0' : 'right-0'"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1 flex flex-col justify-start inner-actions w-full">
      <ng-container *ngFor="let action of actions">
        <div class="flex w-full">
          <div *ngIf="selection" [style.minWidth]="'20px'" class="flex items-center justify-center">
            <div *ngIf="action.selected" class="relative left-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-primary"
                fill="none"
                viewBox="0 0 20 20"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          </div>

          <label
            *ngIf="action.label"
            class="text-gray-500 font-medium px-5 mt-3 mb-1 first:mt-1"
            [style.fontSize]="'12px'"
            >{{ action.label | uppercase }}
          </label>

          <div class="px-3 w-full" *ngIf="action.key">
            <button
              [style.fontSize]="'14px'"
              (click)="clickBulkAction(action)"
              [class]="action.classes"
              [disabled]="action.disable"
              class="px-2 py-2 text-left w-full rounded font-medium text-gray-800 hover:bg-primary-light hover:text-primary"
            >
              {{ action.name }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
