import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';

@Exclude()
export class MarginLevelModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  leverage: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  balance: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  equity: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  margin: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  marginFree: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  marginLevel: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  credit: number;

  constructor(marginLevel: Partial<MarginLevelModel>) {
    Object.assign(this, marginLevel);
  }
}
