<div id="confirmation-dialog" class="flex flex-col items-center justify-center rounded-md">
  <div *ngIf="data.icon || true" class="icon py-2" [innerHTML]="svgIcons.warningCircle | safe"></div>

  <h1 *ngIf="!data.hideTitle" class="title">{{ title }}</h1>

  <div class="mt-5 content text-center">
    <p class="">{{ message | notSet }}</p>
  </div>

  <div mat-dialog-actions class="actions">
    <button class="btn-light mr-3" (click)="cancel()">{{ cancelActionLabel }}</button>
    <button class="btn-primary" (click)="save()">
      {{ confirmActionLabel }}
    </button>
  </div>
</div>
