import { OrganizationModel } from '../organization/organization-model.class';
import { PlatformUserModel } from './platform-user.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';

@Exclude()
export class OrgUserModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  id: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  orgId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  firstName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  lastName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  title: string | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isActive: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isDeleted: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isSuperAdmin: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  rowVersion: number;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  photoUrl: string | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  createdByUser?: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  lastModifiedByUser?: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => PlatformUserModel)
  platformUser?: PlatformUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastLoginDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastPasswordChangeDate: Date | null;

  constructor(orgUserModel: Partial<OrgUserModel>) {
    Object.assign(this, orgUserModel);
  }
}
