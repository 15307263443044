import { L } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { IGetRowsParams } from 'ag-grid-community';
import { plainToClass } from 'class-transformer';
import { map, Observable, Subject } from 'rxjs';
import { ResultDialog } from '../../components/dialog/dialog/shared/result-dialog.interface';
import { ClassExposeGroups } from '../../enums';
import { FilterModel } from '../../filters/filter-model.class';
import { PickList } from '../../models';
import { Cursor, GridModel } from './grid-model.class';
import { UtilsService } from './utils.service';

type Entity<T> = Pick<T, keyof T> & { id: string; rowVersion: number; isActive?: boolean } & {
  ownerId?: string;
};

@Injectable({
  providedIn: 'root',
})
export abstract class EntityCrudService<T> extends UtilsService<T> {
  abstract baseUrl: string;
  abstract resourcePath: string;
  abstract resourceType: Type<T>;
  public nestedEntityCrudService: EntityCrudService<any>;
  public nestedId: string;

  constructor(protected http: HttpClient) {
    super();
  }

  // [Server Side] Only for grid
  public getGridList(
    gridParams: IGetRowsParams,
    cursor?: Cursor<T>,
    expand: string[] = [],
  ): Observable<GridModel<T>> {
    const url = `${this.getPath()}/list`;

    return this.http.post<GridModel<T>>(url, { ...gridParams, cursor, expand }).pipe(
      map((response: GridModel<T>) => {
        const { data, cursor, expandable } = response;
        const _data: T[] = this.handleManyResponse(data, [ClassExposeGroups.VIEW]);
        return { data: _data, cursor, expandable };
      }),
    );
  }

  // [Client Side] Only for grid
  public getClientSide(nested?: string): Observable<T[]> {
    const url: string = this.getPath(nested);

    // const _deals = this.handleManyResponse(deals, [ClassExposeGroups.VIEW]);
    // return of(_deals);

    return this.http
      .get<T[]>(url)
      .pipe(map((response: T[]) => this.handleManyResponse(response, [ClassExposeGroups.VIEW])));
  }

  public getList(
    filters?: FilterModel,
    expand: string[] = [],
    startRow: number = 0,
    endRow: number = 0,
  ): Observable<T[]> {
    const url = `${this.baseUrl}/${this.resourcePath}/list`;

    const gridParams = {
      startRow,
      endRow,
      filterModel: filters ? filters : undefined,
      expand,
    };

    return this.http
      .post<GridModel<T>>(url, { ...gridParams })
      .pipe(
        map((response: GridModel<T>) => this.handleManyResponse(response.data, [ClassExposeGroups.VIEW])),
      );
  }

  public get(): Observable<T> {
    const url = this.getPath();
    return this.http.get<T>(url).pipe(map((res: T) => this.handleResponse(res, [ClassExposeGroups.VIEW])));
  }

  public getDropdown(id?: string): Observable<T[]> {
    const url = !id
      ? `${this.baseUrl}/${this.resourcePath}/dropdown`
      : `${this.baseUrl}/${this.resourcePath}/${id}/dropdown`;

    return this.http.get<T[]>(url).pipe(
      map((response: T[]) => {
        return this.handleManyResponse(response, [ClassExposeGroups.VIEW]);
      }),
    );
  }

  public pickList(): Observable<PickList> {
    const url: string = `${this.getPath()}/pick-list`;

    return this.http.get<PickList>(url).pipe(map((pickList: PickList) => plainToClass(PickList, pickList)));
  }

  public getByFilter(filterModel: FilterModel, expand: string[] = []): Observable<T> {
    const url: string = `${this.getPath()}/list`;

    const gridParams = {
      startRow: 0,
      endRow: 1,
      filterModel,
      expand,
    };

    return this.http.post<GridModel<T>>(url, { ...gridParams }).pipe(
      map((response: GridModel<T>) => {
        if (!response.data.length) {
          throw new Error('Provided record does not exist.');
        } else {
          return this.handleResponse(response.data[0], [ClassExposeGroups.VIEW]);
        }
      }),
    );
  }

  public getById(id: string, expand: string[] = [], filter?: FilterModel): Observable<T> {
    const filterModel: FilterModel = !!filter
      ? filter
      : {
          id: {
            filterType: 'id',
            type: 'equals',
            filter: id,
          },
        };

    return this.getByFilter(filterModel, expand);
  }

  public save(entity: Entity<T>): Observable<T> {
    return !!entity?.id ? this.update(entity) : this.create(entity);
  }

  public create(entity: T): Observable<T> {
    const url: string = `${this.getPath()}`;
    const _entity = this.handleResponse(entity, [ClassExposeGroups.CREATE]);

    return this.http
      .post<T>(url, _entity)
      .pipe(map((response: T) => this.handleResponse(response, [ClassExposeGroups.VIEW])));
  }

  public update(entity: Entity<T>): Observable<T> {
    const { id } = entity;
    const url: string = `${this.getPath()}/${id}`;
    const _entity = this.handleResponse(entity, [ClassExposeGroups.UPDATE]);

    return this.http
      .put<T>(url, _entity)
      .pipe(map((response: T) => this.handleResponse(response, [ClassExposeGroups.VIEW])));
  }

  public delete(id: string): Observable<T> {
    const url: string = `${this.getPath()}/${id}`;

    return this.http.delete<T>(url).pipe(map((response: T) => response));
  }

  public deleteBulk(data: string[]): Observable<T> {
    const url: string = `${this.getPath()}/bulk/delete`;

    return this.http.post<T>(url, { ids: data }).pipe(map((response: T) => response));
  }

  public activeInactive(entity: Entity<T>): Observable<T> {
    const { id, isActive, rowVersion } = entity;

    const url = `${this.getPath()}/${id}/status`;
    const body = {
      rowVersion,
      isActive: !isActive,
    };

    return this.http.put<T>(url, body);
  }

  public changeOwner(entity: Entity<T>): Observable<T> {
    const { id, ownerId, rowVersion } = entity;
    const url: string = `${this.getPath()}/${id}`;

    return this.http
      .put<T>(url, { ownerId, rowVersion })
      .pipe(map((response: T) => this.handleResponse(response, [ClassExposeGroups.VIEW])));
  }

  private subject = new Subject<ResultDialog<T>>();
  private records = new Subject<T[]>();

  sendMessage(message: ResultDialog<T>) {
    this.subject.next(message);
  }

  sendRecords(_records: T[]) {
    this.records.next(_records);
  }

  getMessage(): Observable<ResultDialog<T>> {
    return this.subject.asObservable();
  }

  getRecords(): Observable<T[]> {
    return this.records.asObservable();
  }

  clearSubjects(): void {
    this.subject.next(null);
    this.records.next(null);
  }
}
