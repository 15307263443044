import { createFeatureSelector, createSelector } from '@ngrx/store';
import { orgUserKey } from '../constants';
import { IOrgState } from '../models';

const state = createFeatureSelector<IOrgState>(orgUserKey);
const selectedOrgUser = createSelector(state, (state: IOrgState) => state.orgUser);

export const fromOrg = {
  selectedOrgUser,
};
