export function stringEnumToArray(type: any, key?: string, value?: any, humanize: boolean = true): object[] {
  return Object.keys(type).map((item) => {
    const itemValue = humanize ? item.humanize() : item;
    if (key && value) {
      const obj = {};
      obj[key] = type[item];
      obj[value] = itemValue;
      return obj;
    } else {
      return { value: type[item], label: itemValue };
    }
  });
}

export function filterEnumToArray(type: any): Object[] {
  return Object.keys(type).map((key) => ({ key: type[key].humanize(), value: key }));
}

export function enumToArray(type: any): Object[] {
  return Object.keys(type)
    .filter((item) => {
      return !Number.isInteger(+item);
    })
    .map((key) => ({ id: type[key], name: key.humanize() }));
}

export function enumToSimpleArray(type: any): string[] {
  return Object.keys(type)
    .filter((item) => {
      return !Number.isInteger(+item);
    })
    .map((key) => type[key]);
}

export function isFunction(functionToCheck: any): functionToCheck is Function {
  const getType = {};
  return (
    !!functionToCheck &&
    functionToCheck instanceof Function &&
    getType.toString.call(functionToCheck) === '[object Function]'
  );
}

export function isPlainObject(value: any): boolean {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  } else {
    const prototype = Object.getPrototypeOf(value);
    return prototype === null || prototype === Object.prototype;
  }
}

export function isString(value: any): value is string {
  return !!value && typeof value === 'string';
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function isPromise(promise: any) {
  return Object.prototype.toString.call(promise) === '[object Promise]';
}

export function notEmptyValue(value: any): boolean {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return !!value;
}

export function transformStringToArray(value: any): string[] {
  if (isString(value)) {
    return [value];
  }
  return value;
}

export function getProperty(propertyName, object): any {
  const parts = propertyName.split('.');
  const length = parts.length;

  let property = object || this;

  for (let i = 0; i < length; i++) {
    property = property[parts[i]] || '';
  }

  return property;
}

export function swapArrayElements(a, x, y) {
  if (a.length === 1) {
    return a;
  }

  a.splice(y, 1, a.splice(x, 1, a[y])[0]);
  return a;
}

export function normalizeArray(value: any): any[] {
  if (value instanceof Array || !value) {
    return value;
  }
  return [value];
}

// ucs-2 string to base64 encoded ascii
export function utoa(str) {
  return btoa(unescape(encodeURIComponent(str)));
}

// base64 encoded ascii to ucs-2 string
export function atou(str) {
  return decodeURIComponent(escape(atob(str)));
}

export function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export function objectProp(obj, prop, val) {
  const props = prop.split('.');
  const final = props.pop();

  let p;

  while ((p = props.shift())) {
    if (typeof obj[p] === 'undefined' || !obj[p]) {
      return undefined;
    }
    obj = obj[p];
  }
  return val ? (obj[final] = val) : obj[final];
}

export const getDomainViaEmail = (email: string): string => {
  return email.split('@').pop();
};

export const goBack = (step: number = -1): void => {
  window.history.go(step);
};

export const setItemToLocalStorage = (key: string, data: string | any): void => {
  localStorage.setItem(key, data);
};

export const getItemFromLocalStorage = (key: string): string => {
  return localStorage.getItem(key) || null;
};

export const removeItemFromLocalStorage = (key: string[]): void => {
  key.map((k) => localStorage.removeItem(k));
};

export const singularLabel = (text: string): string => {
  const _text = !!text && text.length ? (text[text.length - 1] === 's' ? text.slice(0, -1) : text) : text;
  return _text.toLocaleLowerCase();
};

export const groupByKey = (list, key) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const getFirstChars = (text: string) =>
  text
    .split(' ')
    .map((word: string) => word[0])
    .join('')
    .toUpperCase();
