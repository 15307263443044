export const COUNTRIES = [
    {
        code: 'af',
        name: 'Afghanistan',
        numericalCode: 4,
        dialingCode: '+93',
        unCode: 'afg',
    },
    {
        code: 'al',
        name: 'Albania',
        numericalCode: 8,
        dialingCode: '+355',
        unCode: 'alb',
    },
    {
        code: 'dz',
        name: 'Algeria',
        numericalCode: 12,
        dialingCode: '+213',
        unCode: 'dza',
    },
    {
        code: 'as',
        name: 'American Samoa',
        numericalCode: 16,
        dialingCode: '+1',
        unCode: 'asm',
    },
    {
        code: 'hu',
        name: 'Hungary',
        numericalCode: 348,
        dialingCode: '+36',
        unCode: 'hun',
    },
    {
        code: 'is',
        name: 'Iceland',
        numericalCode: 352,
        dialingCode: '+354',
        unCode: 'isl',
    },
    {
        code: 'in',
        name: 'India',
        numericalCode: 356,
        dialingCode: '+91',
        unCode: 'ind',
    },
    {
        code: 'ad',
        name: 'Andorra',
        numericalCode: 20,
        dialingCode: '+376',
        unCode: 'and',
    },
    {
        code: 'ao',
        name: 'Angola',
        numericalCode: 24,
        dialingCode: '+244',
        unCode: 'ago',
    },
    {
        code: 'ai',
        name: 'Anguilla',
        numericalCode: 660,
        dialingCode: '+1',
        unCode: 'aia',
    },
    {
        code: 'aq',
        name: 'Antarctica',
        numericalCode: 10,
        dialingCode: '+672',
        unCode: 'ata',
    },
    {
        code: 'ag',
        name: 'Antigua and Barbuda',
        numericalCode: 28,
        dialingCode: '+1',
        unCode: 'atg',
    },
    {
        code: 'ar',
        name: 'Argentina',
        numericalCode: 32,
        dialingCode: '+54',
        unCode: 'arg',
    },
    {
        code: 'am',
        name: 'Armenia',
        numericalCode: 51,
        dialingCode: '+374',
        unCode: 'arm',
    },
    {
        code: 'aw',
        name: 'Aruba',
        numericalCode: 533,
        dialingCode: '+297',
        unCode: 'abw',
    },
    {
        code: 'au',
        name: 'Australia',
        numericalCode: 36,
        dialingCode: '+61',
        unCode: 'aus',
    },
    {
        code: 'at',
        name: 'Austria',
        numericalCode: 40,
        dialingCode: '+43',
        unCode: 'aut',
    },
    {
        code: 'az',
        name: 'Azerbaijan',
        numericalCode: 31,
        dialingCode: '+994',
        unCode: 'aze',
    },
    {
        code: 'bs',
        name: 'Bahamas',
        numericalCode: 44,
        dialingCode: '+1',
        unCode: 'bhs',
    },
    {
        code: 'bh',
        name: 'Bahrain',
        numericalCode: 48,
        dialingCode: '+973',
        unCode: 'bhr',
    },
    {
        code: 'bd',
        name: 'Bangladesh',
        numericalCode: 50,
        dialingCode: '+880',
        unCode: 'bgd',
    },
    {
        code: 'bb',
        name: 'Barbados',
        numericalCode: 52,
        dialingCode: '+1',
        unCode: 'brb',
    },
    {
        code: 'by',
        name: 'Belarus',
        numericalCode: 112,
        dialingCode: '+375',
        unCode: 'blr',
    },
    {
        code: 'be',
        name: 'Belgium',
        numericalCode: 56,
        dialingCode: '+32',
        unCode: 'bel',
    },
    {
        code: 'bz',
        name: 'Belize',
        numericalCode: 84,
        dialingCode: '+501',
        unCode: 'blz',
    },
    {
        code: 'bj',
        name: 'Benin',
        numericalCode: 204,
        dialingCode: '+229',
        unCode: 'ben',
    },
    {
        code: 'bm',
        name: 'Bermuda',
        numericalCode: 60,
        dialingCode: '+1',
        unCode: 'bmu',
    },
    {
        code: 'bt',
        name: 'Bhutan',
        numericalCode: 64,
        dialingCode: '+975',
        unCode: 'btn',
    },
    {
        code: 'bo',
        name: 'Bolivia',
        numericalCode: 68,
        dialingCode: '+591',
        unCode: 'bol',
    },
    {
        code: 'bq',
        name: 'Bonaire, Sint Eustatius and Saba',
        numericalCode: 535,
        dialingCode: '+599',
        unCode: 'bes',
    },
    {
        code: 'ba',
        name: 'Bosnia and Herzegovina',
        numericalCode: 70,
        dialingCode: '+387',
        unCode: 'bih',
    },
    {
        code: 'bw',
        name: 'Botswana',
        numericalCode: 72,
        dialingCode: '+267',
        unCode: 'bwa',
    },
    {
        code: 'bv',
        name: 'Bouvet Island',
        numericalCode: 74,
        dialingCode: '+47',
        unCode: 'bvt',
    },
    {
        code: 'br',
        name: 'Brazil',
        numericalCode: 76,
        dialingCode: '+55',
        unCode: 'bra',
    },
    {
        code: 'io',
        name: 'British Indian Ocean Territory',
        numericalCode: 86,
        dialingCode: '+246',
        unCode: 'iot',
    },
    {
        code: 'bn',
        name: 'Brunei Darussalam',
        numericalCode: 96,
        dialingCode: '+673',
        unCode: 'brn',
    },
    {
        code: 'bg',
        name: 'Bulgaria',
        numericalCode: 100,
        dialingCode: '+359',
        unCode: 'bgr',
    },
    {
        code: 'bf',
        name: 'Burkina Faso',
        numericalCode: 854,
        dialingCode: '+226',
        unCode: 'bfa',
    },
    {
        code: 'bi',
        name: 'Burundi',
        numericalCode: 108,
        dialingCode: '+257',
        unCode: 'bdi',
    },
    {
        code: 'kh',
        name: 'Cambodia',
        numericalCode: 116,
        dialingCode: '+855',
        unCode: 'khm',
    },
    {
        code: 'cm',
        name: 'Cameroon',
        numericalCode: 120,
        dialingCode: '+237',
        unCode: 'cmr',
    },
    {
        code: 'ca',
        name: 'Canada',
        numericalCode: 124,
        dialingCode: '+1',
        unCode: 'can',
    },
    {
        code: 'cv',
        name: 'Cabo Verde',
        numericalCode: 132,
        dialingCode: '+238',
        unCode: 'cpv',
    },
    {
        code: 'ky',
        name: 'Cayman Islands',
        numericalCode: 136,
        dialingCode: '+1',
        unCode: 'cym',
    },
    {
        code: 'cf',
        name: 'Central African Republic',
        numericalCode: 140,
        dialingCode: '+236',
        unCode: 'caf',
    },
    {
        code: 'td',
        name: 'Chad',
        numericalCode: 148,
        dialingCode: '+235',
        unCode: 'tcd',
    },
    {
        code: 'cl',
        name: 'Chile',
        numericalCode: 152,
        dialingCode: '+56',
        unCode: 'chl',
    },
    {
        code: 'cn',
        name: 'China',
        numericalCode: 156,
        dialingCode: '+86',
        unCode: 'chn',
    },
    {
        code: 'cx',
        name: 'Christmas Island',
        numericalCode: 162,
        dialingCode: '+61',
        unCode: 'cxr',
    },
    {
        code: 'cc',
        name: 'Cocos (Keeling) Islands',
        numericalCode: 166,
        dialingCode: '+891',
        unCode: 'cck',
    },
    {
        code: 'co',
        name: 'Colombia',
        numericalCode: 170,
        dialingCode: '+57',
        unCode: 'col',
    },
    {
        code: 'km',
        name: 'Comoros',
        numericalCode: 174,
        dialingCode: '+269',
        unCode: 'com',
    },
    {
        code: 'cg',
        name: 'Congo',
        numericalCode: 178,
        dialingCode: '+243',
        unCode: 'cog',
    },
    {
        code: 'cd',
        name: 'Congo (Democratic Republic of the)',
        numericalCode: 180,
        dialingCode: '+243',
        unCode: 'cod',
    },
    {
        code: 'ck',
        name: 'Cook Islands',
        numericalCode: 184,
        dialingCode: '+682',
        unCode: 'cok',
    },
    {
        code: 'cr',
        name: 'Costa Rica',
        numericalCode: 188,
        dialingCode: '+506',
        unCode: 'cri',
    },
    {
        code: 'hr',
        name: 'Croatia',
        numericalCode: 191,
        dialingCode: '+385',
        unCode: 'hrv',
    },
    {
        code: 'cu',
        name: 'Cuba',
        numericalCode: 192,
        dialingCode: '+53',
        unCode: 'cub',
    },
    {
        code: 'cw',
        name: 'Curaçao',
        numericalCode: 531,
        dialingCode: '+5999',
        unCode: 'cuw',
    },
    {
        code: 'cy',
        name: 'Cyprus',
        numericalCode: 196,
        dialingCode: '+357',
        unCode: 'cyp',
    },
    {
        code: 'cz',
        name: 'Czechia',
        numericalCode: 203,
        dialingCode: '+420',
        unCode: 'cze',
    },
    {
        code: 'ci',
        name: "Côte d'Ivoire",
        numericalCode: 384,
        dialingCode: '+225',
        unCode: 'civ',
    },
    {
        code: 'dk',
        name: 'Denmark',
        numericalCode: 208,
        dialingCode: '+45',
        unCode: 'dnk',
    },
    {
        code: 'dj',
        name: 'Djibouti',
        numericalCode: 262,
        dialingCode: '+253',
        unCode: 'dji',
    },
    {
        code: 'dm',
        name: 'Dominica',
        numericalCode: 212,
        dialingCode: '+1767',
        unCode: 'dma',
    },
    {
        code: 'do',
        name: 'Dominican Republic',
        numericalCode: 214,
        dialingCode: '+1',
        unCode: 'dom',
    },
    {
        code: 'ec',
        name: 'Ecuador',
        numericalCode: 218,
        dialingCode: '+593',
        unCode: 'ecu',
    },
    {
        code: 'eg',
        name: 'Egypt',
        numericalCode: 818,
        dialingCode: '+20',
        unCode: 'egy',
    },
    {
        code: 'sv',
        name: 'El Salvador',
        numericalCode: 222,
        dialingCode: '+503',
        unCode: 'slv',
    },
    {
        code: 'gq',
        name: 'Equatorial Guinea',
        numericalCode: 226,
        dialingCode: '+240',
        unCode: 'gnq',
    },
    {
        code: 'er',
        name: 'Eritrea',
        numericalCode: 232,
        dialingCode: '+291',
        unCode: 'eri',
    },
    {
        code: 'ee',
        name: 'Estonia',
        numericalCode: 233,
        dialingCode: '+372',
        unCode: 'est',
    },
    {
        code: 'et',
        name: 'Ethiopia',
        numericalCode: 231,
        dialingCode: '+251',
        unCode: 'eth',
    },
    {
        code: 'fk',
        name: 'Falkland Islands (Malvinas)',
        numericalCode: 238,
        dialingCode: '+500',
        unCode: 'flk',
    },
    {
        code: 'fo',
        name: 'Faroe Islands',
        numericalCode: 234,
        dialingCode: '+298',
        unCode: 'fro',
    },
    {
        code: 'fj',
        name: 'Fiji',
        numericalCode: 242,
        dialingCode: '+679',
        unCode: 'fji',
    },
    {
        code: 'fi',
        name: 'Finland',
        numericalCode: 246,
        dialingCode: '+358',
        unCode: 'fin',
    },
    {
        code: 'fr',
        name: 'France',
        numericalCode: 250,
        dialingCode: '+33',
        unCode: 'fra',
    },
    {
        code: 'gf',
        name: 'French Guiana',
        numericalCode: 254,
        dialingCode: '+594',
        unCode: 'guf',
    },
    {
        code: 'pf',
        name: 'French Polynesia',
        numericalCode: 258,
        dialingCode: '+689',
        unCode: 'pyf',
    },
    {
        code: 'tf',
        name: 'French Southern Territories',
        numericalCode: 260,
        dialingCode: '+262',
        unCode: 'atf',
    },
    {
        code: 'ga',
        name: 'Gabon',
        numericalCode: 266,
        dialingCode: '+241',
        unCode: 'gab',
    },
    {
        code: 'gm',
        name: 'Gambia',
        numericalCode: 270,
        dialingCode: '+220',
        unCode: 'gmb',
    },
    {
        code: 'ge',
        name: 'Georgia',
        numericalCode: 268,
        dialingCode: '+995',
        unCode: 'geo',
    },
    {
        code: 'de',
        name: 'Germany',
        numericalCode: 276,
        dialingCode: '+49',
        unCode: 'deu',
    },
    {
        code: 'gh',
        name: 'Ghana',
        numericalCode: 288,
        dialingCode: '+233',
        unCode: 'gha',
    },
    {
        code: 'gi',
        name: 'Gibraltar',
        numericalCode: 292,
        dialingCode: '+350',
        unCode: 'gib',
    },
    {
        code: 'gr',
        name: 'Greece',
        numericalCode: 300,
        dialingCode: '+30',
        unCode: 'grc',
    },
    {
        code: 'gl',
        name: 'Greenland',
        numericalCode: 304,
        dialingCode: '+299',
        unCode: 'grl',
    },
    {
        code: 'gd',
        name: 'Grenada',
        numericalCode: 308,
        dialingCode: '+1',
        unCode: 'grd',
    },
    {
        code: 'gp',
        name: 'Guadeloupe',
        numericalCode: 312,
        dialingCode: '+590',
        unCode: 'glp',
    },
    {
        code: 'gu',
        name: 'Guam',
        numericalCode: 316,
        dialingCode: '+1671',
        unCode: 'gum',
    },
    {
        code: 'gt',
        name: 'Guatemala',
        numericalCode: 320,
        dialingCode: '+502',
        unCode: 'gtm',
    },
    {
        code: 'gg',
        name: 'Guernsey',
        numericalCode: 831,
        dialingCode: '+44',
        unCode: 'ggy',
    },
    {
        code: 'gn',
        name: 'Guinea',
        numericalCode: 324,
        dialingCode: '+224',
        unCode: 'gin',
    },
    {
        code: 'gw',
        name: 'Guinea-Bissau',
        numericalCode: 624,
        dialingCode: '+245',
        unCode: 'gnb',
    },
    {
        code: 'gy',
        name: 'Guyana',
        numericalCode: 328,
        dialingCode: '+592',
        unCode: 'guy',
    },
    {
        code: 'ht',
        name: 'Haiti',
        numericalCode: 332,
        dialingCode: '+509',
        unCode: 'hti',
    },
    {
        code: 'hm',
        name: 'Heard Island and McDonald Islands',
        numericalCode: 334,
        dialingCode: '+672',
        unCode: 'hmd',
    },
    {
        code: 'va',
        name: 'Holy See',
        numericalCode: 336,
        dialingCode: '+379',
        unCode: 'vat',
    },
    {
        code: 'hn',
        name: 'Honduras',
        numericalCode: 340,
        dialingCode: '+504',
        unCode: 'hnd',
    },
    {
        code: 'hk',
        name: 'Hong Kong',
        numericalCode: 344,
        dialingCode: '+852',
        unCode: 'hkg',
    },
    {
        code: 'id',
        name: 'Indonesia',
        numericalCode: 360,
        dialingCode: '+62',
        unCode: 'idn',
    },
    {
        code: 'ir',
        name: 'Iran',
        numericalCode: 364,
        dialingCode: '+98',
        unCode: 'irn',
    },
    {
        code: 'iq',
        name: 'Iraq',
        numericalCode: 368,
        dialingCode: '+964',
        unCode: 'irq',
    },
    {
        code: 'ie',
        name: 'Ireland',
        numericalCode: 372,
        dialingCode: '+353',
        unCode: 'irl',
    },
    {
        code: 'im',
        name: 'Isle of Man',
        numericalCode: 833,
        dialingCode: '+44',
        unCode: 'imn',
    },
    {
        code: 'il',
        name: 'Israel',
        numericalCode: 376,
        dialingCode: '+972',
        unCode: 'isr',
    },
    {
        code: 'it',
        name: 'Italy',
        numericalCode: 380,
        dialingCode: '+39',
        unCode: 'ita',
    },
    {
        code: 'jm',
        name: 'Jamaica',
        numericalCode: 388,
        dialingCode: '+1',
        unCode: 'jam',
    },
    {
        code: 'jp',
        name: 'Japan',
        numericalCode: 392,
        dialingCode: '+81',
        unCode: 'jpn',
    },
    {
        code: 'je',
        name: 'Jersey',
        numericalCode: 832,
        dialingCode: '+44',
        unCode: 'jey',
    },
    {
        code: 'jo',
        name: 'Jordan',
        numericalCode: 400,
        dialingCode: '+962',
        unCode: 'jor',
    },
    {
        code: 'kz',
        name: 'Kazakhstan',
        numericalCode: 398,
        dialingCode: '+7',
        unCode: 'kaz',
    },
    {
        code: 'ke',
        name: 'Kenya',
        numericalCode: 404,
        dialingCode: '+254',
        unCode: 'ken',
    },
    {
        code: 'ki',
        name: 'Kiribati',
        numericalCode: 296,
        dialingCode: '+686',
        unCode: 'kir',
    },
    {
        code: 'kp',
        name: 'Korea (Democratic Peoples Republic of)',
        numericalCode: 408,
        dialingCode: '+850',
        unCode: 'prk',
    },
    {
        code: 'kr',
        name: 'Korea (Republic of)',
        numericalCode: 410,
        dialingCode: '+82',
        unCode: 'kor',
    },
    {
        code: 'kw',
        name: 'Kuwait',
        numericalCode: 414,
        dialingCode: '+965',
        unCode: 'kwt',
    },
    {
        code: 'kg',
        name: 'Kyrgyzstan',
        numericalCode: 417,
        dialingCode: '+996',
        unCode: 'kgz',
    },
    {
        code: 'la',
        name: 'Lao Peoples Democratic Republic',
        numericalCode: 418,
        dialingCode: '+856',
        unCode: 'lao',
    },
    {
        code: 'lv',
        name: 'Latvia',
        numericalCode: 428,
        dialingCode: '+371',
        unCode: 'lva',
    },
    {
        code: 'lb',
        name: 'Lebanon',
        numericalCode: 422,
        dialingCode: '+961',
        unCode: 'lbn',
    },
    {
        code: 'ls',
        name: 'Lesotho',
        numericalCode: 426,
        dialingCode: '+266',
        unCode: 'lso',
    },
    {
        code: 'lr',
        name: 'Liberia',
        numericalCode: 430,
        dialingCode: '+231',
        unCode: 'lbr',
    },
    {
        code: 'ly',
        name: 'Libya',
        numericalCode: 434,
        dialingCode: '+218',
        unCode: 'lby',
    },
    {
        code: 'li',
        name: 'Liechtenstein',
        numericalCode: 438,
        dialingCode: '+423',
        unCode: 'lie',
    },
    {
        code: 'lt',
        name: 'Lithuania',
        numericalCode: 440,
        dialingCode: '+370',
        unCode: 'ltu',
    },
    {
        code: 'lu',
        name: 'Luxembourg',
        numericalCode: 442,
        dialingCode: '+352',
        unCode: 'lux',
    },
    {
        code: 'mo',
        name: 'Macao',
        numericalCode: 446,
        dialingCode: '+853',
        unCode: 'mac',
    },
    {
        code: 'mk',
        name: 'Macedonia (the former Yugoslav Republic of)',
        numericalCode: 807,
        dialingCode: '+389',
        unCode: 'mkd',
    },
    {
        code: 'mg',
        name: 'Madagascar',
        numericalCode: 450,
        dialingCode: '+261',
        unCode: 'mdg',
    },
    {
        code: 'mw',
        name: 'Malawi',
        numericalCode: 454,
        dialingCode: '+265',
        unCode: 'mwi',
    },
    {
        code: 'my',
        name: 'Malaysia',
        numericalCode: 458,
        dialingCode: '+60',
        unCode: 'mys',
    },
    {
        code: 'mv',
        name: 'Maldives',
        numericalCode: 462,
        dialingCode: '+960',
        unCode: 'mdv',
    },
    {
        code: 'ml',
        name: 'Mali',
        numericalCode: 466,
        dialingCode: '+223',
        unCode: 'mli',
    },
    {
        code: 'mt',
        name: 'Malta',
        numericalCode: 470,
        dialingCode: '+356',
        unCode: 'mlt',
    },
    {
        code: 'mh',
        name: 'Marshall Islands',
        numericalCode: 584,
        dialingCode: '+692',
        unCode: 'mhl',
    },
    {
        code: 'mq',
        name: 'Martinique',
        numericalCode: 474,
        dialingCode: '+596',
        unCode: 'mtq',
    },
    {
        code: 'mr',
        name: 'Mauritania',
        numericalCode: 478,
        dialingCode: '+222',
        unCode: 'mrt',
    },
    {
        code: 'mu',
        name: 'Mauritius',
        numericalCode: 480,
        dialingCode: '+230',
        unCode: 'mus',
    },
    {
        code: 'yt',
        name: 'Mayotte',
        numericalCode: 175,
        dialingCode: '+262',
        unCode: 'myt',
    },
    {
        code: 'mx',
        name: 'Mexico',
        numericalCode: 484,
        dialingCode: '+52',
        unCode: 'mex',
    },
    {
        code: 'fm',
        name: 'Micronesia (Federated States of)',
        numericalCode: 583,
        dialingCode: '+691',
        unCode: 'fsm',
    },
    {
        code: 'md',
        name: 'Moldova (Republic of)',
        numericalCode: 498,
        dialingCode: '+373',
        unCode: 'mda',
    },
    {
        code: 'mc',
        name: 'Monaco',
        numericalCode: 492,
        dialingCode: '+377',
        unCode: 'mco',
    },
    {
        code: 'mn',
        name: 'Mongolia',
        numericalCode: 496,
        dialingCode: '+976',
        unCode: 'mng',
    },
    {
        code: 'me',
        name: 'Montenegro',
        numericalCode: 499,
        dialingCode: '+382',
        unCode: 'mne',
    },
    {
        code: 'ms',
        name: 'Montserrat',
        numericalCode: 500,
        dialingCode: '+1664\t',
        unCode: 'msr',
    },
    {
        code: 'ma',
        name: 'Morocco',
        numericalCode: 504,
        dialingCode: '+212',
        unCode: 'mar',
    },
    {
        code: 'mz',
        name: 'Mozambique',
        numericalCode: 508,
        dialingCode: '+258',
        unCode: 'moz',
    },
    {
        code: 'mm',
        name: 'Myanmar',
        numericalCode: 104,
        dialingCode: '+95',
        unCode: 'mmr',
    },
    {
        code: 'na',
        name: 'Namibia',
        numericalCode: 516,
        dialingCode: '+264',
        unCode: 'nam',
    },
    {
        code: 'nr',
        name: 'Nauru',
        numericalCode: 520,
        dialingCode: '+674',
        unCode: 'nru',
    },
    {
        code: 'np',
        name: 'Nepal',
        numericalCode: 524,
        dialingCode: '+977',
        unCode: 'npl',
    },
    {
        code: 'nl',
        name: 'Netherlands',
        numericalCode: 528,
        dialingCode: '+31',
        unCode: 'nld',
    },
    {
        code: 'nc',
        name: 'New Caledonia',
        numericalCode: 540,
        dialingCode: '+687',
        unCode: 'ncl',
    },
    {
        code: 'nz',
        name: 'New Zealand',
        numericalCode: 554,
        dialingCode: '+64',
        unCode: 'nzl',
    },
    {
        code: 'ni',
        name: 'Nicaragua',
        numericalCode: 558,
        dialingCode: '+505',
        unCode: 'nic',
    },
    {
        code: 'ne',
        name: 'Niger',
        numericalCode: 562,
        dialingCode: '+227',
        unCode: 'ner',
    },
    {
        code: 'ng',
        name: 'Nigeria',
        numericalCode: 566,
        dialingCode: '+234',
        unCode: 'nga',
    },
    {
        code: 'nu',
        name: 'Niue',
        numericalCode: 570,
        dialingCode: '+683',
        unCode: 'niu',
    },
    {
        code: 'nf',
        name: 'Norfolk Island',
        numericalCode: 574,
        dialingCode: '+672',
        unCode: 'nfk',
    },
    {
        code: 'mp',
        name: 'Northern Mariana Islands',
        numericalCode: 580,
        dialingCode: '+1',
        unCode: 'mnp',
    },
    {
        code: 'no',
        name: 'Norway',
        numericalCode: 578,
        dialingCode: '+47',
        unCode: 'nor',
    },
    {
        code: 'om',
        name: 'Oman',
        numericalCode: 512,
        dialingCode: '+968',
        unCode: 'omn',
    },
    {
        code: 'pk',
        name: 'Pakistan',
        numericalCode: 586,
        dialingCode: '+92',
        unCode: 'pak',
    },
    {
        code: 'pw',
        name: 'Palau',
        numericalCode: 585,
        dialingCode: '+680',
        unCode: 'plw',
    },
    {
        code: 'ps',
        name: 'Palestine, State of',
        numericalCode: 275,
        dialingCode: '+970',
        unCode: 'pse',
    },
    {
        code: 'pa',
        name: 'Panama',
        numericalCode: 591,
        dialingCode: '+507',
        unCode: 'pan',
    },
    {
        code: 'pg',
        name: 'Papua New Guinea',
        numericalCode: 598,
        dialingCode: '+675',
        unCode: 'png',
    },
    {
        code: 'py',
        name: 'Paraguay',
        numericalCode: 600,
        dialingCode: '+595',
        unCode: 'pry',
    },
    {
        code: 'pe',
        name: 'Peru',
        numericalCode: 604,
        dialingCode: '+51',
        unCode: 'per',
    },
    {
        code: 'ph',
        name: 'Philippines',
        numericalCode: 608,
        dialingCode: '+63',
        unCode: 'phl',
    },
    {
        code: 'pn',
        name: 'Pitcairn',
        numericalCode: 612,
        dialingCode: '+64',
        unCode: 'pcn',
    },
    {
        code: 'pl',
        name: 'Poland',
        numericalCode: 616,
        dialingCode: '+48',
        unCode: 'pol',
    },
    {
        code: 'pt',
        name: 'Portugal',
        numericalCode: 620,
        dialingCode: '+351',
        unCode: 'prt',
    },
    {
        code: 'pr',
        name: 'Puerto Rico',
        numericalCode: 630,
        dialingCode: '+1',
        unCode: 'pri',
    },
    {
        code: 'qa',
        name: 'Qatar',
        numericalCode: 634,
        dialingCode: '+974',
        unCode: 'qat',
    },
    {
        code: 'ro',
        name: 'Romania',
        numericalCode: 642,
        dialingCode: '+40',
        unCode: 'rou',
    },
    {
        code: 'ru',
        name: 'Russian Federation',
        numericalCode: 643,
        dialingCode: '+7',
        unCode: 'rus',
    },
    {
        code: 'rw',
        name: 'Rwanda',
        numericalCode: 646,
        dialingCode: '+250',
        unCode: 'rwa',
    },
    {
        code: 're',
        name: 'Réunion',
        numericalCode: 638,
        dialingCode: '+262',
        unCode: 'reu',
    },
    {
        code: 'bl',
        name: 'Saint Barthélemy',
        numericalCode: 652,
        dialingCode: '+590',
        unCode: 'blm',
    },
    {
        code: 'sh',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        numericalCode: 654,
        dialingCode: '+290',
        unCode: 'shn',
    },
    {
        code: 'kn',
        name: 'Saint Kitts and Nevis',
        numericalCode: 659,
        dialingCode: '+1869',
        unCode: 'kna',
    },
    {
        code: 'lc',
        name: 'Saint Lucia',
        numericalCode: 662,
        dialingCode: '+1758',
        unCode: 'lca',
    },
    {
        code: 'mf',
        name: 'Saint Martin (French part)',
        numericalCode: 663,
        dialingCode: '+590',
        unCode: 'maf',
    },
    {
        code: 'pm',
        name: 'Saint Pierre and Miquelon',
        numericalCode: 666,
        dialingCode: '+508',
        unCode: 'spm',
    },
    {
        code: 'vc',
        name: 'Saint Vincent and the Grenadines',
        numericalCode: 670,
        dialingCode: '+1784',
        unCode: 'vct',
    },
    {
        code: 'ws',
        name: 'Samoa',
        numericalCode: 882,
        dialingCode: '+685',
        unCode: 'wsm',
    },
    {
        code: 'sm',
        name: 'San Marino',
        numericalCode: 674,
        dialingCode: '+378',
        unCode: 'smr',
    },
    {
        code: 'st',
        name: 'Sao Tome and Principe',
        numericalCode: 678,
        dialingCode: '+239',
        unCode: 'stp',
    },
    {
        code: 'sa',
        name: 'Saudi Arabia',
        numericalCode: 682,
        dialingCode: '+966',
        unCode: 'sau',
    },
    {
        code: 'sn',
        name: 'Senegal',
        numericalCode: 686,
        dialingCode: '+221',
        unCode: 'sen',
    },
    {
        code: 'rs',
        name: 'Serbia',
        numericalCode: 688,
        dialingCode: '+381',
        unCode: 'srb',
    },
    {
        code: 'sc',
        name: 'Seychelles',
        numericalCode: 690,
        dialingCode: '+248',
        unCode: 'syc',
    },
    {
        code: 'sl',
        name: 'Sierra Leone',
        numericalCode: 694,
        dialingCode: '+232',
        unCode: 'sle',
    },
    {
        code: 'sg',
        name: 'Singapore',
        numericalCode: 702,
        dialingCode: '+65',
        unCode: 'sgp',
    },
    {
        code: 'sx',
        name: 'Sint Maarten (Dutch part)',
        numericalCode: 534,
        dialingCode: '+1',
        unCode: 'sxm',
    },
    {
        code: 'sk',
        name: 'Slovakia',
        numericalCode: 703,
        dialingCode: '+421',
        unCode: 'svk',
    },
    {
        code: 'si',
        name: 'Slovenia',
        numericalCode: 705,
        dialingCode: '+386',
        unCode: 'svn',
    },
    {
        code: 'sb',
        name: 'Solomon Islands',
        numericalCode: 90,
        dialingCode: '+677',
        unCode: 'slb',
    },
    {
        code: 'so',
        name: 'Somalia',
        numericalCode: 706,
        dialingCode: '+252',
        unCode: 'som',
    },
    {
        code: 'za',
        name: 'South Africa',
        numericalCode: 710,
        dialingCode: '+27',
        unCode: 'zaf',
    },
    {
        code: 'gs',
        name: 'South Georgia and the South Sandwich Islands',
        numericalCode: 239,
        dialingCode: '+500',
        unCode: 'sgs',
    },
    {
        code: 'ss',
        name: 'South Sudan',
        numericalCode: 728,
        dialingCode: '+211',
        unCode: 'ssd',
    },
    {
        code: 'es',
        name: 'Spain',
        numericalCode: 724,
        dialingCode: '+34',
        unCode: 'esp',
    },
    {
        code: 'lk',
        name: 'Sri Lanka',
        numericalCode: 144,
        dialingCode: '+94',
        unCode: 'lka',
    },
    {
        code: 'sd',
        name: 'Sudan',
        numericalCode: 729,
        dialingCode: '+249',
        unCode: 'sdn',
    },
    {
        code: 'sr',
        name: 'Suriname',
        numericalCode: 740,
        dialingCode: '+597',
        unCode: 'sur',
    },
    {
        code: 'sj',
        name: 'Svalbard and Jan Mayen',
        numericalCode: 744,
        dialingCode: '+47',
        unCode: 'sjm',
    },
    {
        code: 'sz',
        name: 'Eswatini',
        numericalCode: 748,
        dialingCode: '+268',
        unCode: 'swz',
    },
    {
        code: 'se',
        name: 'Sweden',
        numericalCode: 752,
        dialingCode: '+46',
        unCode: 'swe',
    },
    {
        code: 'ch',
        name: 'Switzerland',
        numericalCode: 756,
        dialingCode: '+41',
        unCode: 'che',
    },
    {
        code: 'sy',
        name: 'Syrian Arab Republic',
        numericalCode: 760,
        dialingCode: '+963',
        unCode: 'syr',
    },
    {
        code: 'tw',
        name: 'Taiwan',
        numericalCode: 158,
        dialingCode: '+886',
        unCode: 'twn',
    },
    {
        code: 'tj',
        name: 'Tajikistan',
        numericalCode: 762,
        dialingCode: '+992',
        unCode: 'tjk',
    },
    {
        code: 'tz',
        name: 'Tanzania',
        numericalCode: 834,
        dialingCode: '+255',
        unCode: 'tza',
    },
    {
        code: 'th',
        name: 'Thailand',
        numericalCode: 764,
        dialingCode: '+66',
        unCode: 'tha',
    },
    {
        code: 'tl',
        name: 'Timor-Leste',
        numericalCode: 626,
        dialingCode: '+670',
        unCode: 'tls',
    },
    {
        code: 'tg',
        name: 'Togo',
        numericalCode: 768,
        dialingCode: '+228',
        unCode: 'tgo',
    },
    {
        code: 'tk',
        name: 'Tokelau',
        numericalCode: 772,
        dialingCode: '+690',
        unCode: 'tkl',
    },
    {
        code: 'to',
        name: 'Tonga',
        numericalCode: 776,
        dialingCode: '+676',
        unCode: 'ton',
    },
    {
        code: 'tt',
        name: 'Trinidad and Tobago',
        numericalCode: 780,
        dialingCode: '+1',
        unCode: 'tto',
    },
    {
        code: 'tn',
        name: 'Tunisia',
        numericalCode: 788,
        dialingCode: '+216',
        unCode: 'tun',
    },
    {
        code: 'tr',
        name: 'Turkey',
        numericalCode: 792,
        dialingCode: '+90',
        unCode: 'tur',
    },
    {
        code: 'tm',
        name: 'Turkmenistan',
        numericalCode: 795,
        dialingCode: '+993',
        unCode: 'tkm',
    },
    {
        code: 'tc',
        name: 'Turks and Caicos Islands',
        numericalCode: 796,
        dialingCode: '+1649',
        unCode: 'tca',
    },
    {
        code: 'tv',
        name: 'Tuvalu',
        numericalCode: 798,
        dialingCode: '+688',
        unCode: 'tuv',
    },
    {
        code: 'ug',
        name: 'Uganda',
        numericalCode: 800,
        dialingCode: '+256',
        unCode: 'uga',
    },
    {
        code: 'ua',
        name: 'Ukraine',
        numericalCode: 804,
        dialingCode: '+380',
        unCode: 'ukr',
    },
    {
        code: 'ae',
        name: 'United Arab Emirates',
        numericalCode: 784,
        dialingCode: '+971',
        unCode: 'are',
    },
    {
        code: 'gb',
        name: 'United Kingdom',
        numericalCode: 826,
        dialingCode: '+44',
        unCode: 'gbr',
    },
    {
        code: 'us',
        name: 'U.S.A.',
        numericalCode: 840,
        dialingCode: '+1',
        unCode: 'usa',
    },
    {
        code: 'um',
        name: 'United States Minor Outlying Islands',
        numericalCode: 581,
        dialingCode: '+246',
        unCode: 'umi',
    },
    {
        code: 'uy',
        name: 'Uruguay',
        numericalCode: 858,
        dialingCode: '+598',
        unCode: 'ury',
    },
    {
        code: 'uz',
        name: 'Uzbekistan',
        numericalCode: 860,
        dialingCode: '+998',
        unCode: 'uzb',
    },
    {
        code: 'vu',
        name: 'Vanuatu',
        numericalCode: 548,
        dialingCode: '+678',
        unCode: 'vut',
    },
    {
        code: 've',
        name: 'Venezuela',
        numericalCode: 862,
        dialingCode: '+58',
        unCode: 'ven',
    },
    {
        code: 'vn',
        name: 'Viet Nam',
        numericalCode: 704,
        dialingCode: '+84',
        unCode: 'vnm',
    },
    {
        code: 'vg',
        name: 'Virgin Islands (British)',
        numericalCode: 92,
        dialingCode: '+1',
        unCode: 'vgb',
    },
    {
        code: 'vi',
        name: 'Virgin Islands (U.S.)',
        numericalCode: 850,
        dialingCode: '+1',
        unCode: 'vir',
    },
    {
        code: 'wf',
        name: 'Wallis and Futuna',
        numericalCode: 876,
        dialingCode: '+681',
        unCode: 'wlf',
    },
    {
        code: 'eh',
        name: 'Western Sahara',
        numericalCode: 732,
        dialingCode: '+212',
        unCode: 'esh',
    },
    {
        code: 'ye',
        name: 'Yemen',
        numericalCode: 887,
        dialingCode: '+967',
        unCode: 'yem',
    },
    {
        code: 'zm',
        name: 'Zambia',
        numericalCode: 894,
        dialingCode: '+260',
        unCode: 'zmb',
    },
    {
        code: 'zw',
        name: 'Zimbabwe',
        numericalCode: 716,
        dialingCode: '+263',
        unCode: 'zwe',
    },
    {
        code: 'ax',
        name: 'Åland Islands',
        numericalCode: 248,
        dialingCode: '+358',
        unCode: 'ala',
    },
];
