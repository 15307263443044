import { QuickAction } from '../../../../classes/quick-action.class';
import { DropdownButton } from '../../../buttons/dropdown/shared/dropdown-button';

export class GridHeader {
  name: string;
  description?: string;
  actions?: QuickAction[];
  bulkActions?: QuickAction[];
  classes?: string = '';
  show?: boolean = true;

  constructor(gridHeader: GridHeader) {
    Object.assign(this, gridHeader);
  }
}
