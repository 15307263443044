export class TradingAccountTransactionModel {
  amount: number;
  comment: string;
  expirationDate?: Date;
  withTransaction?: boolean;

  constructor(data: Partial<TradingAccountTransactionModel>) {
    Object.assign(this, data);
  }
}
