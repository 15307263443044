export enum GlobalTMPermissions {
    ADMIN = 'admin_TM',
}

export enum TradingAccountsPermissions {
    VIEW_ALL_TRADING_ACCOUNT = 'view-all-trading-account_TM',
    VIEW_TRADING_ACCOUNT = 'view-trading-account_TM',
    CREATE_TRADING_ACCOUNT = 'create-trading-account_TM',
    UPDATE_TRADING_ACCOUNT = 'update-trading-account_TM',
    DELETE_TRADING_ACCOUNT = 'delete-trading-account_TM',
    DEPOSIT_TRADING_ACCOUNT = 'deposit-trading-account_TM',
    WITHDRAWAL_TRADING_ACCOUNT = 'withdrawal-trading-account_TM',
    CREDIT_IN_TRADING_ACCOUNT = 'credit-in-trading-account_TM',
    CREDIT_OUT_TRADING_ACCOUNT = 'credit-out-trading-account_TM',
    CHANGE_PASSWORD_TRADING_ACCOUNT = 'change-password-trading-account_TM',
}

