/* tslint:disable:no-unused-expression */
import { ICellRenderer } from 'ag-grid-community';
import { CellRendererClickHandler } from '../shared/cell-click-params.interface';
import { EventAction } from '../../../classes/event-action.class';
import { PlatformGroupModel } from '../../../models';

export enum PlatformGroupEnum {
  ASSIGN_GROUP = 'assign-group',
  UNASSIGN_GROUP = 'unassign-group',
}

export default class AssignUnassignedButton implements ICellRenderer {
  public eGui: any;
  public eValue: any;
  public params: CellRendererClickHandler;

  public init(params: CellRendererClickHandler): void {
    this.params = params;
    const value = !!params.getValue();

    this.eGui = value ? this.generateTemplate(params) : undefined;
    value && this.addListeners();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: CellRendererClickHandler): any {
    this.params = params;
    this.eGui = params.getValue() ? this.generateTemplate(params) : undefined;
  }

  public generateTemplate = (params: CellRendererClickHandler): HTMLElement => {
    const eGui = document.createElement('div');
    const label = !!params.data.accountTypeId ? 'Unassign' : 'Assign';

    eGui.innerHTML = `<button class="btn-light-primary-light btn-small" style="line-height: 1.1; border-radius: 4px">${label}</button>`;
    return eGui;
  };

  public addListeners(): void {
    this.clickedHandler = this.clickedHandler.bind(this);
    this.eGui.addEventListener('click', this.clickedHandler);
  }

  public clickedHandler(): void {
    const data = this.params.data;
    const response: EventAction<PlatformGroupModel> = {
      data,
      gridRowIndex: this.params.rowIndex.toString(),
      key: !this.params.data.accountTypeId
        ? PlatformGroupEnum.ASSIGN_GROUP
        : PlatformGroupEnum.UNASSIGN_GROUP,
    };

    !this.params.data.accountTypeId ? this.params?.assign(response) : this.params.unassign(response);
  }

  public destroy(): void {
    if (!!this.eGui) {
      this.eGui.removeEventListener('click', this.clickedHandler);
    }
  }
}
