import { Injectable } from '@angular/core';
import { normalizeArray } from '../../../../../lib/src/helpers/helpers';

class UserGroups {
  groupsArray: string[] = [];
  groupObj: any = {};

  get groups(): string[] {
    return this.groupObj;
  }

  set groups(groups: string[]) {
    this.setupGroups(groups);
  }

  setGroup(group: string): void {
    this.groupObj[group] = group;
  }

  setupGroups(groups: string[]): void {
    groups.forEach(group => {
      this.setGroup(group);
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  userGroup: UserGroups = new UserGroups();

  getUserGroups(): any {
    return JSON.parse(localStorage.getItem('USER_GROUPS'));
  }

  loadGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      const groups = this.getUserGroups();
      if (groups) {
        this.userGroup.setupGroups(groups);
        resolve(true);
      } else {
        reject(false);
      }
    });
  }

  // OR groups
  hasPermission(groups: string | string[]): boolean {
    if (!groups) {
      return true;
    }
    groups = normalizeArray(groups);
    for (const group of groups) {
      if (this.userGroup.groups[group]) {
        return true;
      }
    }
    return false;
  }

  // AND groups
  hasAllPermission(groups: string | string[]): boolean {
    if (!groups) {
      return true;
    }

    let exist: boolean = true;
    groups = normalizeArray(groups);

    for (const group of groups) {
      if (!this.userGroup.groups[group]) {
        exist = false;
        break;
      }
    }
    return exist;
  }

}
