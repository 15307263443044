import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-toggle',
  template: `
    <div class="flex flex-col relative top-1" [class.has-error]="showError">
      <lib-toggle
        (handleOnChange)="onChangeValue($event)"
        [defaultValue]="defaultValue"
        [initValue]="initValue"
      ></lib-toggle>

      <div class="input-error toggle-error">
        <formly-validation-message *ngIf="showError && !field.wrappers.length" [field]="field">
        </formly-validation-message>
      </div>
    </div>
  `,
})
export class ToggleFormlyComponent extends FieldType {
  get label(): string {
    return this.to.label;
  }

  get wrapper(): string {
    return this.field.wrappers[0];
  }

  get defaultValue(): boolean | undefined {
    return !this.formControl.value ? this.field.defaultValue : undefined;
  }

  get initValue(): boolean {
    return this.formControl.value;
  }

  onChangeValue(value: boolean): void {
    this.formControl.setValue(value);
  }
}
