import { createFeatureSelector, createSelector } from '@ngrx/store';
import { pickListKey } from '../constants';
import { IPickListState } from '../models';

const pickListState = createFeatureSelector<IPickListState>(pickListKey);

const platformServer = createSelector(pickListState, (state: IPickListState) => state.platformServer);
const bank = createSelector(pickListState, (state: IPickListState) => state.bank);
const site = createSelector(pickListState, (state: IPickListState) => state.site);

export const fromPickList = {
  pickListState,
  platformServer,
  bank,
  site,
};
