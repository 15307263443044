export interface PickListValue {
  label: string;
  value: string;
  attributes: string | null;
  validFor: string | null;
}

export class PickValue {
  values: PickListValue[];
  defaultValue: string | Date | boolean | null;
  entity?: string;
}

export class FormlyPickValue {
  key: string;
  definition: PickValue;
}

export class PickList {
  [key: string]: PickValue;
}
