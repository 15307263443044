<mat-toolbar>
  <div class="flex justify-between w-full container-sec">
    <div class="justify-start flex">
      <div>
        <button
          (click)="createWorkspace()"
          class="bg-gray-850 text-white ml-2 text-sm px-4 py-2 rounded-sm flex items-center cursor-pointer select font-medium hover:bg-gray-800"
        >
          Add workspace
        </button>
      </div>
      <div class="justify-start flex items-center" *ngIf="workspaces?.length === 1">
        <div class="bg-indigo-50 text-indigo-500 ml-2 text-sm px-4 py-2 rounded-sm flex items-center">
          {{ (workspaceUser$ | async).workspace.name }}
        </div>
      </div>
      <div class="select" *ngIf="workspaces?.length > 1" style="min-width: 20rem">
        <div class="relative select" *ngIf="workspaceUser$ | async as workspaceUser">
          <button
            class="bg-indigo-50 text-indigo-500 ml-2 text-sm px-4 py-2 rounded-sm flex items-center cursor-pointer select font-medium"
          >
            {{ workspaceUser.workspace.name }}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ml-2 h-4 w-4 opacity-90"
              viewBox="0 0 18 18"
              fill="currentColor"
            >
              <path
                d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
              />
            </svg>
          </button>

          <ul
            class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm dropdown transition ease-in duration-100"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            <!-- RAFAEL -->
            <ng-container *ngFor="let orgWorkspaceSection of orgWorkspaces">
              <small class="font-medium text-indigo-500 pl-8 mt-1">
                {{ orgWorkspaceSection[0].organization.name }}
              </small>
              <li
                *ngFor="let orgWorkspace of orgWorkspaceSection"
                class="select-none relative py-2 pl-8 pr-4 text-gray-900 hover:bg-indigo-50 cursor-pointer"
                role="option"
                (click)="selectWorkspace(orgWorkspace)"
              >
                <span
                  class="block truncate w-full"
                  [ngClass]="
                    orgWorkspace.workspace.id === workspaceUser.workspace.id ? 'font-normal' : 'font-normal'
                  "
                >
                  {{ orgWorkspace.workspace.name }}
                </span>

                <span
                  [ngClass]="
                    orgWorkspace.workspace.id === workspaceUser.workspace.id
                      ? 'text-white'
                      : 'text-indigo-600'
                  "
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                >
                  <svg
                    *ngIf="orgWorkspace.workspace.id === workspaceUser.workspace.id"
                    class="h-5 w-5 text-indigo-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div class="justify-end flex">
      <div class="quick-actions" [matMenuTriggerFor]="menu">
        <div class="quick-action settings" [innerHTML]="svgIcons.settings | safe: 'html'"></div>
      </div>

      <!--  <ngx-avatar-->
      <!--    [name]="user?.name"-->
      <!--    [round]="false"-->
      <!--    [cornerRadius]="4"-->
      <!--    [size]="35"-->
      <!--    [textSizeRatio]="2"-->
      <!--    bgColor="#00C1E0">-->
      <!--  </ngx-avatar>-->

      <div class="profile-main-info" *ngIf="user$">
        <div>
          <h6>{{ (user$ | async).firstName }}</h6>
          <span>{{ (user$ | async).email }}</span>
        </div>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/profile">
      <mat-icon color="primary">perm_identity</mat-icon>
      <span>Profile</span>
    </button>
    <!-- <button mat-menu-item routerLink="/profile">
      <mat-icon color="primary">vpn_key</mat-icon>
      <span>Change Password</span>
    </button> -->
    <button mat-menu-item (click)="logout()">
      <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
