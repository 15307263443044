<div class="form">
  <h2>Verify Account</h2>
  <form [formGroup]="form" (submit)="submit()" novalidate>
    <formly-form [fields]="formFields" [form]="form" [model]="confirmCode">
      <div class="button">
        <button mat-stroked-button color="warn" class="btn-rounded btn-lg" [libSpinBtn]="saving">Confirm</button>
        <button routerLink="/auth/login" class="btn-rounded btn-lg" mat-stroked-button>Cancel</button>
      </div>
    </formly-form>
  </form>
  <div *ngIf="confButton" class="conf">
   Resend confirmation code <a style="cursor: pointer" [href] (click)="resendConfCode()">Sign Up</a>
  </div>
</div>
