import { AccountTypeRequest } from './account-type-request.class';

export class AccountTypeResponse extends AccountTypeRequest {
  id: string;
  orgId: string;
  workspaceId: string;
  serverId: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  rowVersion: number;
  createdDate: Date;
  lastModifiedDate: Date;

  constructor(accountTypeResponse: Partial<AccountTypeResponse>) {
    super();
    Object.assign(this, accountTypeResponse);
  }
}
