<div class="flex flex-col relative" [class.has-error]="showError">
  <input
    [formControl]="loadFormControl"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [type]="to.type || 'text'"
    [formlyAttributes]="field"
    [required]="required"
    (change)="changeInput($event)"
  />

  <div class="input-error">
    <formly-validation-message *ngIf="showError && !field.wrappers.length" [field]="field">
    </formly-validation-message>
  </div>

  <div class="text-xs text-gray-600 pl-1 pt-1">
    <mat-hint *ngIf="to.description" [id]="null">{{ to.description }}</mat-hint>
  </div>
</div>
