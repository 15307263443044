export class WorkspaceFormModel {
  orgId: string;
  workspace: {
    name?: string;
    locale?: string;
    language?: string;
    timezone?: string;
    product: {
      productName?: string;
      editionName?: string;
      editionLicenses?: string;
      addons?: [{ addonName: string; addonLicenses: number }];
    };
  };
  adminUser: {
    locale: string;
    language: string;
    timezone: string;
    isActive: boolean;
  } = {
    locale: 'en_CY',
    language: 'en',
    timezone: 'GMT',
    isActive: true,
  };

  constructor(data: Partial<WorkspaceFormModel>) {
    Object.assign(this, data);
  }
}
