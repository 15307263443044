export class PermissionSettingFormModel {
  permissionSet: {
    id: string;
    rowVersion: number;
  };
  permissionSetSettings: PermissionSetSettingFormModel[];
}

export class PermissionSetSettingFormModel {
  id: string;
  rowVersion: string;
  value: boolean | string;
}
