import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { WorkspaceReducer } from './state-management/reducers/workspace.reducer';
import { workspaceKey } from './state-management/constants';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(workspaceKey, WorkspaceReducer)],
})
export class WorkspaceStateModule {}
