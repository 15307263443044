<div class="input-container p-0">
  <input (change)="changeEvent($event)"
         [formlyAttributes]="field"
         [id]="id+'_input'"
         class="form-control-file"
         type="file">

  <button (click)="openFileBrowser(id+'_input', $event)" mat-icon-button>
    <mat-icon>cloud_upload</mat-icon>
  </button>
  <span (click)="openFileBrowser(id+'_input', $event)" class="file-info">
        {{fileName ? fileName : 'No File chosen.'}}
      </span>
</div>
