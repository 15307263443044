export enum TransactionPaymentStatus {
  INITIALIZED = 'initialized',
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  PARTIAL_REFUND = 'partial_refund',
  REFUND = 'refund',
  CHARGEBACK = 'chargeback',
  DUPLICATED = 'duplicated',
  REQUESTED = 'requested',
}
