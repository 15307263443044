export const RouterConstant = {
  home: { label: 'Home', routePath: 'home' },
  bank: { label: 'Carrier banks', routePath: 'bank', apiEndpoint: 'bank' },
  email: { label: 'System emails', routePath: 'emails', apiEndpoint: 'system-emails' },
  workspaceUser: { label: 'Workspace users', routePath: 'ws-users', apiEndpoint: 'workspace-user' },
  workspaceUserInvite: { label: 'Invite user', routePath: 'ws-users', apiEndpoint: 'workspace-user/invite' },
  workspaceUserAssignedPermissionSet: {
    label: 'Assigned permission sets',
    routePath: 'assigned-permission-set',
    apiEndpoint: 'workspace-user/permission-set',
  },
  orgUser: { label: 'Organization users', routePath: 'org-users', apiEndpoint: 'org-user' },
  hierarchy: { label: 'Hierarchy', routePath: 'hierarchy', apiEndpoint: 'hierarchy' },
  systemStatus: { label: 'System status', routePath: 'system-status', apiEndpoint: 'setting-status' },

  // Sites
  site: { label: 'Sites', routePath: 'sites', apiEndpoint: 'site' },
  siteRegistrationField: {
    label: 'Setup site registration fields',
    routePath: 'registration-fields',
    apiEndpoint: 'site-registration-field',
  },

  // Trading Manager
  platformServers: { label: 'Platform servers', routePath: 'platform-servers' },
  platformGroup: { label: 'platform groups', routePath: 'platform-groups' },
  accountTypes: { label: 'Account types', routePath: 'account-type' },
  questionnaire: { label: 'Questionnaires', routePath: 'questionnaire', apiEndpoint: 'questionnaire' },

  // Permission set
  permissionSet: { label: 'Permission sets', routePath: 'permission-set', apiEndpoint: 'permission-set' },
  permissionSetSetting: {
    label: 'Permission settings',
    routePath: 'permission-settings',
    apiEndpoint: 'permission-set/setting',
  },
  permissionSetObject: {
    label: 'Object permissions',
    routePath: 'object-permission',
    apiEndpoint: 'permission-set/object-permission',
  },

  // Settings
  settings: { label: 'Settings', routePath: 'settings' },
  smtp: { label: 'SMTP', routePath: 'smtp', apiEndpoint: 'smtp-config' },
  kyc: { label: 'KYC', routePath: 'kyc' },
  paymentProvider: { label: 'Payment provider', routePath: 'payment-provider' },
  marketing: { label: 'Marketing', routePath: 'marketing' },
  compliance: {
    label: 'Compliance',
    routePath: 'compliance',
    apiEndpoint: 'compliance-questionnaire-config',
  },
  shuftiPro: { label: 'Shufti Pro', routePath: 'shufti-pro', apiEndpoint: 'kyc-config/shufti-pro' },
  sumsub: { label: 'Sumsub', routePath: 'sumsub', apiEndpoint: 'kyc-config/sumsub' },
  praxis: { label: 'Praxis Cashier', routePath: 'praxis', apiEndpoint: 'gateway-config/praxis' },
  mailchimp: { label: 'Mailchimp', routePath: 'mailchimp', apiEndpoint: 'mailchimp-config' },

  livechat: { label: 'Livechat', routePath: 'livechat' },
  freshchat: { label: 'FreshChat', routePath: 'freshchat', apiEndpoint: 'fresh-chat-config' },
};
