export class TargetActivityModel {
  activity: {
    day: Date;
    emailsSent: number;
    uniqueOpens: number;
    recipientClicks: number;
    hardBounce: number;
    softBounce: number;
    subscribed: number;
    unsubscribed: number;
    otherAdds: number;
    otherRemoves: number;
  }[];
  listId: string;
  totalItems: number;

  constructor(data: Partial<TargetActivityModel>) {
    Object.assign(this, data);
  }
}
