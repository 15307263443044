import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YesNoPipe } from './yes-no.pipe';
import { DateMomentPipe } from './date-moment.pipe';
import { HumanizeStringPipe } from './humanize-string.pipe';
import { NotSetPipe } from './not-set.pipe';
import { SafePipe } from './safe.pipe';
import { PhonePipe } from './phone.pipe';

@NgModule({
  declarations: [
    YesNoPipe,
    DateMomentPipe,
    HumanizeStringPipe,
    NotSetPipe,
    SafePipe,
    PhonePipe,
  ],
  exports: [
    YesNoPipe,
    DateMomentPipe,
    HumanizeStringPipe,
    NotSetPipe,
    SafePipe,
    PhonePipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
