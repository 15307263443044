<div class="flex justify-between items-center py-3 pl-3 pr-8 rounded-l">
  <div class="left flex items-center">
    <div [innerHTML]="svgIcons.arrowLeft | safe" (click)="clickBack()" class="mr-2"></div>
    <form id="form" [formGroup]="form" novalidate (submit)="submit()">
      <formly-form [fields]="formFields" [form]="form" [model]="obj"></formly-form>
    </form>
  </div>
  <div class="right">
    <button form="form" class="btn-success">Save</button>
  </div>
</div>
