<ng-container *ngIf="tabs.length">
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a link</label>
    <select id="tabs"
            name="tabs"
            (change)="navigateTo($event)"
            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
      <ng-container *ngFor="let tab of tabs">
        <option *ngIf="tab.visible"
                [value]="tab.routerLink"
                [selected]="isRouteActive(tab.routerLink)">{{tab.label}}</option>
      </ng-container>
    </select>
  </div>
  <div class="hidden sm:block" [class]="classes">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <ng-container *ngFor="let tab of tabs">
          <a *ngIf="tab.visible && tab.routerLink"
            [routerLink]="tab.routerLink"
            [class.active]="isRouteActive(tab.routerLink)"
            class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm">
            {{tab.label}}
          </a>
          <a *ngIf="tab.visible && !tab.routerLink"
             (click)="tabSelected(tab)"
             [class.active]="isRouteActive(tab.routerLink)"
             class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm">
            {{tab.label}}
          </a>
        </ng-container>
      </nav>
    </div>
  </div>
</ng-container>
