<div class="w-full h-full justify-center flex px-24 py-10" *ngIf="user && workspaces && workspaces.length">
  <div class="">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
        alt="Workflow"
      />
      <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-900">
        Welcome back {{ user.firstName }}!
      </h2>

      <p class="mt-2 text-center text-sm text-gray-600">
        Choose a{{ selectedOrgUser ? '' : 'n' }}
        <a *ngIf="!selectedOrgUser" class="font-medium text-indigo-600"> organization </a>
        <a *ngIf="selectedOrgUser" class="font-medium text-indigo-600"> workspace </a>
        below to get back to working.
      </p>
    </div>

    <ng-container *ngIf="selectedOrgUser; then showWorkspaces; else organizations"></ng-container>
  </div>
  <div class="absolute bottom-1 hidden md:flex cursor-pointer">
    <p class="text-center text-sm text-gray-600">
      <span class="font-medium text-indigo-600">
        {{ user.firstName | uppercase }} {{ user.lastName | uppercase }}
      </span>
    </p>
  </div>
  <div class="text-center absolute bottom-6 cursor-pointer" (click)="logOut()">
    <p class="ml-3 font-medium text-sm text-indigo-600">Log out</p>
  </div>
</div>

<ng-template #organizations>
  <div class="bg-white shadow overflow-hidden sm:rounded-md mt-16">
    <ul role="list" class="divide-y divide-gray-200">
      <li *ngFor="let workspace of workspaces" (click)="handleOrgSelection(workspace.orgUser)">
        <a class="block hover:bg-gray-50 cursor-pointer">
          <div class="px-4 py-4 flex items-center sm:px-6">
            <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div class="truncate">
                <div class="flex text-sm">
                  <p class="font-medium text-indigo-600 truncate">{{ workspace.organization.name }}</p>
                  <p class="ml-2 flex-shrink-0 font-normal text-gray-500">Organization</p>
                </div>
                <div class="mt-2 flex">
                  <div class="flex items-center text-sm text-gray-500">
                    <svg
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p class="mr-3">
                      Registered on
                      <time datetime="2020-01-07">{{
                        workspace.orgUser.createdDate | dateMoment: 'MMM DD, YYYY'
                      }}</time>
                    </p>
                    <ng-container *ngIf="workspace.orgUser.lastLoginDate">
                      <svg
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p>
                        Last login on
                        <time datetime="2020-01-07">{{
                          workspace.orgUser.lastLoginDate | dateMoment: 'MMM DD, YYYY'
                        }}</time>
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                <div class="flex overflow-hidden -space-x-1"></div>
              </div>
            </div>
            <div class="ml-5 flex-shrink-0">
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #showWorkspaces>
  <div class="justify-center mt-16 pt-2">
    <div class="col--1 max-w-xl flex shadow-sm rounded-md mx-auto">
      <div
        class="mr-3 flex items-center text-gray-500 hover:text-indigo-500 cursor-pointer text-xl"
        (click)="backToOrgList()"
      >
        <i [innerHTML]="svgIcons.arrowLeftCurly | safe"></i>
      </div>
      <div
        class="flex-shrink-0 flex items-center justify-center w-16 bg-green-500 text-white text-sm font-medium rounded-l-md"
      >
        {{ avatar(selectedOrg.name) }}
      </div>
      <div
        class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate"
      >
        <div class="flex-1 px-4 py-2 text-sm truncate">
          <a class="text-gray-900 font-medium">{{ selectedOrg.name }}</a>
          <p class="text-gray-600">{{ selectedOrgWorkspaces.length }} workspaces</p>
        </div>
      </div>
    </div>
  </div>
  <div class="justify-center mx-auto mt-12">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <ul
      role="list"
      class="grid grid-cols-1 gap-6 sm:grid-cols-2"
      [class]="selectedOrgWorkspaces.length === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'"
    >
      <li
        class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
        *ngFor="let item of selectedOrgWorkspaces"
      >
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
              <h3 class="text-gray-900 text-sm font-medium truncate">{{ item.workspace.name }}</h3>
              <span
                *ngIf="item.orgUser.isSuperAdmin"
                class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full"
                >Admin</span
              >
            </div>
            <p class="mt-1 text-gray-500 text-sm truncate">{{ item.orgUser.title }}</p>
          </div>
          <img
            class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
            alt=""
          />
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
              <a
                (click)="chooseWorkspace(item)"
                class="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-green-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="ml-3">Connect</span>
              </a>
            </div>
          </div>
        </div>
      </li>

      <!-- More people... -->
    </ul>
  </div>
</ng-template>
