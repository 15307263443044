import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-number-input',
  template: `
    <mat-form-field [style.width]="'100%'">
      <input
        matInput
        type="number"
        [minLength]="minLength"
        [maxLength]="getMaxLength"
        [placeholder]="placeholder"
        [formControl]="formControl"
      />
    </mat-form-field>
  `,
})
export class NumberInputComponent extends FieldType {
  get placeholder(): string {
    return this.to.placeholder;
  }

  get minLength(): number {
    return this.to.minLength;
  }

  getMaxLength(): number {
    return this.to.maxLength;
  }
}
