<div class="form">
  <h2>Reset Password</h2>
  <form [formGroup]="form" (submit)="submit()" novalidate>
    <formly-form [fields]="formFields" [form]="form">
      <div class="button">
        <button class="btn-rounded btn-lg" mat-stroked-button color="warn">Reset</button>
        <button class="btn-rounded btn-lg" routerLink="/auth/login" mat-stroked-button>Cancel</button>
      </div>
    </formly-form>
  </form>
</div>

