import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'lib-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TagInputComponent)
    }
  ]
})
export class TagInputComponent implements OnInit {
  @Input() required: false = false;
  @Input() placeholder: string = 'Select';
  @Input() formControl: FormControl = new FormControl();

  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;

  items: string[] = [];
  suggestions: Observable<string[]>;
  filteredItems: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {}

  ngOnInit() {}

  add(event: MatChipInputEvent) {
    if (this.matAutocomplete.isOpen || !this.items) {
      return;
    }

    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.items.push(value.trim());
      this.propagateChange(this.items);
    }

    if (input) {
      input.value = '';
    }
  }

  inputChange(value) {
    if (this.suggestions) {
      this._filter(value.trim());
    }
  }

  remove(value) {
    const index = this.items.findIndex(val => val === value);

    if (index === -1) {
      return;
    }

    this.items.splice(index, 1);
    this.propagateChange(this.items);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.items.push(event.option.viewValue);
    this.input.nativeElement.value = '';

    this.propagateChange(this.items);
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(items: string[]): void {
    if (items !== undefined && items !== null) {
      this.items = items;
    }
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredItems = this.suggestions
      .pipe(
        map(items => {
          return items.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
        })
      );
  }
}
