import {Component, OnInit, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import {FieldType} from '@ngx-formly/core';
import {MatFormlyFieldConfig} from '../../../wrappers/formly-form-field-wrapper.component';

@Component({
  selector: 'lib-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent extends FieldType implements OnInit {
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;
  hide = true;
  errorStateMatcher: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  get formlyField(): MatFormlyFieldConfig {
    return this.field as MatFormlyFieldConfig;
  }
}
