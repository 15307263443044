import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends FieldType {
  get loadFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  get placeholder(): string {
    return this.to.placeholder;
  }

  get label(): string {
    return this.to.label;
  }

  get disabled(): boolean {
    return this.to.disabled;
  }

  get required(): boolean {
    return this.to.required;
  }

  get mask(): string {
    return this.field.templateOptions.mask || '';
  }

  get type(): string {
    return this.to.type;
  }

  get wrapper(): string {
    return this.field.wrappers[0];
  }

  get description(): string {
    return this.to.description || '';
  }

  changeInput(event): void {
    const value = event?.target?.value;
    value === '' && this.formControl.setValue(null);
  }
}
