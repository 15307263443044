import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { MatFormlyFieldConfig } from '../../../wrappers/formly-form-field-wrapper.component';
import * as moment from 'moment';

@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],

})
export class DatePickerComponent extends FieldType {
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;

  hide = true;
  errorStateMatcher: any;

  constructor() {
    super();
  }

  get formlyField(): MatFormlyFieldConfig {
    return this.field as MatFormlyFieldConfig;
  }

  get staticDate(): boolean {
    return this.to.static || false;
  }

  public selectDate(date: any): void {
    const setDate = this.staticDate ? moment(date.value).format('YYYY-MM-DD') : date.value;
    this.formControl.setValue(setDate);
  }
}
