import { Component } from '@angular/core';
import { BaseComponent } from '@lib/classes/base-component.class';
import { AllowedAction } from '@lib/interfaces';
import { Menu } from '@lib/layout/shared/menu.class';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions } from '../state-management/actions';
import { IAuthAppState } from '../state-management/models';
import { fromAuth } from '../state-management/selectors';
import { WorkspaceUserService } from '../workspace-users/shared/workspace-user.service';
import { RouterConstant } from '../core/route-constants';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent {
  menus: Menu[];
  workspaceID$: Observable<string>;

  constructor(private authStore: Store<IAuthAppState>, private wsUserService: WorkspaceUserService) {
    super();
    this.setMenu();
    this.workspaceID$ = this.authStore.select(fromAuth.selectWorkspaceID);
    this._getUserActions();
  }

  setMenu(): void {
    const {
      home,
      workspaceUser,
      bank,
      email,
      questionnaire,
      site,
      platformServers,
      permissionSet,
      hierarchy,
      settings,
      systemStatus,
      orgUser,
    } = RouterConstant;

    // Todo: Permissions here!
    this.menus = [
      new Menu({
        label: 'Home',
        route: '/' + home.routePath,
        icon: 'home',
      }),
      new Menu({
        label: email.label,
        route: email.routePath,
        icon: 'email',
        show: true,
      }),
      new Menu({
        label: bank.label,
        route: bank.routePath,
        icon: 'account_balance',
        show: true,
      }),
      new Menu({
        label: platformServers.label,
        route: 'trading-manager/' + platformServers.routePath,
        icon: 'storage',
        show: true,
      }),
      new Menu({
        label: questionnaire.label,
        route: 'trading-manager/' + questionnaire.routePath,
        icon: 'question_answer',
        show: true,
      }),
      new Menu({
        label: site.label,
        route: site.routePath,
        icon: 'laptop_mac',
        show: true,
      }),
      new Menu({
        label: workspaceUser.label,
        route: workspaceUser.routePath,
        icon: 'person',
        show: true,
      }),
      new Menu({
        label: orgUser.label,
        route: orgUser.routePath,
        icon: 'group',
        show: true,
      }),
      new Menu({
        label: 'Workspace',
        icon: 'business',
        isOpen: false,
        submenu: [
          new Menu({
            label: hierarchy.label,
            route: hierarchy.routePath,
            icon: 'accessibility',
          }),
          new Menu({
            label: permissionSet.label,
            route: permissionSet.routePath,
            icon: 'work',
          }),
          new Menu({
            label: settings.label,
            route: settings.routePath,
            icon: 'settings',
          }),
          new Menu({
            label: 'Profile',
            route: '/profile',
            icon: 'person',
          }),
          new Menu({
            label: systemStatus.label,
            route: systemStatus.routePath,
            icon: 'web_asset',
          }),
        ],
      }),
    ];
  }

  private _getUserActions(): void {
    this.workspaceID$.subscribe((workspaceId: string) => {
      workspaceId &&
        this.wsUserService.allowedActions().subscribe({
          next: (actions: AllowedAction[]) => {
            this.authStore.dispatch(AuthActions.setWsUserActions({ actions }));
          },
        });
    });
  }
}
