import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../../auth/shared/authorization.service';
import { Router } from '@angular/router';
import { ConfigService } from '@lib/services/shared/config.service';
import { AuthService } from '../../auth/shared/auth.service';
import { RouterConstant } from '../route-constants';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private configService: ConfigService,
    private authService: AuthService,
    private authorizationService: AuthorizationService,
  ) {}

  get router(): Router {
    return this.injector.get(Router);
  }

  loadAppConfig(endpoint: string[] = ['/config.json']): Promise<any> {
    return this.configService
      .loadConfig(endpoint)
      .then((): any => {
        if (this.isLogin()) {
          if (!this.hasOrg()) {
            return this.router.navigate(['auth', 'organization']);
          }
          return this.authService
            .refreshToken()
            .toPromise()
            .then((): any => {
              // return this.profileService.getProfile().toPromise().then(() => {
              //   return this.authorizationService.loadGroups().then((): any => {

              if (location.pathname.includes('/auth/')) {
                return this.router.navigate([RouterConstant.home.routePath]);
              }
              //   });
              // }).catch(() => {
              //   return this._logOutApp();
              // });
            })
            .catch(() => {
              return this._logOutApp();
            });
        } else {
          return this.router.navigate(['auth', 'login']);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  private isLogin(): boolean {
    return !!JSON.parse(localStorage.getItem('USER_NAME'));
  }

  private hasOrg(): boolean {
    return !!this.authService.getOrganization();
  }

  private _logOutApp(): boolean {
    this.authService.logout();
    return false;
  }
}
