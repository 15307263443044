// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   apiBaseHref: 'https://localhost:3000',
//   apiBasePmHref: 'https://localhost:3000',
//   apiBaseTmHref: 'https://localhost:3002',
//   apiBasePSP: 'https://localhost:3003',
//   production: false,
//   hmr: false,
// };

export const environment = {
  apiBaseHref: 'https://admin-api.prapps.io',
  apiBasePmHref: 'https://admin-api.prapps.io',
  apiBaseTmHref: 'https://tm-api.prapps.io',
  apiBasePSP: 'https://psp-api.prapps.io',
  production: true,
  hmr: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
