import { createAction, props } from '@ngrx/store';
import { OrgUserModel } from '@lib/models';

const setOrgUser = createAction('[Org User] Set', props<{ orgUser: OrgUserModel }>());
const updateOrgUser = createAction('[Org User] Update', props<{ orgUser: OrgUserModel }>());

export const OrgActions = {
  setOrgUser,
  updateOrgUser,
};
