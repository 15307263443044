<div
  [class.hideBorder]="!displayBorder"
  [class.hideShadow]="!isError && (hideBoxShadow || !(btnName && btnIsVisible))"
  class="flex items-center justify-center py-16 px-8 text-center w-full rounded bg-white card-shadow"
  [ngClass]="isError ? 'border-danger border-dashed border' : ''"
>
  <div class="flex flex-col items-center justify-center">
    <div
      class="flex justify-center items-center mb-4 svg-50"
      [ngClass]="isError ? 'text-danger' : 'text-primary'"
      [innerHTML]="svgIcon | safe"
    ></div>
    <h4 class="mb-1 font-bold text-base text-gray-800">{{ title }}</h4>
    <div class="description text-sm font-medium text-gray-500">
      {{ description }}
    </div>
    <button *ngIf="btnIsVisible && btnName" (click)="handleButtonAction()" class="btn-primary mt-6">
      {{ btnName }}
    </button>
  </div>
</div>
