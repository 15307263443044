import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { OrganizationModel } from '../organization/organization-model.class';
import { WorkspaceModel } from '../workspace/workspace-model.class';
import { BaseModel } from './base-model.class';

@Exclude()
export class BaseOrgWorkspaceModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  orgId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  workspaceId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => WorkspaceModel)
  workspace?: WorkspaceModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  constructor(data: Partial<BaseOrgWorkspaceModel>) {
    super(data);
    Object.assign(this, data);
  }
}
