<!-- <input type="radio" [value]="getValue" [hidden]="true" /> -->
<!-- <div [title]="getValue"></div> -->
<ng-container [ngSwitch]="radioMode">
  <div *ngSwitchCase="1" class="flex">
    <div
      *ngFor="let radioOption of radioOptions"
      (click)="selectOption(radioOption.id)"
      [class.active]="radioOption.active"
      class="box py-2 px-3 rounded mr-2 mt-1"
    >
      {{ radioOption.name }}
    </div>
  </div>

  <div *ngSwitchCase="2">
    <div class="border border-2 border-color-gray-100 rounded inline-flex">
      <div
        *ngFor="let radioOption of radioOptions"
        class="py-2 px-8 border-1 border-solid border-transparent group"
        (click)="selectOption(radioOption.id)"
        [class.active]="radioOption.active"
      >
        {{ radioOption.name }}
      </div>
    </div>
  </div>

  <div *ngSwitchCase="3">
    <div>
      <p *ngIf="description" class="mt-1 text-sm text-gray-600">{{ description }}</p>
      <fieldset class="mt-2">
        <legend class="sr-only">{{ to.description }}</legend>
        <div class="divide-y divide-gray-200">
          <div *ngFor="let radioOption of radioOptions" class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="id-{{ radioOption.name }}" class="font-medium text-gray-700">{{
                radioOption.name
              }}</label>
              <p id="account-checking-description" class="text-gray-500">{{ radioOption.description }}</p>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input
                id="id-{{ radioOption?.name }}"
                name="account"
                (click)="selectOption(radioOption.id)"
                [checked]="radioOption.active"
                type="radio"
                class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</ng-container>
