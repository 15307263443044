/* tslint:disable:no-unused-expression */
import { ICellRenderer } from 'ag-grid-community';
import { getProperty } from '../../../helpers/helpers';
import { CellRendererClickHandler } from '../shared/cell-click-params.interface';

export default class Checkbox implements ICellRenderer {
  public eGui: any;
  public eValue: any;
  public params: CellRendererClickHandler;

  public init(params: CellRendererClickHandler): void {
    this.params = params;

    this.eGui = this.generateTemplate(params);
    this.addListeners();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: CellRendererClickHandler): any {
    this.params = params;
    this.eGui = this.generateTemplate(params);
  }

  public generateTemplate = (params: CellRendererClickHandler): HTMLElement => {
    const eGui = document.createElement('div');
    const value = params.getValue();
    const data = params.data;
    const disabled = !!data ? getProperty(params.disableBy, data) : false;

    const id = params.data?.id + '-' + params.column.getColId();
    const name = params.colDef.headerName ?? '';

    eGui.innerHTML = !!value
      ? `<input id="${id}" alt="${name}"  type="checkbox"  class="my-checkbox"   checked  ${
          !disabled ? 'disabled' : ''
        }>`
      : `<input id="${id}" alt="${name}"  type="checkbox"  class="my-checkbox ${
          !disabled ? 'disabled' : ''
        }">`;
    return eGui;
  };

  public generateNone = (): HTMLElement => {
    const eGui = document.createElement('div');
    eGui.innerHTML = '-';
    return eGui;
  };

  public addListeners(): void {
    this.clickedHandler = this.clickedHandler.bind(this);
    this.eGui.addEventListener('click', this.clickedHandler);
  }

  public clickedHandler(): void {
    this.params.touched = true;
    this.params.setValue(!this.params.getValue());
    this.params?.clicked && this.params?.clicked(this.params.data);
  }

  public destroy(): void {
    if (!!this.eGui) {
      this.eGui.removeEventListener('click', this.clickedHandler);
    }
  }
}
