import { BaseComponent } from './base-component.directive';
import { BaseDetails } from './base-details.directive';
import { BaseDialogForm } from './base-dialog-form.directive';
import { BaseEntityDialogForm } from './base-entity-dialog-form.directive';
import { BaseEntityForm } from './base-entity-form.directive';
import { BaseForm } from './base-form.directive';
import { BaseGrid } from './base-grid.directive';
import { BaseRelated } from './base-related.directive';
import { BaseView } from './base-view.directive';

export {
  BaseEntityForm,
  BaseDialogForm,
  BaseEntityDialogForm,
  BaseGrid,
  BaseRelated,
  BaseDetails,
  BaseComponent,
  BaseView,
  BaseForm,
};
