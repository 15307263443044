import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { AccountModel } from '../account-model/account-model';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { ContactModel } from '../contact/contact.model';
import { OrgUserModel } from '../user/org-user.model';

@Exclude()
export class LeadModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  firstName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  lastName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  email: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  company: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  status: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  preferredLanguage: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  phone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mobilePhone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fax: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  leadSource: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  department: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  addressStreet: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  addressCity: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  addressCountry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  addressPostalCode: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  addressState: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  salutation: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  productInterest: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  industry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  annualRevenue: number | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfEmployees: number | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  title: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  website: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  rating: string | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  ownerId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  convertedAccountId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  convertedContactId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  convertedOpportunityId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isUnreadByOwner: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isConverted: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  convertedDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastActivityDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastViewedDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastTransferDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountModel)
  account?: AccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => ContactModel)
  contact?: ContactModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  owner?: OrgUserModel;

  constructor(lead: Partial<LeadModel>) {
    super(lead);
    Object.assign(this, lead);
  }
}
