import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown/dropdown.component';
import { PipesModule } from '../../pipes/pipes.module';
import { NavigationModule } from '../navigation/navigation.module';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
  declarations: [DropdownComponent, RadioButtonComponent, ToggleComponent],
  imports: [CommonModule, PipesModule, NavigationModule],
  exports: [DropdownComponent, RadioButtonComponent, ToggleComponent],
})
export class ButtonsModule {}
