import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseFormComponent } from '@lib/classes/base-form-component.class';
import { ToastService } from '@lib/services/toast/toast.service';
import { QuickDialog } from '@lib/components/dialog/dialog/shared/quick-dialog.class';
import { AuthService } from '../../../auth/shared/auth.service';
import { WorkspaceFormModel } from './workspace-form.model';

@Component({
  selector: 'app-create-workspace-form',
  templateUrl: './create-workspace-form.component.html',
  styleUrls: ['./create-workspace-form.component.scss'],
})
export class CreateWorkspaceFormComponent extends BaseFormComponent implements OnInit {
  public workspace: WorkspaceFormModel = new WorkspaceFormModel({});

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: QuickDialog,
    private mdDialogRef: MatDialogRef<WorkspaceFormModel>,
  ) {
    super();
  }

  cancel(): void {
    this.mdDialogRef.close(false);
  }

  save(): void {
    this.setSaving(true);

    this.subscription = this.authService.createWorkspace(this.workspace).subscribe({
      next: () => {
        this.setSaving(false);
        this.toastService.showSuccess(`<b>Success: </b> Workspace created`);
        this.mdDialogRef.close(true);
      },
      error: (err) => {
        this.setSaving(false);
        console.log(err);
        this.toastService.showError(`<b>Error: </b>` + err.error.message);
      },
    });
  }

  ngOnInit(): void {
    this._init();
    this._createForm();
  }

  private _init(): void {
    const orgId = this.authService.getOrganization();
    this.workspace = {
      ...this.workspace,
      orgId,
      workspace: {
        product: {
          productName: 'sales_cloud',
          editionName: 'enterprise',
        },
      },
    };
  }

  private _createForm(): void {
    this.formFields = [
      {
        key: 'workspace.name',
        type: 'my-input',
        wrappers: ['horizontal'],
        templateOptions: {
          label: 'Name',
          placeholder: 'Type preferred workspace name',
          required: true,
        },
      },
      {
        key: 'workspace.locale',
        type: 'my-input',
        wrappers: ['horizontal'],
        defaultValue: 'en_CY',
        templateOptions: {
          label: 'Locale',
          placeholder: 'Workspace locale',
          required: true,
        },
      },
      {
        key: 'workspace.language',
        wrappers: ['horizontal'],
        type: 'my-input',
        defaultValue: 'en',
        templateOptions: {
          label: 'Language',
          placeholder: 'Workspace language',
          required: true,
        },
      },
      {
        key: 'workspace.timezone',
        wrappers: ['horizontal'],
        type: 'my-input',
        defaultValue: 'GMT',
        templateOptions: {
          label: 'Timezone',
          placeholder: 'Workspace timezone',
          required: true,
        },
      },
      {
        key: 'workspace.product.editionLicenses',
        wrappers: ['horizontal'],
        type: 'my-number',
        templateOptions: {
          label: 'Edition licenses',
          placeholder: 'Define workspace edition licenses',
          required: true,
        },
      },
    ];
  }
}
