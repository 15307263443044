import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { DocumentModel } from './document-model';

@Exclude()
export class DocumentTranslationModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  language: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  documentId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => DocumentModel)
  document?: DocumentModel;

  @Expose({ groups: [ClassExposeGroups.CREATE] })
  @Type(() => Object)
  file?: File;

  constructor(document: Partial<DocumentModel>) {
    super(document);
    Object.assign(this, document);
  }
}
