import { AfterViewInit, Directive, Input, ViewChild } from '@angular/core';
import { TypedAction } from '@ngrx/store/src/models';
import { EventAction } from '../classes/event-action.class';
import { QuickAction } from '../classes/quick-action.class';
import { DropdownButton } from '../components/buttons/dropdown/shared/dropdown-button';
import { DialogService } from '../components/dialog/dialog/shared/dialog.service';
import { ResultDialog } from '../components/dialog/dialog/shared/result-dialog.interface';
import { GridHeader } from '../components/grid/grid-header/shared/grid-header';
import { GridComponent } from '../components/grid/grid.component';
import { GridColumn } from '../components/grid/shared/grid-column.interface';
import { FilterModel } from '../filters/filter-model.class';
import { QuickPanelCloseStatus } from '../layout/quick-panel/shared/quick-panel-close-status.enum';
import { BaseComponent } from './base-component.directive';

@Directive()
export abstract class BaseGrid<T> extends BaseComponent implements AfterViewInit {
  @ViewChild('grid', { static: false }) grid: GridComponent<T>;
  @Input() relatedFilter: FilterModel;

  public entityKey: string;
  public entityDialogService: DialogService<T>;
  public emptyResultsAction: QuickAction;

  public gridExpands: string[] = [];
  public gridInitFilter: FilterModel;
  public storeAction: (records: T[]) => { records: T[] } & TypedAction<string>;

  cellActions: DropdownButton[];
  columns: GridColumn[];
  gridHeader: GridHeader;
  emptyResults: QuickAction;

  reloadGridFn = () => this.grid.reloadGrid();

  ngAfterViewInit(): void {
    !!this.entityDialogService && this._subscribeToTheDialogService();
  }

  public updateRecord(updatedValue: T, eventAction: EventAction<T>): void {
    eventAction.data = {
      ...eventAction.data,
      ...updatedValue,
    };
    this.grid.updateRecord(eventAction);
  }

  public relatedId(key: string): string | null {
    return this.relatedFilter?.[key] ? this.relatedFilter?.[key].filter : null;
  }

  public generateFilter(key: string, id: string): void {
    this.relatedFilter = {
      [key]: {
        filterType: 'id',
        type: 'equals',
        filter: id,
      },
    };
  }

  public subscribeToDialog(result: ResultDialog<T>): void {}

  private _subscribeToTheDialogService(): void {
    this.subscription = this.entityDialogService.getResult().subscribe({
      next: (res: ResultDialog<T>) => {
        if (res.status === QuickPanelCloseStatus.SUCCESS) {
          this.subscribeToDialog(res);
        }
      },
    });
  }

  // ngOnInit(): void {
  //   this._checkForRelatedFilter();
  // }

  // private _checkForRelatedFilter(): void {
  //   const key = this.entityKey;
  //   const value: string | null = this.relatedFilter?.[key] ? this.relatedFilter?.[key].filter : null;
  // }
}
