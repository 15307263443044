import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { MaterialModule } from '../../material.module';

import { CountryComponent } from './country/country.component';
import { CountryPickerComponent } from './country-picker.component';
import {FormlyModule} from '@ngx-formly/core';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    NgSelectModule,
    FormlyModule,
  ],
  declarations: [
    CountryComponent,
    CountryPickerComponent
  ],
  exports: [
    CountryComponent,
    CountryPickerComponent
  ]
})
export class CountryPickerModule {}
