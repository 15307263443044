import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';
import { OrgUserModel } from '../user/org-user.model';

@Exclude()
export class SiteModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  adminId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  archiveStatus: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  archivedById: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  archiveDate: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  masterLabel: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  siteStatus: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  optionRedirectToCustomDomain: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  urlPathPrefix: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  analyticsTrackingCode: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  networkId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  adminUser?: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  archivedByUser?: OrgUserModel;

  constructor(site: Partial<SiteModel>) {
    super(site);
    Object.assign(this, site);
  }
}
