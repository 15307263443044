import { Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';

export class OrderModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  order: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  externalID: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  login: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  dealer: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  symbol: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  digits: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  digitsCurrency: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  contractSize: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  state: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  reason: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  modificationFlags: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  type: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  typeFill: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  typeTime: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceOrder: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceTrigger: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceCurrent: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceSL: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceTP: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  volumeInitial: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  volumeCurrent: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  expertID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  positionID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  positionByID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  comment: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationMode: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationTime: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationPrice: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationFlags: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  timeDoneMsc: Date;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  timeSetupMsc: Date;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  timeExpiration?: Date;

  constructor(orders: OrderModel) {
    Object.assign(this, orders);
  }
}
