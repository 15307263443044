import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SecondPhoneComponent } from '../../../components/second-phone/second-phone.component';
import { MatFormlyFieldConfig } from '../../wrappers/formly-form-field-wrapper.component';

@Component({
  selector: 'lib-second-phone-select',
  template: `
    <lib-third-phone
      *ngIf="version && version===3"
      id="{{key}}"
      [autodetect]="autoDetect"
      [validatePhone]="validatePhone"
      [placeholder]="placeholder"
      [formControl]="formControl"
      [labelForId]="id+'_input'"
      [label]="label"
      [description]="description"
      [hideLocalErrorDetection]="controlType=== 'phone'"
      [required]="required"
      [formlyAttributes]="formlyField">
    </lib-third-phone>
    <lib-second-phone
      *ngIf="!version"
      id="{{key}}"
      [autodetect]="autoDetect"
      [validatePhone]="validatePhone"
      [placeholder]="placeholder"
      [formControl]="formControl"
      [labelForId]="id+'_input'"
      [label]="label"
      [description]="description"
      [hideLocalErrorDetection]="controlType=== 'phone'"
      [required]="required"
      [formlyAttributes]="formlyField">
    </lib-second-phone>
  `,
})
export class SecondPhoneSelectComponent extends FieldType {

  @ViewChild(SecondPhoneComponent, {static: true} as any) formFieldControl!: SecondPhoneComponent;

  get autoDetect(): boolean {
    return this.to.autodetect || false;
  }

  get label(): string {
    return this.to.label;
  }

  get placeholder(): string {
    return this.to.placeholder;
  }

  get validatePhone(): boolean {
    return this.to.validatePhone || false;
  }

  get required(): boolean {
    return this.to.required;
  }

  get description(): string {
    return this.to.description;
  }

  get formlyField(): MatFormlyFieldConfig {
    return this.field as MatFormlyFieldConfig;
  }

  get controlType(): string {
    return this.to.type;
  }

  get version(): number {
    return this.to.version;
  }

}
