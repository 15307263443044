export class ObjectPermissionFormModel {
  permissionSet: {
    id: string;
    rowVersion: number;
  };
  objectPermissions: ObjectPermissionValueModel[];
}

export class ObjectPermissionValueModel {
  id: string;
  rowVersion: number;
  permissionRead: boolean;
  permissionCreate: boolean;
  permissionEdit: boolean;
  permissionDelete: boolean;
}
