import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../base';
import { ConfirmationPopup } from '../../components/dialog/confirmation-popup/confirmation-popup.component';
import { DialogService } from '../../components/dialog/dialog/shared/dialog.service';
import { WorkspaceUserModel } from '../../models';
import { EntityCrudService } from '../api/entity-crud.service';

@Injectable()
export abstract class EntityNavigationService<T> extends BaseComponent {
  abstract path: string[];
  public detailsRoute: string = 'details';
  public modalComponent: unknown;

  constructor(protected router: Router, protected dialogService: DialogService<T>) {
    super();
  }

  goToGrid(route?: ActivatedRoute): void {
    const extras = !!route ? { relativeTo: route } : {};
    // console.log('* Path: ', this.path);

    this.router.navigate([...this.path], extras).then();
  }

  goToDetails(id: string, openNewTab?: boolean, route?: ActivatedRoute): void {
    const extras = !!route ? { relativeTo: route } : {};
    const path = [...this.path, id, this.detailsRoute];

    this.router.navigate(path, extras).then();
  }

  goToRelated(id: string, openNewTab?: boolean, route?: ActivatedRoute): void {
    const extras = !!route ? { relativeTo: route } : {};
    this.router.navigate([...this.path, id, 'related'], extras).then();
  }

  goToCreate(): void {
    this.router.navigate([...this.path, 'create']).then();
  }

  goToUpdate(id: string): void {
    this.router.navigate([...this.path, id, 'update']).then();
  }

  goTo(direction: string[]): void {
    console.log([...this.path, ...direction]);
    this.router.navigate([...this.path, ...direction]).then();
  }

  goToDelete(service: any, key: string = 'delete', reload?: any): void {
    const options: ConfirmationPopup = {
      hideTitle: true,
      message: `Do you want to delete it?`,
      confirmActionLabel: 'Yes, delete it!',
      icon: 'warning',
    };

    const fn = !!reload ? () => reload() : () => this.goToGrid();

    this.dialogService.delete(key, service, options, undefined, fn);
  }

  openCreateModal(options?: object): void {
    this.checkIfModalHasBeenDeclared();
    this.dialogService.open(this.modalComponent, { ...options });
  }

  openUpdateModal(entity: T, options?: object): void {
    this.checkIfModalHasBeenDeclared();
    this.dialogService.open(this.modalComponent, { ...options, ...entity });
  }

  changeOwner(
    entity: T,
    service: EntityCrudService<T>,
    key: string,
    wsUserService: EntityCrudService<WorkspaceUserModel>,
  ): void {
    this.dialogService.changeOwner(entity, service, key, wsUserService);
  }

  private checkIfModalHasBeenDeclared() {
    if (!this.modalComponent) {
      console.log('FE Code error: Missing modal declaration');
      console.warn('FE Code error: Missing modal declaration');
    }
  }
}
