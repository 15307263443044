import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';

export class BankTransferModel {
  tradingAccountId: string;
  toBankId: string;
  amount: number;
  fromBankId: string;
  parentTransactionId?: string;

  constructor(bankTransfer: Partial<BankTransferModel>) {
    Object.assign(this, bankTransfer);
  }
}
