import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';

interface Options {
  name: string;
  id: string;
  active: boolean;
  description?: string;
}

@Component({
  selector: 'lib-formly-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent extends FieldType implements OnInit {
  radioOptions: Options[];

  radioMode: number;
  description: string;

  ngOnInit(): void {
    this.getRadioOptions();
    this.radioMode = !!this.to.mode ? +this.to.mode : 1;
    this.description = !!this.to.description ? this.description : undefined;
  }

  get getValue(): string | boolean {
    const value = this.formControl.value;

    if (this.radioOptions && this.radioOptions.length) {
      this.radioOptions = this.radioOptions.map((option: Options) => ({
        ...option,
        active: option.id === value,
      }));
    }

    return value;
  }

  getRadioOptions(): void {
    const value =
      typeof this.formControl.value === 'boolean'
        ? this.formControl.value
        : this.formControl.value || this.field.defaultValue;

    (async () => {
      const options = isObservable(this.to.options)
        ? await (this.to.options as any).toPromise().then((res) => res)
        : this.to.options;
      this.radioOptions = (options as []).map((option: any) => ({
        active: value === option.id,
        id: option.id,
        name: option.name,
      }));
    })();
  }

  selectOption(id: string): void {
    this.radioOptions = this.radioOptions.map((option) => ({
      ...option,
      active: option.id === id,
    }));

    this.formControl.setValue(id);
  }
}
