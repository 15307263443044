export class PlatformUserModel {
  id: string;
  federatedId: string;
  email: string;
  isDeleted: boolean;
  rowVersion: number;
  createdDate: Date;
  lastModifiedDate: Date;

  constructor(platformUser: PlatformUserModel) {
    Object.assign(this, platformUser)
  }
}
