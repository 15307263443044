import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CurrencyType } from '../../../components/currency-picker/shared/currency-type.enum';

@Component({
  selector: 'lib-formly-currency-picker',
  template: `
    <lib-currency-picker
      [placeholder]="placeholder"
      [formControl]="formControl"
      [type]="type"
      [formlyAttributes]="field"
      [multiple]="multiple">
    </lib-currency-picker>
  `
})
export class CurrencyPickerComponent extends FieldType {

  get placeholder() {
    return this.to.placeholder;
  }

  get multiple() {
    return this.to.multiple || false;
  }

  get type() {
    return this.to.type || CurrencyType.FIAT;
  }
}
