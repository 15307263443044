<ng-container *ngIf="tabs.length">
  <nav class="space-y-1" aria-label="Sidebar">
    <ng-container *ngFor="let tab of tabs">
      <a
        *ngIf="tab.visible && tab.routerLink"
        (click)="tabSelected(tab)"
        [routerLink]="tab.routerLink"
        [class.active]="isRouteActive(tab.routerLink)"
        [ngClass]="
          isRouteActive(tab.routerLink)
            ? 'bg-primary-light border-primary-active text-primary-active'
            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-600'
        "
        class="group flex items-center px-3 py-2 text-sm font-medium border-l-4 cursor-pointer"
      >
        <i *ngIf="tab.icon" class="pr-3" [innerHtml]="tab.icon | safe"></i>
        {{ tab.label }}
      </a>

      <div class="ml-1" *ngIf="tab.visible && tab.child && tab.child.length">
        <ng-container *ngFor="let childTab of tab.child">
          <a
            *ngIf="childTab.visible && childTab.routerLink"
            (click)="tabSelected(childTab)"
            [routerLink]="childTab.routerLink"
            [class.active]="isRouteActive(childTab.routerLink)"
            [ngClass]="
              isRouteActive(childTab.routerLink)
                ? 'bg-primary-light border-primary-active text-primary-active'
                : 'border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-600'
            "
            class="group flex items-center px-3 py-2 text-sm font-medium border-l-4 cursor-pointer"
          >
            <i class="pr-3">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
            </i>
            {{ childTab.label }}
          </a>
        </ng-container>
      </div>
    </ng-container>
  </nav>
</ng-container>
