import { PickList } from '@lib/models';
import { Action, createReducer, on } from '@ngrx/store';
import { plainToClass } from 'class-transformer';
import { PickListActions } from '../actions';
import { IPickListState } from '../models';

export const initialState: IPickListState = {
  platformServer: null,
  bank: null,
  site: null,
};

const PickListReducerFn = createReducer<IPickListState>(
  initialState,

  on(PickListActions.setForSite, (state, { pickList }) => ({
    ...state,
    site: pick(pickList, 'site'),
  })),
  on(PickListActions.setForPlatformServer, (state, { pickList }) => ({
    ...state,
    platformServer: pick(pickList, 'platformServer'),
  })),
  on(PickListActions.setForBank, (state, { pickList }) => ({
    ...state,
    bank: pick(pickList, 'bank'),
  })),
);

const pick = (pickList: PickList, entity: string): PickList => {
  const keys = Object.keys(pickList);

  const newPickList = keys.reduce((object, key: string) => {
    return { ...object, [key]: { ...pickList[key], entity } };
  }, {});

  return plainToClass(PickList, newPickList);
};

export function PickListReducer(state: IPickListState | undefined, action: Action) {
  return PickListReducerFn(state, action);
}
