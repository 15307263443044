import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { MetaGuard } from '@ngx-meta/core';
import { AuthGuard } from '../auth/auth.guard';
import { AuthModule } from '../auth/auth.module';
import { RouterConstant } from '../core/route-constants';
import { authKey } from '../state-management/constants';
import { AuthReducer } from '../state-management/reducers';
import { WorkspaceUserService } from '../workspace-users/shared/workspace-user.service';
import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [MetaGuard],
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/' + RouterConstant.home.routePath, pathMatch: 'full' },
      {
        path: RouterConstant.home.routePath,
        loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: RouterConstant.orgUser.routePath,
        loadChildren: () => import('../org-users/org-users.module').then((m) => m.UsersModule),
      },
      {
        path: RouterConstant.email.routePath,
        loadChildren: () => import('../emails/emails.module').then((m) => m.EmailsModule),
      },
      {
        path: 'trading-manager',
        loadChildren: () =>
          import('../trading-manager/trading-manager.module').then((m) => m.TradingManagerModule),
      },
      {
        path: RouterConstant.workspaceUser.routePath,
        loadChildren: () =>
          import('../workspace-users/workspace-users.module').then((m) => m.WorkspaceUsersModule),
      },
      {
        path: RouterConstant.bank.routePath,
        loadChildren: () => import('../carrier-banks/carrier-banks.module').then((m) => m.CarrierBanksModule),
      },
      {
        path: RouterConstant.site.routePath,
        loadChildren: () => import('../sites/sites.module').then((m) => m.SitesModule),
      },
      {
        path: RouterConstant.permissionSet.routePath,
        loadChildren: () =>
          import('../permission-set/permission-set.module').then((m) => m.PermissionSetModule),
      },
      {
        path: RouterConstant.hierarchy.routePath,
        loadChildren: () => import('../hierarchy/hierarchy.module').then((m) => m.HierarchyModule),
      },
      {
        path: RouterConstant.settings.routePath,
        loadChildren: () => import('../settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: RouterConstant.systemStatus.routePath,
        loadChildren: () => import('../system-status/system-status.module').then((m) => m.SystemStatusModule),
      },
    ],
  },
];

@NgModule({
  imports: [AuthModule, StoreModule.forFeature(authKey, AuthReducer), RouterModule.forChild(routes)],
  providers: [WorkspaceUserService],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
