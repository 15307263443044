import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BreadcrumbsModule } from '@lib/breadcrumbs/breadcrumbs.module';
import { LayoutModule as BaseLayoutModule } from '@lib/layout/layout.module';
import { LayoutComponent } from './layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { AvatarModule } from 'ngx-avatar';
import { MaterialModule } from '@lib/material.module';
import { PipesModule } from '@lib/pipes/pipes.module';
import { StoreModule } from '@ngrx/store';
import { pickListKey } from '../state-management/constants';
import { PickListReducer } from '../state-management/reducers';
import { CreateWorkspaceFormComponent } from './header-toolbar/create-workspace-form/create-workspace-form.component';
import { FormModule } from '@lib/forms/form.module';

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    MaterialModule,
    BaseLayoutModule,
    BreadcrumbsModule,
    NgScrollbarModule,
    LayoutRoutingModule,
    PipesModule,
    FormModule,
    StoreModule.forFeature(pickListKey, PickListReducer),
  ],
  declarations: [LayoutComponent, HeaderToolbarComponent, CreateWorkspaceFormComponent],
})
export class LayoutModule {}
