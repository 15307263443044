export enum WorkspaceUserExpandable {
  ORGANIZATION = 'organization',
  WORKSPACE = 'workspace',
  ORG_USER = 'orgUser',
  ORG_USER_CREATED_BY = 'orgUser.createdByUser',
  ORG_USER_UPDATED_BY = 'orgUser.lastModifiedByUser',
  ORG_USER_PLATFORM_USER = 'orgUser.platformUser',
  PROFILE = 'profile',
  PROFILE_USER_LICENSE = 'profile.userLicense',
  MANAGER = 'managerUser',
  USER_ROLE = 'userRole',
}
