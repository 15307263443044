import { OrganizationModel, OrgUserModel, WorkspaceModel } from '../index';
import { Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';

export class AccountModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  id: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  orgId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  workspaceId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isDeleted: false;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  rowVersion: number;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  ownerId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  active: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isPersonAccount: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  parentId: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  phone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fax: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  website: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  site: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  industry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  type: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  source: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountRevenue: number | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  rating: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  ownership: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  yearStarted: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfEmployees: number | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  customerPriority: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfLocations: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  upsellOpportunity: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  billingStreet: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  billingCity: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  billingCountry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  billingPostalCode: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  billingState: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  shippingStreet: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  shippingCity: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  shippingCountry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  shippingPostalCode: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  shippingState: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: string | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastActivityDate: Date | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastViewedDate: Date | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrganizationModel)
  organization: OrganizationModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => WorkspaceModel)
  workspace: WorkspaceModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  createdByUser: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  lastModifiedByUser: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountModel)
  parentAccount: AccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  owner?: OrgUserModel;

  constructor(account: Partial<AccountModel>) {
    Object.assign(this, account);
  }
}
