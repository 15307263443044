import {NgModule} from '@angular/core';
import {MetaModule} from '@ngx-meta/core';
import {CommonModule} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {JWTInterceptor} from '../auth/interceptors/jwt.interceptor';
import {AppInitializerModule} from './app-initializer/app-initializer.module';
import { ApiInterceptor } from '@lib/services/api/api.interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    // ConfigurationModule,
    BrowserAnimationsModule,
    AppInitializerModule,
    MetaModule.forRoot()
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
}
