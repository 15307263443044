import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from '../filters/filter-model.class';
import { Entity } from '../types';
import { BaseComponent } from './base-component.directive';

@Directive()
export abstract class BaseRelated<T> extends BaseComponent implements OnInit {
  abstract entityKey: string;
  public entity$: Observable<T>;
  public relatedFilter: FilterModel;

  ngOnInit(): void {
    this.entity$ && this._subscribeToEntity();
  }

  private _subscribeToEntity(): void {
    this.subscription = this.entity$.subscribe({
      next: (entity: T) => {
        !!entity && this.generateFilter(entity);
      },
    });
  }

  public generateFilter(entity: Entity<T>): void {
    this.relatedFilter = {
      [this.entityKey]: {
        filterType: 'id',
        type: 'equals',
        filter: entity.id,
      },
    };
  }
}
