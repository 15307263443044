import { Action, createReducer, on } from '@ngrx/store';
import { IWorkspaceState } from '../models';
import { WorkspaceActions } from '../actions';

export const initialState: IWorkspaceState = {
  workspaceUser: null,
  workspaceUserPickList: null,
  inviteExistingUser: null,
  permissionSet: null,
  isRoleMoveMode: null,
  roleMove: null,
  roleMoveParent: null,
  reqRoleUpdate: null,
  workspace: null,
  site: null,
  workspaceUserAssignedPermissionSets: [],
};

const WorkspaceReducerFn = createReducer<IWorkspaceState>(
  initialState,
  on(WorkspaceActions.setWorkspace, (state, { workspace }) => ({
    ...state,
    workspace,
  })),
  on(WorkspaceActions.setWorkspaceUserPickList, (state, { pickList }) => ({
    ...state,
    workspaceUserPickList: pickList,
  })),
  on(WorkspaceActions.setSite, (state, { site }) => ({
    ...state,
    site,
  })),
  on(WorkspaceActions.setWorkspaceUser, (state, { workspaceUser }) => ({
    ...state,
    workspaceUser,
  })),
  on(WorkspaceActions.setExistingInvitationWsUser, (state, { existingUser }) => ({
    ...state,
    inviteExistingUser: existingUser,
  })),
  on(WorkspaceActions.setWorkspaceUserAssignedPermissionSets, (state, { permissionSets }) => ({
    ...state,
    workspaceUserAssignedPermissionSets: permissionSets,
  })),
  on(WorkspaceActions.setPermissionSet, (state, { permissionSet }) => ({
    ...state,
    permissionSet,
  })),
  on(WorkspaceActions.updatePermissionSetRowVersion, (state, { permissionSet }) => ({
    ...state,
    permissionSet: {
      ...state.permissionSet,
      rowVersion: permissionSet.rowVersion,
    },
  })),
  on(WorkspaceActions.moveRole, (state, { role }) => ({
    ...state,
    roleMove: role,
    isRoleMoveMode: !!role,
  })),
  on(WorkspaceActions.moveRoleParent, (state, { role }) => ({
    ...state,
    roleMoveParent: role,
  })),
  on(WorkspaceActions.reqRoleUpdate, (state, { flag }) => ({
    ...state,
    reqRoleUpdate: flag,
  })),
  on(WorkspaceActions.cancelModeMode, (state, {}) => ({
    ...state,
    roleMoveParent: null,
    roleMove: null,
    isRoleMoveMode: false,
  })),
);

export function WorkspaceReducer(state: IWorkspaceState | undefined, action: Action) {
  return WorkspaceReducerFn(state, action);
}
