import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';

@Exclude()
export class CurrentPositionModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  position: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  externalID: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  login: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  symbol: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  action: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  digits: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  digitsCurrency: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  reason: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  contractSize: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceOpen: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceCurrent: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceSL: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  priceTP: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  volume: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  profit: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  storage: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  rateProfit: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  rateMargin: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  expertID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  expertPositionID: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  comment: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationMode: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationTime: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationPrice: number;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  activationFlags: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  modificationFlags: string;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  timeCreateMsc: Date;
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  timeUpdateMs: Date;

  constructor(currentPositions: CurrentPositionModel) {
    Object.assign(this, currentPositions);
  }
}
