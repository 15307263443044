export const CURRENCIES = [
    {
        shortCode: 'AED',
        name: 'UAE Dirham',
        symbol: '',
    },
    {
        shortCode: 'AMD',
        name: 'Armenian Dram',
        symbol: '',
    },
    {
        shortCode: 'AOA',
        name: 'Kwanza',
        symbol: '',
    },
    {
        shortCode: 'BDT',
        name: 'Taka',
        symbol: '',
    },
    {
        shortCode: 'BHD',
        name: 'Bahraini Dinar',
        symbol: '',
    },
    {
        shortCode: 'BIF',
        name: 'Burundi Franc',
        symbol: '',
    },
    {
        shortCode: 'BYR',
        name: 'Belarussian Ruble',
        symbol: 'p.',
    },
    {
        shortCode: 'CDF',
        name: 'Congolese Franc',
        symbol: '',
    },
    {
        shortCode: 'CVE',
        name: 'Cape Verde Escudo',
        symbol: '',
    },
    {
        shortCode: 'DJF',
        name: 'Djibouti Franc',
        symbol: '',
    },
    {
        shortCode: 'DZD',
        name: 'Algerian Dinar',
        symbol: '',
    },
    {
        shortCode: 'EEK',
        name: 'Kroon',
        symbol: '',
    },
    {
        shortCode: 'ERN',
        name: 'Nakfa',
        symbol: '',
    },
    {
        shortCode: 'ETB',
        name: 'Ethiopian Birr',
        symbol: '',
    },
    {
        shortCode: 'GEL',
        name: 'Lari',
        symbol: '',
    },
    {
        shortCode: 'GMD',
        name: 'Dalasi',
        symbol: '',
    },
    {
        shortCode: 'GNF',
        name: 'Guinea Franc',
        symbol: '',
    },
    {
        shortCode: 'HTG USD',
        name: 'Gourde US Dollar',
        symbol: '',
    },
    {
        shortCode: 'INR BTN',
        name: 'Indian Rupee Ngultrum',
        symbol: '',
    },
    {
        shortCode: 'IQD',
        name: 'Iraqi Dinar',
        symbol: '',
    },
    {
        shortCode: 'JOD',
        name: 'Jordanian Dinar',
        symbol: '',
    },
    {
        shortCode: 'KES',
        name: 'Kenyan Shilling',
        symbol: '',
    },
    {
        shortCode: 'KMF',
        name: 'Comoro Franc',
        symbol: '',
    },
    {
        shortCode: 'KWD',
        name: 'Kuwaiti Dinar',
        symbol: '',
    },
    {
        shortCode: 'LTL',
        name: 'Lithuanian Litas',
        symbol: 'Lt',
    },
    {
        shortCode: 'LVL',
        name: 'Latvian Lats',
        symbol: 'Ls',
    },
    {
        shortCode: 'LYD',
        name: 'Libyan Dinar',
        symbol: '',
    },
    {
        shortCode: 'MAD',
        name: 'Moroccan Dirham',
        symbol: '',
    },
    {
        shortCode: 'MDL',
        name: 'Moldovan Leu',
        symbol: '',
    },
    {
        shortCode: 'MGA',
        name: 'Malagasy Ariary',
        symbol: '',
    },
    {
        shortCode: 'MMK',
        name: 'Kyat',
        symbol: '',
    },
    {
        shortCode: 'MOP',
        name: 'Pataca',
        symbol: '',
    },
    {
        shortCode: 'MRO',
        name: 'Ouguiya',
        symbol: '',
    },
    {
        shortCode: 'MVR',
        name: 'Rufiyaa',
        symbol: '',
    },
    {
        shortCode: 'MWK',
        name: 'Kwacha',
        symbol: '',
    },
    {
        shortCode: 'ALL',
        name: 'Lek',
        symbol: 'Lek',
    },
    {
        shortCode: 'PGK',
        name: 'Kina',
        symbol: '',
    },
    {
        shortCode: 'RWF',
        name: 'Rwanda Franc',
        symbol: '',
    },
    {
        shortCode: 'SDG',
        name: 'Sudanese Pound',
        symbol: '',
    },
    {
        shortCode: 'SLL',
        name: 'Leone',
        symbol: '',
    },
    {
        shortCode: 'STD',
        name: 'Dobra',
        symbol: '',
    },
    {
        shortCode: 'SZL',
        name: 'Lilangeni',
        symbol: '',
    },
    {
        shortCode: 'TJS',
        name: 'Somoni',
        symbol: '',
    },
    {
        shortCode: 'TMT',
        name: 'Manat',
        symbol: '',
    },
    {
        shortCode: 'TND',
        name: 'Tunisian Dinar',
        symbol: '',
    },
    {
        shortCode: 'TOP',
        name: 'Paanga',
        symbol: '',
    },
    {
        shortCode: 'TRY',
        name: 'Turkish Lira',
        symbol: 'TL',
    },
    {
        shortCode: 'TZS',
        name: 'Tanzanian Shilling',
        symbol: '',
    },
    {
        shortCode: 'UGX',
        name: 'Uganda Shilling',
        symbol: '',
    },
    {
        shortCode: 'VUV',
        name: 'Vatu',
        symbol: '',
    },
    {
        shortCode: 'WST',
        name: 'Tala',
        symbol: '',
    },
    {
        shortCode: 'XAF',
        name: 'CFA Franc BEAC',
        symbol: '',
    },
    {
        shortCode: 'XAG',
        name: 'Silver',
        symbol: '',
    },
    {
        shortCode: 'XAU',
        name: 'Gold',
        symbol: '',
    },
    {
        shortCode: 'XBA',
        name: 'Bond Markets Units European Composite Unit (EURCO)',
        symbol: '',
    },
    {
        shortCode: 'XBB',
        name: 'European Monetary Unit (E.M.U.-6)',
        symbol: '',
    },
    {
        shortCode: 'XBC',
        name: 'European Unit of Account 9(E.U.A.-9)',
        symbol: '',
    },
    {
        shortCode: 'XBD',
        name: 'European Unit of Account 17(E.U.A.-17)',
        symbol: '',
    },
    {
        shortCode: 'XDR',
        name: 'SDR',
        symbol: '',
    },
    {
        shortCode: 'XFU',
        name: 'UIC-Franc',
        symbol: '',
    },
    {
        shortCode: 'XOF',
        name: 'CFA Franc BCEAO',
        symbol: '',
    },
    {
        shortCode: 'XPD',
        name: 'Palladium',
        symbol: '',
    },
    {
        shortCode: 'XPF',
        name: 'CFP Franc',
        symbol: '',
    },
    {
        shortCode: 'XPT',
        name: 'Platinum',
        symbol: '',
    },
    {
        shortCode: 'XTS',
        name: 'Codes specifically reserved for testing purposes',
        symbol: '',
    },
    {
        shortCode: 'ZMK',
        name: 'Zambian Kwacha',
        symbol: '',
    },
    {
        shortCode: 'BTC',
        name: 'Bitcoin',
        symbol: '',
    },
    {
        shortCode: 'BCH',
        name: 'Bitcoin Cash',
        symbol: '',
    },
    {
        shortCode: 'DSH',
        name: 'Dashcoin',
        symbol: '',
    },
    {
        shortCode: 'ETH',
        name: 'Ethereum',
        symbol: '',
    },
    {
        shortCode: 'LTC',
        name: 'Litecoin',
        symbol: '',
    },
    {
        shortCode: 'XBT',
        name: 'Bitcoin (ISO standard)',
        symbol: '',
    },
    {
        shortCode: 'USDT',
        name: 'Tether',
        symbol: '',
    },
    {
        shortCode: 'CUC',
        name: 'Cuban Peso Peso Convertible',
        symbol: '$',
    },
    {
        shortCode: 'BOV',
        name: 'Bolivian Mvdol',
        symbol: '$b',
    },
    {
        shortCode: 'LSL',
        name: 'Lesotho loti',
        symbol: '',
    },
    {
        shortCode: 'AFN',
        name: 'Afghani',
        symbol: '؋',
    },
    {
        shortCode: 'ARS',
        name: 'Argentine Peso',
        symbol: '$',
    },
    {
        shortCode: 'AWG',
        name: 'Aruban Guilder',
        symbol: 'ƒ',
    },
    {
        shortCode: 'AUD',
        name: 'Australian Dollar',
        symbol: '$',
    },
    {
        shortCode: 'AZN',
        name: 'Azerbaijanian Manat',
        symbol: '₼',
    },
    {
        shortCode: 'BSD',
        name: 'Bahamian Dollar',
        symbol: '$',
    },
    {
        shortCode: 'BBD',
        name: 'Barbados Dollar',
        symbol: '$',
    },
    {
        shortCode: 'BZD',
        name: 'Belize Dollar',
        symbol: 'BZ$',
    },
    {
        shortCode: 'BMD',
        name: 'Bermudian Dollar (customarily known as Bermuda Dollar)',
        symbol: '$',
    },
    {
        shortCode: 'BOB',
        name: 'Bolivian boliviano',
        symbol: '$b',
    },
    {
        shortCode: 'BAM',
        name: 'Convertible Marks',
        symbol: 'KM',
    },
    {
        shortCode: 'BWP',
        name: 'Pula',
        symbol: 'P',
    },
    {
        shortCode: 'BGN',
        name: 'Bulgarian Lev',
        symbol: 'лв',
    },
    {
        shortCode: 'BRL',
        name: 'Brazilian Real',
        symbol: 'R$',
    },
    {
        shortCode: 'BND',
        name: 'Brunei Dollar',
        symbol: '$',
    },
    {
        shortCode: 'KHR',
        name: 'Riel',
        symbol: '៛',
    },
    {
        shortCode: 'CAD',
        name: 'Canadian Dollar',
        symbol: '$',
    },
    {
        shortCode: 'KYD',
        name: 'Cayman Islands Dollar',
        symbol: '$',
    },
    {
        shortCode: 'PYG',
        name: 'Guarani',
        symbol: 'Gs',
    },
    {
        shortCode: 'CLP',
        name: 'Chile Peso',
        symbol: '$',
    },
    {
        shortCode: 'CNY',
        name: 'Yuan Renminbi',
        symbol: '¥',
    },
    {
        shortCode: 'COP',
        name: 'Colombia Peso',
        symbol: '$',
    },
    {
        shortCode: 'CRC',
        name: 'Costa Rican Colon',
        symbol: '₡',
    },
    {
        shortCode: 'HRK',
        name: 'Croatian Kuna',
        symbol: 'kn',
    },
    {
        shortCode: 'CUP',
        name: 'Cuba Peso',
        symbol: '₱',
    },
    {
        shortCode: 'CZK',
        name: 'Czech Koruna',
        symbol: 'Kč',
    },
    {
        shortCode: 'DKK',
        name: 'Danish Krone',
        symbol: 'kr',
    },
    {
        shortCode: 'DOP',
        name: 'Dominican Peso',
        symbol: 'RD$',
    },
    {
        shortCode: 'XCD',
        name: 'East Caribbean Dollar',
        symbol: '$',
    },
    {
        shortCode: 'EGP',
        name: 'Egyptian Pound',
        symbol: '£',
    },
    {
        shortCode: 'SVC',
        name: 'El Salvador Colon US Dollar',
        symbol: '$',
    },
    {
        shortCode: 'EUR',
        name: 'Euro',
        symbol: '€',
    },
    {
        shortCode: 'FKP',
        name: 'Falkland Islands Pound',
        symbol: '£',
    },
    {
        shortCode: 'FJD',
        name: 'Fiji Dollar',
        symbol: '$',
    },
    {
        shortCode: 'GHS',
        name: 'Cedi',
        symbol: '¢',
    },
    {
        shortCode: 'GIP',
        name: 'Gibraltar Pound',
        symbol: '£',
    },
    {
        shortCode: 'GTQ',
        name: 'Quetzal',
        symbol: 'Q',
    },
    {
        shortCode: 'GYD',
        name: 'Guyana Dollar',
        symbol: '$',
    },
    {
        shortCode: 'HNL',
        name: 'Lempira',
        symbol: 'L',
    },
    {
        shortCode: 'HKD',
        name: 'Hong Kong Dollar',
        symbol: '$',
    },
    {
        shortCode: 'HUF',
        name: 'Forint',
        symbol: 'Ft',
    },
    {
        shortCode: 'ISK',
        name: 'Iceland Krona',
        symbol: 'kr',
    },
    {
        shortCode: 'INR',
        name: 'Indian Rupee',
        symbol: '',
    },
    {
        shortCode: 'IDR',
        name: 'Rupiah',
        symbol: 'Rp',
    },
    {
        shortCode: 'IRR',
        name: 'Iranian Rial',
        symbol: '﷼',
    },
    {
        shortCode: 'ILS',
        name: 'New Israeli Sheqel',
        symbol: '₪',
    },
    {
        shortCode: 'JMD',
        name: 'Jamaican Dollar',
        symbol: 'J$',
    },
    {
        shortCode: 'JPY',
        name: 'Yen',
        symbol: '¥',
    },
    {
        shortCode: 'KZT',
        name: 'Tenge',
        symbol: 'лв',
    },
    {
        shortCode: 'KPW',
        name: 'North Korean Won',
        symbol: '₩',
    },
    {
        shortCode: 'KRW',
        name: 'Won',
        symbol: '₩',
    },
    {
        shortCode: 'KGS',
        name: 'Som',
        symbol: 'лв',
    },
    {
        shortCode: 'LAK',
        name: 'Kip',
        symbol: '₭',
    },
    {
        shortCode: 'LBP',
        name: 'Lebanese Pound',
        symbol: '£',
    },
    {
        shortCode: 'LRD',
        name: 'Liberian Dollar',
        symbol: '$',
    },
    {
        shortCode: 'MKD',
        name: 'Denar',
        symbol: 'ден',
    },
    {
        shortCode: 'MYR',
        name: 'Malaysian Ringgit',
        symbol: 'RM',
    },
    {
        shortCode: 'MUR',
        name: 'Mauritius Rupee',
        symbol: '₨',
    },
    {
        shortCode: 'MXN',
        name: 'Mexican Peso',
        symbol: '$',
    },
    {
        shortCode: 'MNT',
        name: 'Tugrik',
        symbol: '₮',
    },
    {
        shortCode: 'MZN',
        name: 'Metical',
        symbol: 'MT',
    },
    {
        shortCode: 'NAD',
        name: 'Namibia Dollar',
        symbol: '$',
    },
    {
        shortCode: 'NPR',
        name: 'Nepalese Rupee',
        symbol: '₨',
    },
    {
        shortCode: 'ANG',
        name: 'Netherlands Antillian Guilder',
        symbol: 'ƒ',
    },
    {
        shortCode: 'NZD',
        name: 'New Zealand Dollar',
        symbol: '$',
    },
    {
        shortCode: 'NIO',
        name: 'Cordoba Oro',
        symbol: 'C$',
    },
    {
        shortCode: 'NGN',
        name: 'Naira',
        symbol: '₦',
    },
    {
        shortCode: 'NOK',
        name: 'Norwegian Krone',
        symbol: 'kr',
    },
    {
        shortCode: 'OMR',
        name: 'Rial Omani',
        symbol: '﷼',
    },
    {
        shortCode: 'PKR',
        name: 'Pakistan Rupee',
        symbol: '₨',
    },
    {
        shortCode: 'PAB',
        name: 'Balboa US Dollar',
        symbol: 'B/.',
    },
    {
        shortCode: 'PEN',
        name: 'Nuevo Sol',
        symbol: 'S/.',
    },
    {
        shortCode: 'PHP',
        name: 'Philippine Peso',
        symbol: '₱',
    },
    {
        shortCode: 'PLN',
        name: 'Zloty',
        symbol: 'zł',
    },
    {
        shortCode: 'QAR',
        name: 'Qatari Rial',
        symbol: '﷼',
    },
    {
        shortCode: 'RON',
        name: 'New Leu',
        symbol: 'lei',
    },
    {
        shortCode: 'RUB',
        name: 'Russian Ruble',
        symbol: '₽',
    },
    {
        shortCode: 'SHP',
        name: 'Saint Helena Pound',
        symbol: '£',
    },
    {
        shortCode: 'SAR',
        name: 'Saudi Riyal',
        symbol: '﷼',
    },
    {
        shortCode: 'RSD',
        name: 'Serbian Dinar',
        symbol: 'Дин.',
    },
    {
        shortCode: 'SCR',
        name: 'Seychelles Rupee',
        symbol: '₨',
    },
    {
        shortCode: 'SGD',
        name: 'Singapore Dollar',
        symbol: '$',
    },
    {
        shortCode: 'SBD',
        name: 'Solomon Islands Dollar',
        symbol: '$',
    },
    {
        shortCode: 'SOS',
        name: 'Somali Shilling',
        symbol: 'S',
    },
    {
        shortCode: 'ZAR',
        name: 'Rand',
        symbol: 'R',
    },
    {
        shortCode: 'LKR',
        name: 'Sri Lanka Rupee',
        symbol: '₨',
    },
    {
        shortCode: 'SEK',
        name: 'Swedish Krona',
        symbol: 'kr',
    },
    {
        shortCode: 'CHF',
        name: 'Swiss Franc',
        symbol: 'CHF',
    },
    {
        shortCode: 'SRD',
        name: 'Surinam Dollar',
        symbol: '$',
    },
    {
        shortCode: 'SYP',
        name: 'Syrian Pound',
        symbol: '£',
    },
    {
        shortCode: 'TWD',
        name: 'New Taiwan Dollar',
        symbol: 'NT$',
    },
    {
        shortCode: 'THB',
        name: 'Thailand Baht',
        symbol: '฿',
    },
    {
        shortCode: 'TTD',
        name: 'Trinidad and Tobago Dollar',
        symbol: 'TT$',
    },
    {
        shortCode: 'TVD',
        name: 'Tuvalu Dollar',
        symbol: '$',
    },
    {
        shortCode: 'UAH',
        name: 'Hryvnia',
        symbol: '₴',
    },
    {
        shortCode: 'GBP',
        name: 'Pound Sterling',
        symbol: '£',
    },
    {
        shortCode: 'USD',
        name: 'US Dollar',
        symbol: '$',
    },
    {
        shortCode: 'USDC',
        name: 'USD Coin',
        symbol: '',
    },
    {
        shortCode: 'UYU',
        name: 'Uruguay Peso',
        symbol: '$U',
    },
    {
        shortCode: 'UZS',
        name: 'Uzbekistan Som',
        symbol: 'лв',
    },
    {
        shortCode: 'VEF',
        name: 'Venezuela Bolívar',
        symbol: 'Bs',
    },
    {
        shortCode: 'VND',
        name: 'Viet Nam Dong',
        symbol: '₫',
    },
    {
        shortCode: 'YER',
        name: 'Yemeni Rial',
        symbol: '﷼',
    },
    {
        shortCode: 'ZWD',
        name: 'Zimbabwe Dollar',
        symbol: 'Z$',
    },
];
