import { ICellRendererParams } from 'ag-grid-community';

export const userRelation = (params: ICellRendererParams): HTMLElement => {
  return params.data ? generateTemplate(params) : undefined;
};

const generateTemplate = (params: ICellRendererParams): HTMLElement => {
  const {data} = params;
  const {firstName, lastName, email} = data;


  const eGui = document.createElement('div');
  eGui.innerHTML = `
   <div class="flex flex-col" style="line-height: 17px">
    <a class="text-gray-800 cursor-pointer">${firstName} ${lastName}</a>
    <div style="font-size: 12px">${email}</div>
  </div>
  `;

  return eGui;
};

