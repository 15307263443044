import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MetaGuard } from '@ngx-meta/core';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { SelectOrganizationComponent } from './select-organization/select-organization.component';

const childRoutes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {
    path: 'login',
    component: LoginComponent,
    data: {
      meta: {
        title: 'Login',
        description: 'Login page'
      }
    }
  },
  {
    path: 'organization',
    component: SelectOrganizationComponent,
    data: {
      meta: {
        title: 'Organization',
        description: 'Select organization'
      }
    }
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      meta: {
        title: 'Forgot Password',
        description: 'Forgot Password Page'
      }
    }
  },
  {
    path: 'reset-password/:email',
    component: ResetPasswordComponent,
    data: {
      meta: {
        title: 'Reset Password',
        description: 'Reset Password Page'
      }
    }
  },
  // {
  //   path: 'register', component: RegisterComponent,
  //   data: {
  //     meta: {
  //       title: 'Register Account',
  //       description: 'Register Account Page'
  //     }
  //   }
  // },
  {
    path: 'confirm-registration/:email', component: ConfirmRegistrationComponent,
    data: {
      meta: {
        title: 'Confirm Registration',
        description: 'Confirm Registration Code'
      }
    }
  },
];

const routes: Routes = [
  {
    path: 'auth', component: AuthComponent,
    canActivateChild: [MetaGuard],
    children: childRoutes
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRouting {
}
