import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';
import { SystemUserLicenseModel } from '../system-user-license/system-user-license-model.class';
@Exclude()
export class UserLicenseModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  isActive: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  totalLicenses: number;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  usedLicenses: number;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  systemUserLicenseId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => SystemUserLicenseModel)
  systemUserLicense?: SystemUserLicenseModel;

  constructor(data: Partial<UserLicenseModel>) {
    super(data);
    Object.assign(this, data);
  }
}
