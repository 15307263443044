import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material.module';

import { SideMenuComponent } from './side-menu/side-menu.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgScrollbarModule
  ],
  declarations: [
    SideMenuComponent
  ],
  exports: [
    SideMenuComponent
  ]
})
export class LayoutModule {
}
