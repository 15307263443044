import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';

@Exclude()
export class HierarchyModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  label: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountOwnerContactAccess: 'Edit' | 'Read' | 'None';

  @Expose({ groups: Object.values(ClassExposeGroups) })
  parentRoleId: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  @Type(() => HierarchyModel)
  children?: HierarchyModel[];

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  hide?: boolean;

  constructor(hierarchy: Partial<HierarchyModel>) {
    super(hierarchy);
    Object.assign(this, hierarchy);
  }
}
