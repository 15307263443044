import { Type } from 'class-transformer';
import { AccountModel } from '../account-model/account-model';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { ContactModel } from '../contact/contact.model';
import { OrgUserModel } from '../user/org-user.model';
import { DocumentModel } from './document-model';

export class DocumentContentModel extends BaseOrgWorkspaceAuditModel {
  documentId: string;
  userId: string;
  accountId: string;
  contactId: string;

  @Type(() => AccountModel)
  account?: AccountModel;

  @Type(() => ContactModel)
  contact?: ContactModel;

  @Type(() => OrgUserModel)
  user?: OrgUserModel;

  @Type(() => DocumentModel)
  document?: DocumentModel;

  constructor(document: Partial<DocumentContentModel>) {
    super(document);
    Object.assign(this, document);
  }
}
