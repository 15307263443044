/* tslint:disable:no-unused-expression */
import { ICellRenderer, RowNode } from 'ag-grid-community';
import { ObjectPermissionEnum } from '../../../enums';
import { CellRendererClickHandler } from '../shared/cell-click-params.interface';

export default class ObjectPermissionCheckbox implements ICellRenderer {
  public eGui: any;
  public eValue: any;
  public params: CellRendererClickHandler;

  public init(params: CellRendererClickHandler): void {
    this.params = params;
    const value = !!params.getValue();

    this.eGui = this.generateTemplate(params);
    this.addListeners();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: CellRendererClickHandler): any {
    this.params = params;
    this.eGui = this.generateTemplate(params);
  }

  public generateTemplate = (params: CellRendererClickHandler): HTMLElement => {
    const eGui = document.createElement('div');
    const value = params.getValue();
    const id = params.data?.id + '-' + params.column.getColId();
    const name = params.colDef.headerName ?? '';

    eGui.innerHTML = !!value
      ? `<input id="${id}" alt="${name}"  type="checkbox" class="my-checkbox"  checked>`
      : `<input id="${id}" alt="${name}"  type="checkbox" class="my-checkbox">`;
    return eGui;
  };

  public generateNone = (): HTMLElement => {
    const eGui = document.createElement('div');
    eGui.innerHTML = '-';
    return eGui;
  };

  public addListeners(): void {
    this.clickedHandler = this.clickedHandler.bind(this);
    this.eGui.addEventListener('click', this.clickedHandler);
  }

  public clickedHandler(): void {
    const _newValue: boolean = !this.params.getValue();

    this.params.touched = true;
    this.params.setValue(_newValue);
    this.params?.clicked && this.params?.clicked(this.params.data);

    this._handleScenarios(_newValue);
  }

  public destroy(): void {
    if (!!this.eGui) {
      this.eGui.removeEventListener('click', this.clickedHandler);
    }
  }

  private _handleScenarios(_newValue: boolean) {
    const _headerCell: string = this.params.colDef.field;

    if (_newValue) {
      if (_headerCell === ObjectPermissionEnum.CREATE || _headerCell === ObjectPermissionEnum.EDIT) {
        this.params.data = { ...this.params.data, [ObjectPermissionEnum.READ]: true };
        this.updateRow();
      }

      if (_headerCell === ObjectPermissionEnum.DELETE) {
        this.params.data = {
          ...this.params.data,
          [ObjectPermissionEnum.READ]: true,
          [ObjectPermissionEnum.EDIT]: true,
        };
        this.updateRow();
      }
    }

    if (!_newValue) {
      if (_headerCell === ObjectPermissionEnum.READ) {
        this.params.data = {
          ...this.params.data,
          [ObjectPermissionEnum.EDIT]: false,
          [ObjectPermissionEnum.CREATE]: false,
          [ObjectPermissionEnum.DELETE]: false,
        };
        this.updateRow();
      } else if (_headerCell === ObjectPermissionEnum.EDIT) {
        this.params.data = {
          ...this.params.data,
          [ObjectPermissionEnum.DELETE]: false,
        };
        this.updateRow();
      }
    }
  }

  private updateRow(): void {
    const _rowIndex = this.params.rowIndex;
    const nodeToBeUpdated = this.params.api.getRowNode(_rowIndex.toString());
    nodeToBeUpdated.setData({ ...this.params.data, touched: true });
  }
}
