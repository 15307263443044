import { Injectable } from '@angular/core';
import { AllowedAction } from '@lib/interfaces';
import { Store } from '@ngrx/store';
import { IAuthAppState } from '../../state-management/models';
import { fromAuth } from '../../state-management/selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthUserActionsService {
  private userActions: AllowedAction[];

  constructor(private authStore: Store<IAuthAppState>) {
    this.authStore.select(fromAuth.selectWsUserActions).subscribe((actions) => (this.userActions = actions));
  }

  hasPermission(action: string): boolean {
    return !!(this.userActions ?? []).find((a) => a.actionName === action)?.actionValue;
  }
}
