import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { Nullable } from '../../types/nullable.type';
import { BaseOrgAuditModel } from '../base-models/base-org-audit-model.class';
import { OrganizationModel } from '../organization/organization-model.class';
import { OrgUserModel } from '../user/org-user.model';
@Exclude()
export class WorkspaceModel extends BaseOrgAuditModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  name: string;
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  locale: string;
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  timezone: string;
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  language: string;
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  fax: Nullable<string>;
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  phone: Nullable<string>;
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  fiscalYearStartMonth: Nullable<Date>;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => OrgUserModel)
  createdByUser?: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => OrgUserModel)
  lastModifiedByUser?: OrgUserModel;

  constructor(workspace: Partial<WorkspaceModel>) {
    super(workspace);
    Object.assign(this, workspace);
  }
}
