import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SvgIcons } from '../../../assets/svgs.enum';
import { ITab } from '../tabs/shared/tab.interface';

@Component({
  selector: 'lib-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss'],
})
export class VerticalNavigationComponent implements OnInit {
  @Input() tabs: ITab[];
  @Output() handleTabSelection: EventEmitter<ITab> = new EventEmitter<ITab>();

  public svgIcons = SvgIcons;

  constructor(private router: Router) {}

  navigateTo(event: Event): void {
    const path: string = (event.target as HTMLInputElement).value;
    this.router.navigate([path]).then();
  }

  tabSelected(tab: ITab): void {
    this.tabs = this.tabs.map((item) => ({ ...item, selected: tab.key === item.key }));
    this.handleTabSelection.emit(tab);
  }

  isRouteActive(routePath: string): boolean {
    const link = this.router.url;

    return this.router.url === routePath || this.router.url.trim().includes(routePath.trim());
  }

  ngOnInit(): void {}
}
