import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { BaseModel } from '../base-models/base-model.class';
import { OrganizationStatusModel } from '../organization-status/organization-status-model.class';

@Exclude()
export class OrganizationModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  status: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  country: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  stateId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  street?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  city?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Expose({ name: 'postal_code' })
  postalCode?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => OrganizationStatusModel)
  statusRel?: OrganizationStatusModel;

  constructor(data: Partial<OrganizationModel>) {
    super(data);
    Object.assign(this, data);
  }
}
