<div #group class="input-group flex flex-row justify-end">
  <div class="input-group-prepend">
    <div class="custom-input-group-text input-group-text currency-code">
      <ng-select
        #phoneCodeSelect
        (change)="onPrefixChange($event)"
        (click)="switchFocus()"
        [clearable]="false"
        [items]="countriesFiltered"
        [ngModel]="model.countryCode"
        [searchFn]="searchTerm"
        [searchable]="true"
        bindValue="code"
        class="custom phone"
        matPrefix
      >
        <ng-template ng-typetosearch-tmp></ng-template>

        <ng-template ng-header-tmp>
          <mat-form-field class="w-full" floatLabel="never">
            <input #filterInput (keyup)="filterOptions($event)" class="searchBarInput" matInput type="text" />
            <button
              (click)="filterInput.value = ''"
              *ngIf="filterInput.value"
              aria-label="Clear"
              mat-button
              mat-icon-button
              matSuffix
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-template>

        <ng-template let-item="item" ng-label-tmp>
          <div class="country-select">
            <div
              *ngIf="useSvg"
              class="flagSize flag-icon flag-icon-{{ item?.code | lowercase }} country-details"
            ></div>
            <img
              *ngIf="!useSvg"
              [src]="'/assets/img/flags/' + item?.code.toLowerCase() + '.png'"
              [alt]="'flag-' + item?.code.toLowerCase()"
              class="country-selected country-details"
            />
          </div>
        </ng-template>

        <ng-template let-index="index" let-item="item" ng-option-tmp>
          <div class="flex flex-row">
            <div *ngIf="useSvg" class="flagSize flag-icon flag-icon-{{ item?.code | lowercase }}"></div>
            <img
              *ngIf="!useSvg"
              [src]="'/assets/img/flags/' + item?.code.toLowerCase() + '.png'"
              [alt]="'flag-' + item?.code.toLowerCase()"
              class="country-png country-details flagSize"
            />
            <div class="pl-3 country-details">
              <span>{{ item?.name }}</span>
              <span class="country-prefix">{{ item?.dialingCode }}</span>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <mat-form-field [style.width]="'100%'" [style.paddingLeft.px]="4">
    <input
      #phoneInput
      (blur)="onInputBlur($event)"
      (change)="onInputChange($event.target?.value)"
      (input)="onInputChange($event.target?.value)"
      [(ngModel)]="model.number"
      [autocomplete]="'nope'"
      [disabled]="formControl && formControl.disabled"
      [id]="labelForId"
      [placeholder]="placeholder"
      class="tel-value pl-2"
      matInput
      type="tel"
    />

    <mat-label *ngIf="label">
      {{ label }}
      <span *ngIf="required" class="mat-form-field-required-marker">*</span>
    </mat-label>
    <mat-hint *ngIf="description" [id]="null">{{ description }}</mat-hint>
  </mat-form-field>
</div>

<mat-error [id]="null" class="error-section" *ngIf="!hideLocalErrorDetection">
  <formly-validation-message [field]="formlyAttributes"></formly-validation-message>
</mat-error>
