import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { Observable, Subject } from 'rxjs';
import { BaseComponent } from '../../../../classes/base-component.class';
import { QuickPanelCloseStatus } from '../../../../layout/quick-panel/shared/quick-panel-close-status.enum';
import { WorkspaceUserModel } from '../../../../models';
import { EntityCrudService } from '../../../../services/api/entity-crud.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { ChangeOwnerComponent } from '../../../change-owner/change-owner.component';
import {
  ConfirmationPopup,
  ConfirmationPopupComponent,
} from '../../confirmation-popup/confirmation-popup.component';
import { DialogComponent } from '../dialog.component';
import { QuickDialog } from './quick-dialog.class';
import { ResultDialog } from './result-dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService<T> extends BaseComponent {
  constructor(private dialog: MatDialog, private toastService: ToastService) {
    super();
  }

  dialogRef: MatDialogRef<DialogComponent>;
  private dialogResult = new Subject<any>();

  public close(): void {
    this.dialogRef.close();
  }

  public open(component: any, entity?: QuickDialog, dialogConfig?: MatDialogConfig): void {
    this.dialog
      .open(component || DialogComponent, {
        disableClose: true,
        width: '700px',
        ...dialogConfig,
        ...entity,
        data: {
          ...entity,
          ...entity?.data,
        },
      })
      .afterClosed()
      .subscribe((res: ResultDialog<T>) => {
        this.dialogResult.next(res);
      });
  }

  public openVerification(
    key: string,
    service: Observable<T>,
    options?: ConfirmationPopup,
    dialogConfig?: MatDialogConfig,
  ): void {
    this.dialog
      .open(ConfirmationPopupComponent, {
        disableClose: true,
        width: '500px',
        ...dialogConfig,
        data: {
          ...options,
          service,
          key,
        },
      })
      .afterClosed()
      .subscribe((res: ResultDialog<T>) => {
        this.dialogResult.next(res);
      });
  }

  public delete(
    key: string,
    service?: any,
    options?: ConfirmationPopup,
    dialogConfig?: MatDialogConfig,
    finalAction?: any,
  ): void | Observable<ResultDialog<T>> {
    const _options: ConfirmationPopup = {
      ...options,
      key,
      service,
      icon: 'warning',
      title: 'Delete' + options?.title ? options?.title : '',
      confirmActionLabel: 'Delete',
      response: {
        success: 'Deleted successfully!',
      },
    };

    this.dialog
      .open(ConfirmationPopupComponent, {
        disableClose: true,
        width: '500px',
        ...dialogConfig,
        data: {
          ..._options,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        this.dialogResult.next(res);
        if (res.status === QuickPanelCloseStatus.SUCCESS) {
          if (!!finalAction) {
            this.toastService.showSuccess('Deleted successfully!');
            this.subscription = finalAction();
          }
        }
      });
  }

  public changeOwner(
    entity: T,
    service: EntityCrudService<T>,
    key: string,
    wsUserService: EntityCrudService<WorkspaceUserModel>,
  ): void {
    this.dialog
      .open(ChangeOwnerComponent, {
        disableClose: true,
        width: '500px',
        data: {
          entity,
          service,
          key,
          wsUserService,
        },
      })
      .afterClosed()
      .subscribe((res: ResultDialog<T>) => {
        res.status === QuickPanelCloseStatus.SUCCESS && service.sendMessage(res);
      });
  }

  public getResult(): Observable<ResultDialog<T>> {
    return this.dialogResult.asObservable();
  }
}
