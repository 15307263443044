import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-country-picker',
  template: `
    <lib-country-picker
      [placeholder]="placeholder"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [autocomplete]="autocomplete"
      [multiple]="multiple">
    </lib-country-picker>
  `
})
export class CountryPickerComponent extends FieldType {

  get placeholder(): string {
    return this.to.placeholder;
  }

  get multiple(): boolean {
    return this.to.multiple || false;
  }

  get autocomplete(): boolean {
    return this.to.autocomplete || false;
  }
}
