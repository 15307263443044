import { createAction, props } from '@ngrx/store';
import { PickList } from '@lib/models';

const setEmailPickList = createAction(
  '[Email] Pick list',
  props<{ pickList: PickList }>(),
);

export const EmailActions = {
  setEmailPickList
}
