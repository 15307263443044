import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../enums';
import { BaseOrgWorkspaceAuditModel } from '../models/base-models/base-org-workspace-audit-model.class';

@Exclude()
export class EntityModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  id: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  ownerId: string;
}

type MainEntity<T> = Pick<T, keyof T> & { id?: string } & { status?: string } & { ownerId?: string };

export type Entity<T> = MainEntity<T>;
// export type EntityMotel<EntityExposeModel> = MainEntity<EntityExposeModel>;
