export enum AuthPermissions {
    ADMIN = 'ADMIN'
}

export enum AppActions {
    // PM Actions ----------------------------------
    WORKSPACE_SETUP = 'workspace_setup',
    ORG_SETUP = 'org_setup',
    PICK_LIST = 'pick_list',

    ORG_PROVISION = 'org_provision',
    ORG_WORKSPACE_PROVISION = 'org_workspace_provision',
    ORG_USER_UPDATE_LAST_LOGIN = 'org_user_update_last_login',

    // TM Actions ----------------------------------
    TM_SETUP = 'tm_setup',
    TRADING_ACCOUNTS_MARGIN_LEVEL = 'trading_accounts_margin_level',
    TRADING_ACCOUNTS_CHANGE_STATUS = 'trading_accounts_change_status',
    TRADING_ACCOUNTS_CHANGE_PASSWORD = 'trading_accounts_change_password',
    TRADING_ACCOUNTS_CHANGE_INVESTOR_PASSWORD = 'trading_accounts_change_investor_password',
    TRADING_ACCOUNTS_CHECK_PASSWORD = 'trading_accounts_check_password',
    TRADING_ACCOUNTS_DEPOSIT = 'trading_accounts_deposit',
    TRADING_ACCOUNTS_WITHDRAWAL = 'trading_accounts_withdrawal',
    TRADING_ACCOUNTS_CREDIT_IN = 'trading_accounts_credit_in',
    TRADING_ACCOUNTS_CREDIT_OUT = 'trading_accounts_credit_out',
    TRADING_ACCOUNTS_CURRENT_POSITIONS = 'trading_accounts_current_positions',
    TRADING_ACCOUNTS_ORDERS = 'trading_accounts_orders',
    TRADING_ACCOUNTS_DEALS = 'trading_accounts_deals',
    TRADING_ACCOUNT_INTERNAL_TRANSFER = 'trading_account_internal_transfer',

    TRANSACTIONS_CASHIER_INIT = 'transactions_cashier_init',
    TRANSACTIONS_REFUND = 'transactions_refund',
    TRANSACTIONS_MANAGE_WITHDRAWAL = 'transactions_manage_withdrawal',

    COMPLIANCE_QUESTIONNAIRE_ANSWER_VIEW = 'compliance_questionnaire_answer_view',
    COMPLIANCE_QUESTIONNAIRE_ANSWER_DELETE = 'compliance_questionnaire_answer_delete',
    COMPLIANCE_QUESTIONNAIRE_FORM_URL = 'compliance_questionnaire_form_url',

    // PSP Actions ----------------------------------
    PSP_SETUP = 'psp_setup',
    PSP_TRANSACTIONS_CASHIER_INIT = 'psp_transactions_cashier_init',
    PSP_TRANSACTIONS_REFUND = 'psp_transactions_refund',
    PSP_TRANSACTIONS_MANAGE_WITHDRAWAL = 'psp_transactions_manage_withdrawal',

    // CRM Actions ----------------------------------
    ACCOUNT_CREATE_PERSONAL = 'account_create_personal',
    CAN_SHARE = 'CAN_SHARE',

    LEAD_CONVERT = 'lead_convert',
    LEAD_IMPORT = 'lead_import',

    SHUFTI_PRO_REQUEST_KYC = 'shufti_ori_request_kyc',

    SUMSUB_KYC = 'sumsub-kyc',

    TRANSFER_RECORD = 'transfer_record'
}
