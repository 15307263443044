export class TargetStatsModel {
  member_count: number = 0;
  unsubscribe_count: number = 0;
  cleaned_count: number = 0;
  member_count_since_send: number = 0;
  unsubscribe_count_since_send: number = 0;
  cleaned_count_since_send: number = 0;
  campaign_count: number = 0;
  campaign_last_sent?: Date;
  merge_field_count: number = 0;
  avg_sub_rate: number = 0;
  avg_unsub_rate: number = 0;
  target_sub_rate: number = 0;
  open_rate: number = 0;
  click_rate: number = 0;
  last_sub_date: Date;
  last_unsub_date: Date;
  last_unsub_date2: string = 'ere';

  constructor(data: Partial<TargetStatsModel>) {
    Object.assign(this, data);
  }
}
