import { BaseModel } from '../base-models/base-model.class';

export class EmailIdentifierModel extends BaseModel {
  name: string;
  mergeTag: IMergeTag;
}

export interface IMergeTag {
  [key: string]: IMergeTagValue;
}

interface IMergeTagValue {
  name: string;
  value: string;
  sample: string;
}
