import { WorkspaceUserMinimal } from './logged-user-models/workspace-user.model';
import { Type } from 'class-transformer';
import { AuthModel } from './logged-user-models/auth.model';
import { PlatformUser } from './logged-user-models/platform-user.model';

export class LoggedUser {
  @Type(() => WorkspaceUserMinimal)
  workspaceUsers: WorkspaceUserMinimal[];

  @Type(() => PlatformUser)
  user: PlatformUser;

  @Type(() => AuthModel)
  auth: AuthModel;
}
