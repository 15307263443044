import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseSharingModel } from '../base-models/base-sharing.model';
import { ContactModel } from './contact.model';

@Exclude()
export class ContactShareModel extends BaseSharingModel<ContactModel> {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  contactId: string;
}
