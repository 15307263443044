<mat-form-field>
  <mat-chip-list #chipList [required]="required" [formControl]="formControl">
    <mat-chip *ngFor="let item of items" [selectable]="true" [removable]="true" (removed)="remove(item)">
      {{ item }}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>

    <input
      #input
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      (input)="inputChange($event.target?.value)"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
