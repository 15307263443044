import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import { IAuthState } from '../models';

export const initialState: IAuthState = {
  organization: null,
  organizations: [],
  organizationUser: null,
  platformUser: null,
  workspaces: [],
  workspace: null,
  workspaceID: null,
  actions: [],
};

const AuthReducerFn = createReducer<IAuthState>(
  initialState,
  on(AuthActions.setOrganizationUser, (state, { organizationUser }) => ({
    ...state,
    organizationUser,
  })),
  on(AuthActions.setWorkspaceID, (state, { workspaceID }) => ({
    ...state,
    workspaceID,
  })),
  on(AuthActions.setWorkspace, (state, { workspace }) => ({
    ...state,
    workspace,
    workspaceID: workspace.id,
  })),
  on(AuthActions.setWorkspaces, (state, { workspaces }) => ({
    ...state,
    workspaces,
  })),
  on(AuthActions.setWsUserActions, (state, { actions }) => ({
    ...state,
    actions,
  })),
  on(AuthActions.setPlatformUser, (state, { user }) => ({
    ...state,
    platformUser: user,
  })),
  on(AuthActions.setOrganizations, (state, { organizations }) => ({
    ...state,
    organizations,
  })),
  on(AuthActions.setLoggedUser, (state, { loggedUser }) => ({
    ...state,
    platformUser: loggedUser.user,
    workspaces: loggedUser.workspaceUsers,
  })),
  on(AuthActions.updateSate, (state, { auth }) => ({
    ...state,
    ...auth,
  })),
  on(AuthActions.switchWorkspace, (state, { workspace }) => ({
    ...state,
    workspaceID: workspace.id,
    organization: state.organizations.find((org) => org.id === workspace.orgId),
    workspace: state.workspaces.find((w) => w.id === workspace.id),
    organizationUser: state.workspaces.find((w) => w.workspaceId === workspace.id).orgUser,
  })),
);

export function AuthReducer(state: IAuthState | undefined, action: Action) {
  return AuthReducerFn(state, action);
}
