import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, Observable, of, zip } from 'rxjs';
import { SvgIcons } from '../assets/svgs.enum';
import { BaseComponent } from '../classes/base-component.class';
import { FilterModel } from '../filters/filter-model.class';
import { goBack } from '../helpers/helpers';
import { IError } from '../interfaces';
import { FormlyPickValue, PickList, PickListValue, PickValue } from '../models';
import { EntityCrudService } from '../services/api/entity-crud.service';
import { EntityNavigationService } from '../services/navigation/entity-navigation.service';
import { ToastService } from '../services/toast/toast.service';

type Entity<T> = Pick<T, keyof T> & { id: string; rowVersion: number; isActive?: boolean };
interface IContent {
  title: string;
  description: string;
  toastResponse: string;
}

@Directive()
export abstract class BaseEntityForm<T> extends BaseComponent implements OnInit, AfterViewInit {
  abstract entity: T;
  abstract entityName: string;
  abstract entityService: EntityCrudService<T>;
  abstract entityNavigationService: EntityNavigationService<T>;

  public relatedFilter: FilterModel;
  public preventPickList: boolean = false;
  public expands: string[] = [];
  public recordId: string;

  public customPickListService: EntityCrudService<any>;

  pickList$: Observable<PickList>;
  pickLists$: Observable<PickList>[];

  resetAndCreateNew: boolean;
  isEdit: boolean;
  preventRedirection: boolean;

  svgIcons = SvgIcons;

  form = new FormGroup({});
  model: T;
  saving: boolean = false;
  options: FormlyFormOptions = {};
  formFields: FormlyFieldConfig[] = [];

  private defPickValues: FormlyPickValue[];

  constructor(
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public toastService: ToastService,
    public el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.preventPickList ? this.createForm() : this.callPickList();
  }

  createForm(): void {}

  ngAfterViewInit(): void {
    this._subscribeToRouter();

    if (this.pickList$) {
      this.pickList$.subscribe((pickList: PickList) => {
        !!pickList && this.defValues(pickList);
      });
    }

    if (this.pickLists$ && this.pickLists$.length) {
      zip(this.pickLists$).subscribe((pickLists: PickList[]) => {
        pickLists.map((pickList: PickList) => {
          !!pickList && this.defValues(pickList);
        });
      });
    }
  }

  cancel(): void {
    goBack();
  }

  callPickList(): void {
    this.customPickListService ? this.callCustomPickListService() : this.callEntityPickListEndpoint();
  }

  callEntityPickListEndpoint(): void {
    this.subscription = this.entityService.pickList().subscribe({
      next: (pickList: PickList) => {
        this.pickList$ = of(pickList);
        this.createForm();

        setTimeout(() => !!pickList && this.defValues(pickList), 0);
      },
    });
  }

  callCustomPickListService(): void {
    this.subscription = this.customPickListService.pickList().subscribe({
      next: (pickList: PickList) => {
        this.pickList$ = of(pickList);
        this.createForm();

        setTimeout(() => !!pickList && this.defValues(pickList), 0);
      },
    });
  }

  submit(): void {
    if (!this.form.valid) {
      if (!!this.el) {
        const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
          invalidControl[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      return;
    }

    this.save();
  }

  update(): void {
    this.model = Object.assign({}, this.model);
  }

  saveAndNew(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.resetAndCreateNew = true;
      this.save();
    }
  }

  save(): void {
    this.setSaving(true);
    this.subscription = this.entityService.save(this.entity as Entity<T>).subscribe({
      next: (entity: T) => this._handleSuccess(entity),
      error: (err: IError) => this._handleError(err),
    });
  }

  resetForm(): void {
    this.resetAndCreateNew = false;
    this.entity = null;

    setInterval(() => {
      this.form.reset();
    }, 0);
  }

  askForReset(): void {}

  getDetails(id: string): void {
    this.subscription = this.entityService.getById(id, this.expands, this.relatedFilter).subscribe({
      next: (entity: T) => this.handleGetDetailsSuccess(entity),
      error: (err: IError) => this.handleGetDetailsError(err),
    });
  }

  content(): IContent {
    return this.isEdit
      ? {
          title: `Update ${this.entityName.toLowerCase()}`,
          description: `Edit this ${this.entityName.toLowerCase()}`,
          toastResponse: `<b>Done</b>: ${this.entityName} updated!`,
        }
      : {
          title: `Create ${this.entityName.toLowerCase()}`,
          description: `Add a new ${this.entityName.toLowerCase()}`,
          toastResponse: `<b>Done</b>: ${this.entityName} created!`,
        };
  }

  // tslint:disable-next-line:cognitive-complexity
  findField(fieldId: string, formFields: FormlyFieldConfig[] = this.formFields): FormlyFieldConfig {
    let field = null;
    formFields.forEach((item) => {
      let keyParts = [];
      if (item.key && typeof item.key === 'string') {
        keyParts = item.key.split('.');
      }

      if (item.fieldGroup && item.fieldGroup.length > 0) {
        if (!field) {
          field = this.findField(fieldId, item.fieldGroup);
        }
      } else {
        if (
          !field &&
          (item.key === fieldId || (keyParts.length && keyParts[keyParts.length - 1] === fieldId))
        ) {
          field = item;
        }
      }
    });

    return field;
  }

  findFieldByLabel(label: string, formFields: FormlyFieldConfig[]): FormlyFieldConfig {
    return formFields.find((item) => {
      return item.templateOptions.label === label;
    });
  }

  getFieldOptions(field: string): Observable<any> {
    const options = this.findField(field, this.formFields).templateOptions.options;
    return Array.isArray(options) ? of(options) : (options as Observable<any>);
  }

  getFieldOptionModel(field: string, value: unknown): Observable<any> {
    return this.getFieldOptions(field).pipe(
      map((items: object[]) => items.find((item) => item[field] === value)),
    );
  }

  disableForm(formFields: FormlyFieldConfig[]) {
    formFields.forEach((item) => {
      if (item.templateOptions) {
        item.templateOptions.disabled = true;
      }
    });
  }

  getFormKeys(formFields: FormlyFieldConfig[]): string[] {
    const fields = [];

    formFields.forEach((item) => {
      if (item.fieldGroup && item.fieldGroup.length > 0) {
        fields.push(...this.getFormKeys(item.fieldGroup));
      } else {
        if (typeof item.key !== 'undefined') {
          fields.push(item.key);
        }
      }
    });

    return fields;
  }

  setSaving(saving: boolean): void {
    this.saving = saving;
  }

  handleComplete(): void {
    this.setLoading(false);
    this.setSaving(false);
  }

  pick(key: string, validFor?: string): Observable<PickListValue[]> {
    return this.pickList$.pipe(
      map((res: PickList) => {
        return !!res
          ? !!validFor && res[key].values
            ? res[key].values.filter((v) => v.validFor === validFor)
            : res[key].values
          : [];
      }),
    );
  }

  pickBy(key: string, entity: string): Observable<PickListValue[]> {
    return zip(this.pickLists$).pipe(
      map((res: PickList[]) => {
        const found = res.find((item: PickList) => !!item && item[key] && item[key].entity === entity);
        return !!found ? found[key].values : [];
      }),
    );
  }

  pickBypassing(key: string, pickList: Observable<PickList>): Observable<PickListValue[]> {
    return pickList
      ? pickList.pipe(
          map((res: PickList) => {
            return !!res ? res[key].values : [];
          }),
        )
      : of([]);
  }

  defValues(pickList: PickList): void {
    const keys: string[] = Object.keys(pickList);
    const values: PickValue[] = Object.values(pickList);
    const convertedPickList = keys.map((key: string, index: number) => ({
      key,
      definition: values[index],
    }));

    this.defPickValues = convertedPickList.filter((list: FormlyPickValue) => list.definition.defaultValue);
    this.formFields.map((formField) => this._updateFieldValue(formField));
  }

  handleGetDetailsSuccess(entity: T): void {
    this.entity = entity;
    this.isEdit = true;
    this.cdr.markForCheck();
  }

  handleGetDetailsError(err: IError, redirectBack = true): void {
    const { message, name } = err.error || err;
    this.toastService.showWarning(`${name}: ${message}`);
    redirectBack && goBack();
  }

  public detectPickList = (field: FormlyFieldConfig, relatedFieldKey: string, pickList: string) =>
    this.detectPickListChanges(field, relatedFieldKey, pickList);

  public detectPickListChanges(field: FormlyFieldConfig, relatedFieldKey: string, pickList: string) {
    const relatedField = field.form.get(relatedFieldKey);
    if (relatedField) {
      this.subscription = relatedField.valueChanges.subscribe((val) => {
        field.formControl.setValue(null);
        field.templateOptions.options = val ? this.pick(pickList, val) : [];
      });
    }
  }

  public _handleSuccess(entity: T): void {
    this.entity = entity;
    this.toastService.showSuccess(this.content().toastResponse);
    this.setLoading(false);
    this.setSaving(false);
    this.cdr.markForCheck();
    this._handleComplete();
  }

  public _handleError(error: IError) {
    const { message, name } = error.error;
    this.toastService.showError(`<b>${name}</b>: ${message}`);
    this.setLoading(false);
    this.setSaving(false);
    this.cdr.markForCheck();
  }

  private _handleComplete(): void {
    if (this.resetAndCreateNew) {
      this.resetForm();
    } else {
      this._handleRedirection();
    }
  }

  private _handleRedirection(): void {
    if (this.preventRedirection) {
      goBack();
    } else {
      const id: string = (this.entity as Entity<T>).id;
      this.isEdit ? goBack() : this.entityNavigationService.goToDetails(id);
    }
  }

  private _updateFieldValue(formlyField: FormlyFieldConfig): void {
    const _defField = this.defPickValues.find((defVal: FormlyPickValue) => defVal.key === formlyField.key);
    _defField && formlyField.formControl.setValue(_defField.definition.defaultValue);

    formlyField.fieldGroup &&
      formlyField.fieldGroup.length &&
      formlyField.fieldGroup.map((childField: FormlyFieldConfig) => this._updateFieldValue(childField));
  }

  private _subscribeToRouter(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.recordId = id;
      this.getDetails(id);
    }
  }
}
