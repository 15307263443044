import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { FieldType, FormlyFieldConfig, ɵdefineHiddenProp } from '@ngx-formly/core';

export interface MatFormlyFieldConfig extends FormlyFieldConfig {
  _matprefix: TemplateRef<any>;
  _matsuffix: TemplateRef<any>;
  __formField__: FormlyFormFieldWrapperComponent;
  _componentFactory: any;
}

interface ITemplateChanges {
  template: TemplateRef<any>;
  location: 'prefix' | 'suffix';
}

@Component({
  selector: 'lib-wrapper-mat-form-field',
  template: `
    <!-- fix https://github.com/angular/material2/pull/7083 by setting width to 100% -->
    <mat-form-field
      style="position: relative"
      [ngClass]=''
      [hideRequiredMarker]="to.required"
      [floatLabel]="to.floatLabel"
      [appearance]="to.appearance"
      [color]="to.color"
      [style.width]="'100%'">

      <ng-container #fieldComponent></ng-container>

      <mat-label *ngIf="to.label && to.hideLabel !== true">
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker">*</span>
      </mat-label>
      <ng-container matPrefix>
        <ng-container *ngTemplateOutlet="to.prefix ? to.prefix : formlyField._matprefix"></ng-container>
      </ng-container>
      <ng-container matSuffix>
        <ng-container *ngTemplateOutlet="to.suffix ? to.suffix : formlyField._matsuffix"></ng-container>
      </ng-container>
      <!-- fix https://github.com/angular/material2/issues/7737 by setting id to null  -->
      <mat-error [id]="null">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
      <!-- fix https://github.com/angular/material2/issues/7737 by setting id to null  -->
      <mat-hint *ngIf="to.description" [id]="null">{{ to.description }}</mat-hint>


    </mat-form-field>
  `,
  providers: [{provide: MatFormFieldControl, useExisting: FormlyFormFieldWrapperComponent}],
  styles: [
    `
      .view-mode ::ng-deep .mat-form-field-underline {
        display: none;
      }

      .view-mode ::ng-deep .mat-form-field-label {
        display: none !important;
      }
    `
  ]
})
export class FormlyFormFieldWrapperComponent extends FieldType implements OnInit, OnDestroy, MatFormFieldControl<any>, AfterContentChecked {
  @ViewChild('fieldComponent', ({read: ViewContainerRef, static: true} as any)) fieldComponent: ViewContainerRef;
  @ViewChild(MatFormField, {static: true} as any) formField!: MatFormField;

  stateChanges = new Subject<void>();
  templateChanges = new Subject<ITemplateChanges>();
  private initialGapCalculated = false;

  constructor(private renderer: Renderer2,
              protected elementRef: ElementRef,
              protected cdRef: ChangeDetectorRef,
              private focusMonitor: FocusMonitor) {
    super();
  }

  _errorState = false;

  get errorState() {
    const showError = this.options.showError(this);
    if (showError !== this._errorState) {
      this._errorState = showError;
      this.stateChanges.next();
    }

    return showError;
  }

  get controlType() {
    return this.to.type;
  }

  get focused() {
    return !!this.formlyField.focus && !this.disabled;
  }

  get disabled() {
    return !!this.to.disabled;
  }

  get required() {
    return !!this.to.required;
  }

  get placeholder() {
    return this.to.placeholder || '';
  }

  get shouldPlaceholderFloat() {
    return this.shouldLabelFloat;
  }

  get value() {
    return this.formControl.value;
  }

  get ngControl() {
    return this.formControl as any;
  }

  get empty() {
    return !this.formControl.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get formlyField() {
    return this.field as MatFormlyFieldConfig;
  }

  ngOnInit() {
    if (!!this.formField) {
      this.formField._control = this;
      ɵdefineHiddenProp(this.field, '__formField__', this.formField);
    }

    const fieldComponent = this.formlyField._componentFactory;
    if (fieldComponent && !(fieldComponent.componentRef.instance instanceof FieldType)) {
      console.warn(`Component '${fieldComponent.component.prototype.constructor.name}' must extend 'FieldType' from '@ngx-formly/material'.`);
    }

    // fix for https://github.com/angular/material2/issues/11437
    if (this.formlyField.hide && this && this.formlyField.templateOptions.appearance === 'outline') {
      this.initialGapCalculated = true;
    }

    this.focusMonitor.monitor(this.elementRef, true).subscribe(origin => {
      this.field.focus = !!origin;
      this.stateChanges.next();
    });

    this._subscribeToTemplateChanges();

  }

  ngAfterContentChecked(): void {
    if (!this.initialGapCalculated || this.formlyField.hide) {
      return;
    }

    if (!!this.formField) {
      this.formField.updateOutlineGap();
    }
    this.initialGapCalculated = true;
  }

  ngOnDestroy(): void {
    delete this.formlyField.__formField__;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  setDescribedByIds(ids: string[]): void {
  }

  onContainerClick(event: MouseEvent): void {
    this.formlyField.focus = true;
    this.stateChanges.next();
  }

  updateFieldSuffix(suffix: TemplateRef<any>) {
    this.templateChanges.next({template: suffix, location: 'suffix'});
  }

  updateFieldPrefix(prefix: TemplateRef<any>) {
    this.templateChanges.next({template: prefix, location: 'prefix'});
  }

  private _subscribeToTemplateChanges(): void {
    this.templateChanges.asObservable().subscribe(toUpdate => {
      this.to[toUpdate.location] = toUpdate.template;
      this.cdRef.detectChanges();
    });
  }

}
