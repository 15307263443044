import { Exclude, Expose, Type } from 'class-transformer';
import { CampaignModel, ContactModel } from '../index';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { LeadModel } from '../lead/lead.model';

@Exclude()
export class CampaignMemberModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  status: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  campaignId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  leadId: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  contactId: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  firstRespondedDate: Date;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  hasResponded: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => CampaignModel)
  campaign?: CampaignModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => LeadModel)
  lead?: LeadModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => ContactModel)
  contact?: ContactModel;

  constructor(campaignMember: Partial<CampaignMemberModel>) {
    super(campaignMember);
    Object.assign(this, campaignMember);
  }
}
