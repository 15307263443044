import { Component, OnInit } from '@angular/core';
import { Register } from '../shared/register.class';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { BaseFormComponent } from '../../../../../lib/src/classes/base-form-component.class';
import { ToastService } from '../../../../../lib/src/services/toast/toast.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseFormComponent implements OnInit {
  returnUrl: string;
  register: Register = new Register();
  emailRegex = '^[a-z0-9-_]+(\\.[_a-z0-9]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
  ) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  createForm(): void {
    this.formFields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'Name',
          placeholder: 'Name',
          required: true,
        },
        validators: {
          validation: [Validators.compose([Validators.required])],
        },
      },
      {
        key: 'organization',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'Organisation',
          placeholder: 'Organisation',
          required: true,
        },
        validators: {
          validation: [Validators.compose([Validators.required])],
        },
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'email',
          label: 'Email',
          placeholder: 'email',
          required: true,
        },
        validators: {
          validation: [
            // Validators.email, Validators.pattern(this.emailRegex)
            Validators.compose([Validators.required]),
          ],
        },
      },
      {
        key: 'phone',
        type: 'phone-select',
        templateOptions: {
          label: 'Phone',
          validatePhone: true,
          autodetect: true,
          description: 'Insert your phone number',
          required: true,
        },
      },
      {
        key: 'password',
        type: 'lib-password',
        focus: false,
        templateOptions: {
          label: 'Password',
          placeholder: 'Password',
          description: 'Password must have at least one letter and one number character',
          required: true,
          minLength: 6,
        },
        validators: {
          validation: ['password-pattern'],
        },
      },
    ];
  }

  save(): void {
    this.setSaving(true);

    this.subscription = this.authService.register(this.register).subscribe(
      () => {
        this.setSaving(false);
        this.toastService.showSuccess('Account created successfully, Please confirm your account');
        this.router.navigate(['auth', 'confirm-registration', this.register.email]).then();
      },
      (error) => {
        this.toastService.showError(error.error.message);
        this.setSaving(false);
      },
    );
  }
}
