import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SvgIcons } from '@lib/assets/svgs.enum';
import { BaseComponent } from '@lib/classes/base-component.class';
import { groupByKey } from '@lib/helpers/helpers';
import { WorkspaceUserMinimal } from '@lib/models';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../auth/shared/auth.service';
import { PlatformUser } from '../../auth/shared/logged-user-models/platform-user.model';
import { ShortWorkspace } from '../../auth/shared/short-workspace.model';
import { RouterConstant } from '../../core/route-constants';
import { IAuthAppState } from '../../state-management/models';
import { fromAuth } from '../../state-management/selectors';
import { CreateWorkspaceFormComponent } from './create-workspace-form/create-workspace-form.component';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
})
export class HeaderToolbarComponent extends BaseComponent {
  public user$: Observable<PlatformUser>;
  public workspaceUser$: Observable<WorkspaceUserMinimal>;
  public workspaces: ShortWorkspace[];
  public title = '';

  public orgWorkspaces: WorkspaceUserMinimal[][];

  svgIcons = SvgIcons;

  constructor(
    private router: Router,
    private authService: AuthService,
    private authStore: Store<IAuthAppState>,
    private dialog: MatDialog,
  ) {
    super();
    this.user$ = this.authStore.select(fromAuth.selectPlatformUser);
    this.workspaceUser$ = this.authStore.select(fromAuth.selectWorkspace);
    this.loadWorkspaces();
  }

  loadWorkspaces(): void {
    this.subscription = this.authStore.select(fromAuth.selectWorkspaces).subscribe({
      next: (val) => {
        this.workspaces = val.map((w) => w.workspace);
        const groups = groupByKey(val, 'orgId');
        this.orgWorkspaces = Object.values(groups);
      },
    });
  }

  logout(): void {
    this.authService.logout();
  }

  selectWorkspace(workspace: WorkspaceUserMinimal): void {
    this.workspaceUser$ = of(workspace);
    this.authService.selectWorkspace(workspace);
    this.router.navigate([RouterConstant.home.routePath]).then();
  }

  createWorkspace(): void {
    this.dialog
      .open(CreateWorkspaceFormComponent)
      .afterClosed()
      .subscribe({
        next: (res: boolean) => {
          res &&
            this.authService.refreshToken().subscribe({
              next: (result) => {
                const groups = groupByKey(result.workspaceUsers, 'orgId');
                this.orgWorkspaces = Object.values(groups);
                console.log(this.orgWorkspaces);
              },
            });
        },
      });
  }
}
