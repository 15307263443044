import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  public enabled: boolean = false;

  @Input() defaultValue: boolean;
  @Output() handleOnChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set initValue(value: boolean) {
    this.enabled = value;
  }

  onToggleClick(): void {
    this.enabled = !this.enabled;
    this.handleOnChange.emit(this.enabled);
  }
}
