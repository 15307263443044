import { Type } from 'class-transformer';
import { OrganizationUser } from './organization-user.model';
import { ShortOrganization } from './short-organization.class';
import { ShortWorkspace } from './short-workspace.model';

export class WorkspaceUserMinimal {
  id: string;
  orgId: string;
  workspaceId: string;
  isActive: boolean;

  @Type(() => ShortOrganization)
  organization: ShortOrganization;

  @Type(() => ShortWorkspace)
  workspace: ShortWorkspace;

  @Type(() => OrganizationUser)
  orgUser: OrganizationUser;

  createdDate: Date;

  constructor(workspaceUser: WorkspaceUserMinimal) {
    Object.assign(this, workspaceUser);
  }
}
