import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { FormlyFormFieldWrapperComponent } from './formly-form-field-wrapper.component';

@Component({
  selector: 'lib-formly-vertical',
  providers: [{ provide: MatFormFieldControl, useExisting: FormlyFormFieldWrapperComponent }],
  template: `
    <div class="flex form-group vertical items-center mb-5 relative" [ngClass]="{ 'has-error': showError }">
      <div [ngClass]="fieldClass" class="flex flex-col w-full px-2">
        <div>
          <label
            *ngIf="!hideLabelColumn"
            [attr.for]="id + '_input'"
            class="label font-semibold"
            [class]="labelClass"
            [class.required]="required"
            [style.color]="'#181c32'"
            [style.fontSize]="'12px'"
          >
            {{ label }}
            <span *ngIf="required" class="text-danger">*</span>
          </label>
        </div>
        <ng-container #fieldComponent></ng-container>
        <div class="input-error ml-2">
          <formly-validation-message *ngIf="showError" [field]="field"> </formly-validation-message>
        </div>
      </div>
    </div>
  `,
})
export class VerticalInputComponent extends FieldWrapper implements OnInit, AfterViewInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;
  @ViewChild(MatFormField, { static: true } as any) formField!: MatFormField;

  get labelClass(): string {
    return this.to.labelClass;
  }

  get hideLabelColumn(): boolean {
    return this.to.hideLabelColumn || false;
  }

  get label(): string {
    return (typeof this.to.showLabel === 'undefined' || this.to.showLabel) && !this.to.innerLabel
      ? this.to.label
      : '';
  }

  get required(): boolean {
    return typeof this.to.showLabel === 'undefined' || this.to.showLabel ? this.to.required : false;
  }

  get fieldClass(): string {
    return this.to.fieldClass || '';
  }

  get controlType(): string {
    return this.to.type;
  }

  get focused(): boolean {
    return !!this.formlyField.focus && !this.disabled;
  }

  get disabled(): boolean {
    return !!this.to.disabled;
  }

  get formlyField(): any {
    return this.field;
  }

  get placeholder(): string {
    return this.to.placeholder || '';
  }

  get value(): any {
    return this.formControl.value;
  }

  get ngControl(): any {
    return this.formControl as any;
  }

  ngOnInit(): void {
    if (!!this.formField) {
      // this.formField._control = this;
      // ɵdefineHiddenProp(this.field, '__formField__', this.formField);
    }
  }

  ngAfterViewInit(): void {}
}
