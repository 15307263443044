import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinBtnDirective } from './spin-btn.directive';
import {MatSpinner} from '@angular/material/progress-spinner';
import {MatIcon} from '@angular/material/icon';


@NgModule({
  declarations: [
    SpinBtnDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SpinBtnDirective
  ],
  entryComponents: [
    MatSpinner,
    MatIcon
  ]
})
export class DirectivesModule {}
