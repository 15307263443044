export class Country {
  id?: string;
  code: string;
  name: string;
  dialingCode?: string;

  constructor(code: string, name: string, dialingCode?: string, id?: string) {
    this.code = code;
    this.name = name;
    this.dialingCode = dialingCode;
    this.id = id;
  }
}
