import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITab } from '../shared/tab.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-tab-underline',
  templateUrl: './tab-underline.component.html',
  styleUrls: ['./tab-underline.component.scss'],
})
export class TabUnderlineComponent {
  @Input() tabs: ITab[];
  @Input() classes: string = '';

  @Output() handleTabSelection: EventEmitter<ITab> = new EventEmitter<ITab>();

  constructor(private router: Router) {}

  navigateTo(event: Event): void {
    const path: string = (event.target as HTMLInputElement).value;
    this.router.navigate([path]).then();
  }

  tabSelected(tab: ITab): void {
    this.tabs = this.tabs.map((item) => ({ ...item, selected: tab.key === item.key }));
    this.handleTabSelection.emit(tab);
  }

  isRouteActive(routePath: string): boolean {
    return this.router.url === routePath || this.router.url.trim().includes(routePath.trim());
  }
}
