import { Injectable } from '@angular/core';
import {HttpBackendClientService} from '../http-backend-client/http-backend-client.service';
import {forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private appSettings: any = {};

  constructor(private httpClient: HttpBackendClientService) {
  }

  loadConfig(endpoints: string[]): Promise<any> {
    const resolution = endpoints.map(item => this.httpClient.get(item));
    return forkJoin(resolution)
      .toPromise()
      .then(data => {
        data.forEach(item => this.appSettings = Object.assign(this.appSettings, item));
        return Promise.resolve();
      }, () => {
        return Promise.reject(new Error('Cannot load configuration files!'));
      });
  }

  getSettings(key?: string | string[], defaultValue?: any): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.appSettings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    let result = key
      .reduce((acc: any, current: string) => acc && acc[current], this.appSettings);

    if (result === undefined) {
      result = defaultValue;

      if (result === undefined) {
        return null;
      }
    }
    return result;
  }
}
