import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, Subscription, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { ConfigService } from '../shared/config.service';
import { LoginStorage } from '../../enums/login-storage.enum';
import { Router } from '@angular/router';
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from '../../helpers/helpers';
import { getOrElseAction, Maybe } from '@org/common-cross-lib';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  private readonly _apiKey: string;
  path: string;

  private environment;
  baseUrl: string;

  private subscriptions: Subscription;

  constructor(
    private injector: Injector,
    protected http: HttpClient,
    private router: Router,
    @Inject('environment') environment,
    private locationPath: Location,
  ) {
    const configService = this.injector.get(ConfigService);
    this.environment = environment;
    this.baseUrl = this.environment.apiBaseHref;
    try {
      this._apiKey = configService.getSettings('apiKey');
    } catch {
      this._apiKey = 'fake';
    }
  }

  getHeaders(): any {
    return {
      'X-Date': new Date().toUTCString(),
      // 'X-Api-Key': this._apiKey,
    };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('config.json')) {
      return next.handle(req);
    }

    const cloneReq = req.clone({
      setHeaders: this.getHeaders(),
    });

    return next.handle(cloneReq).pipe(
      catchError((err: any) => {
        const path = this.locationPath.path().split('?')[0];

        if (err instanceof HttpErrorResponse && err.status === 401 && !path.contains('/auth/')) {
          const refreshToken = getOrElseAction<string, string>(
            getItemFromLocalStorage(LoginStorage.REFRESH_TOKEN_NAME) as Maybe<string>,
            () => {
              this.logout();
              return 'logout';
            },
          );
          this.refreshToken(refreshToken).subscribe(
            (res) => {
              setItemToLocalStorage(LoginStorage.ID_TOKEN_NAME, res.auth.idToken);
              setItemToLocalStorage(LoginStorage.ACCESS_TOKEN_NAME, res.auth.accessToken);
              this.redirectTo(path);
            },
            () => {
              this.logout();
            },
          );
        }
        return throwError(err);
      }),
    );
  }

  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }

  logout(): void {
    this._clear();
    location.reload();
  }

  private _clear(): void {
    removeItemFromLocalStorage([
      LoginStorage.ORGANIZATION,
      LoginStorage.WORKSPACES,
      LoginStorage.WORKSPACE,
      LoginStorage.USER_NAME,
      LoginStorage.ID_TOKEN_NAME,
      LoginStorage.ACCESS_TOKEN_NAME,
      LoginStorage.REFRESH_TOKEN_NAME,
    ]);
  }

  private refreshToken(refToken: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/auth/refresh-token', { refreshToken: refToken }).pipe(
      map((response) => {
        return response;
      }),
    );
  }
}
