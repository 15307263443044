import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';

@Exclude()
export class SystemStatusModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isCompleted: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissions: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  key: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  title: string;
}
