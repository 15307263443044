import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonsModule } from '../components/buttons/buttons.module';
import { CountryPickerModule } from '../components/country-picker/country-picker.module';
import { CurrencyPickerModule } from '../components/currency-picker/currency-picker.module';
// import { DateTimePickerModule } from '../components/date-time-picker/date-time-picker.module';
import { FileUploadModule } from '../components/file-upload/file-upload.module';
import { SecondPhoneModule } from '../components/second-phone/second-phone.module';
import { TagInputModule } from '../components/tag-input/tag-input.module';
import { ThirdPhoneModule } from '../components/third-phone/third-phone.module';
import { MaterialModule } from '../material.module';
import { FORMLY_CONFIG } from './formly.config';
import { CountryPickerComponent } from './types/advanced/country-picker.component';
import { CurrencyPickerComponent } from './types/advanced/currency-picker.component';
// import { FormlyDateTimePickerComponent } from './types/advanced/date-time-picker/date-time-picker.component';
import { FormlyFileUploadComponent } from './types/advanced/formly-file-upload.component';
import { SecondPhoneSelectComponent } from './types/advanced/second-phone-select.component';
import { SelectorComponent } from './types/advanced/selector/selector.component';
import { TagInputComponent } from './types/advanced/tag-input.component';
import { AdditionalFieldsComponent } from './types/basic/additional-fields/additional-fields.component';
import { DatePickerComponent } from './types/basic/date-picker/date-picker.component';
import { NumberInputComponent } from './types/basic/number-input.component';
import { NumberInputComponent as MyNumberInputComponent } from './types/basic/number-input/number-input.component';
import { PasswordInputComponent } from './types/basic/password-input/password-input.component';
import { PasswordComponent } from './types/basic/password/password.component';
import { RadioComponent } from './types/basic/radio/radio.component';
import { TextInputComponent } from './types/basic/text-input/text-input.component';
import { ToggleFormlyComponent } from './types/basic/toggle/toggle.component';
import { AnimationWrapperComponent } from './wrappers/animation-wrapper';
import { FormlyFormFieldWrapperComponent } from './wrappers/formly-form-field-wrapper.component';
import { HorizontalInputComponent } from './wrappers/horizontal-input.component';
import { PanelWrapperComponent } from './wrappers/panel/panel.component';
import { VerticalInputComponent } from './wrappers/vertical-input.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    TagInputModule,
    CountryPickerModule,
    ReactiveFormsModule,
    CurrencyPickerModule,
    FormlyMaterialModule,
    FileUploadModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    MatNativeDateModule,
    FormlyModule.forRoot(FORMLY_CONFIG),
    SecondPhoneModule,
    NgSelectModule,
    TextMaskModule,
    ThirdPhoneModule,
    ButtonsModule,
    // DateTimePickerModule,
  ],
  declarations: [
    TagInputComponent,
    NumberInputComponent,
    CountryPickerComponent,
    CurrencyPickerComponent,
    PasswordComponent,
    FormlyFormFieldWrapperComponent,
    FormlyFileUploadComponent,
    DatePickerComponent,
    SecondPhoneSelectComponent,
    HorizontalInputComponent,
    TextInputComponent,
    VerticalInputComponent,
    RadioComponent,
    MyNumberInputComponent,
    SelectorComponent,
    PasswordInputComponent,
    AdditionalFieldsComponent,
    PanelWrapperComponent,
    AnimationWrapperComponent,
    ToggleFormlyComponent,
    // FormlyDateTimePickerComponent,
  ],
  exports: [
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    NgSelectModule,
    RadioComponent,
  ],
})
export class FormModule {}
