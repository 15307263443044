import { ICellRendererParams } from 'ag-grid-community';

export const callPhone = (params): HTMLElement =>
  params.data ? generateTemplate(params) : undefined;

const generateTemplate = (params: ICellRendererParams): HTMLElement => {
  const eGui = document.createElement('div');
  const value = params.getValue();

  eGui.innerHTML = value
    ? `<a href="tel:${value}" class="font-semibold text-gray-800 hover:text-primary">${value}</a>`
    : `-`;
  return eGui;
};

