import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from '../classes/base-component.class';
import { InfoItem } from '../components/details-preview/shared/info-item.class';

@Directive()
export abstract class BaseDetails<MODEL> extends BaseComponent implements OnInit {
  abstract entity$: Observable<MODEL>;
  public infoItems$: Observable<InfoItem[]>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._subscribeToEntity();
  }

  private _subscribeToEntity(): void {
    if (!!this.entity$) {
      this.subscription = this.entity$.subscribe({ next: (entity: MODEL) => this.setInfoItems(entity) });
    }
  }

  abstract setInfoItems(entity: MODEL): void;
}
