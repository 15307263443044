import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types';
import { BaseOrgWorkspaceModel } from '../../base-models/base-org-workspace-model.class';

@Exclude()
export class PermissionSettingModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  masterLabel: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  label: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  value: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  defaultValue: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isFeature: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  scopes: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  clientVisibilityProvider: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isEncrypted: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: Nullable<string>;

  constructor(permissionSetting: Partial<PermissionSettingModel>) {
    super(permissionSetting);
    Object.assign(this, permissionSetting);
  }
}
