<div class="flex w-full justify-center">
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="/assets/img/logo2.png" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        <span class="font-medium text-indigo-600 hover:text-indigo-500"> PRODUCT MANAGER </span>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="form">
          <h2>Sign In</h2>
          <form [formGroup]="form" (submit)="submit()" novalidate>
            <formly-form [fields]="formFields" [form]="form" [model]="login">
              <div class="forgot-pass">
                <a routerLink="/auth/forgot-password">Forgot Password</a>
              </div>
              <div class="button mt-4">
                <button mat-stroked-button color="primary" class="btn-rounded btn-lg" [libSpinBtn]="saving">
                  Sign In
                </button>
              </div>
            </formly-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
