import { createAction, props } from '@ngrx/store';
import {
  PermissionSetModel,
  PickList,
  WorkspaceUserModel,
  HierarchyModel,
  WorkspaceModel,
  SiteModel,
  PermissionSetAssignment,
} from '@lib/models';

const setWorkspace = createAction('[Workspace] Set', props<{ workspace: WorkspaceModel }>());

const setWorkspaceUserPickList = createAction(
  '[Workspace] Set workspace pick list',
  props<{ pickList: PickList }>(),
);

const setWorkspaceUser = createAction(
  '[Workspace] Set workspace user',
  props<{ workspaceUser: WorkspaceUserModel }>(),
);

const setWorkspaceUserAssignedPermissionSets = createAction(
  '[Workspace] Set workspace user assigned permission sets',
  props<{ permissionSets: PermissionSetAssignment[] }>(),
);

const setExistingInvitationWsUser = createAction(
  '[Workspace] Set workspace user',
  props<{ existingUser: WorkspaceUserModel }>(),
);

const setPermissionSet = createAction(
  '[Workspace] Set permission set',
  props<{ permissionSet: PermissionSetModel }>(),
);

const updatePermissionSetRowVersion = createAction(
  '[Workspace] Update permission set row version',
  props<{ permissionSet: PermissionSetModel }>(),
);

const setSite = createAction('[Workspace] Set site', props<{ site: SiteModel }>());

const reqRoleUpdate = createAction('[Workspace] Req role update', props<{ flag: boolean }>());
const moveRole = createAction('[Workspace] set role selection', props<{ role: HierarchyModel }>());
const moveRoleParent = createAction('[Workspace] set role parent', props<{ role: HierarchyModel }>());
const cancelModeMode = createAction('[Workspace] cancel role movement');

export const WorkspaceActions = {
  setWorkspace,
  setWorkspaceUser,
  setWorkspaceUserAssignedPermissionSets,
  setWorkspaceUserPickList,
  setExistingInvitationWsUser,
  setPermissionSet,
  updatePermissionSetRowVersion,
  moveRole,
  moveRoleParent,
  cancelModeMode,
  reqRoleUpdate,
  setSite,
};
