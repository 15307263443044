import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IError } from '../interfaces';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(public router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: IError) => {
        const { message, name } =
          !!error?.error && typeof error.error === 'object'
            ? { message: error.error.message, name: (error.error as any).error || error.error.name }
            : error;
        const _error: Error = { ...error, message, name: name as string };

        throw _error;
      }),
    );
  }
}
