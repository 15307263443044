import { Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../shared/login.class';
import { AuthService } from '../shared/auth.service';
import { BaseFormComponent } from '@lib/classes/base-form-component.class';
import { ToastService } from '@lib/services/toast/toast.service';
import { AwsCognitoErrors, GenericErrors } from '@org/common-cross-lib';
import { LoggedUser } from '../shared/logged-user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseFormComponent implements OnInit {
  returnUrl: string;
  login: Login = new Login();
  newPasswordRequest: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
  ) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  createForm(): void {
    this.formFields = [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'email',
          label: 'Email',
          placeholder: 'email',
          required: true,
        },
        validators: {
          validation: [Validators.compose([Validators.required, Validators.email])],
        },
      },
      {
        key: 'password',
        type: 'lib-password',
        focus: false,
        defaultValue: environment.production ? '' : 'Password1!',
        templateOptions: {
          label: 'Password',
          placeholder: 'password',
          type: 'password',
          required: true,
        },
        validation: {
          show: true,
        },
        expressionProperties: {
          'validation.show': 'model.showErrorState',
        },
        validators: {
          validation: [
            Validators.compose([Validators.required, Validators.minLength(8)]),
            'password-reactive-pattern',
          ],
        },
      },
      {
        key: 'newPassword',
        type: 'lib-password',
        focus: false,
        templateOptions: {
          label: 'New Password',
          placeholder: 'New password',
          type: 'password',
          required: true,
        },
        validation: {
          show: true,
        },
        expressionProperties: {
          'validation.show': 'model.showErrorState',
        },
        validators: {
          validation: [
            Validators.compose([Validators.required, Validators.minLength(8)]),
            'password-reactive-pattern',
          ],
        },
        hideExpression: () => {
          return !this.newPasswordRequest;
        },
      },
    ];
  }

  save(): void {
    this.setSaving(true);
    this.subscription = this.authService.login(this.login).subscribe(
      (user: LoggedUser) => {
        this.setSaving(false);
        this._handleRedirection(user);
      },
      (error) => {
        if (error.error && error.error.error) {
          switch (error.error.error) {
            case GenericErrors.NEW_PASSWORD_REQUIRED:
              this.newPasswordRequest = true;
              this.toastService.showWarning(error.error?.message);
              break;
            case AwsCognitoErrors.NOT_AUTHORIZED_EXCEPTION:
              this.toastService.showError(error.error?.message);
          }
        }
        if (!error.error?.message) {
          this.toastService.showError(error.message);
        }

        // if (error.error && error.error.error === AwsCognitoErrors.USER_NOT_CONFIRMED_EXCEPTION) {
        //   this.router.navigate(['auth', 'confirm-registration', this.login.username]).then();
        // }

        this.setSaving(false);
      },
    );
  }

  private _handleRedirection(loggedUser: LoggedUser): void {
    // const { workspaceUsers } = loggedUser;

    this.router.navigate(['auth', 'organization']).then();

    // TODO: logic
    // if (organizations.length > 1) {
    //   this.router.navigate(['auth', 'organization']).then();
    // } else {
    //   // TODO: Remove setTimeout callbacks bellow
    //   setTimeout(() => {
    //     // this.authService.selectOrganization(organizations[0]);
    //   }, 0);
    //   setTimeout(() => {
    //     this.router.navigate([this.returnUrl]).then();
    //   }, 0);
    // }
  }
}
