export class TradingAccountMapSyncModel {
  accountId: string;
  login: number;
  serverId: string;
  name: string;

  constructor(data: Partial<TradingAccountMapSyncModel>) {
    Object.assign(this, data);
  }
}
