<div
  class="list-section section border-0 relative flex flex-col bg-white"
  [ngClass]="emptyRecords ? 'border-gray-200 border-solid border-b' : ''"
>
  <ng-container *ngIf="!editableSection; else editable">
    <div *ngIf="!!gridHeader?.name" class="section-header">
      <div class="section-title">
        <div class="flex flex-col">
          <div class="flex items-center">
            <h3>{{ gridHeader.name }}</h3>
            <h5
              *ngIf="gridHeader?.description"
              class="subtitle text-xs leading-none relative font-medium text-gray-400 ml-2 top-0.5"
            >
              {{ gridHeader.description }}
            </h5>

            <div class="ml-8 relative top-0.5">
              <ng-content select="[bulkActions]"></ng-content>
            </div>
          </div>
          <ng-content select="[quickInfo]"></ng-content>
        </div>
      </div>
      <div class="section-actions flex justify-end">
        <ng-content select="[checkButtons]"></ng-content>

        <ng-container *ngIf="!shortMode && !emptyRecords">
          <div *ngIf="views && views.length" class="views flex">
            <lib-dropdown
              [actions]="views"
              [icon]="svgIcons.files2"
              [classes]="'mr-3'"
              (handleBulkActionClick)="selectView($event)"
              [selection]="true"
            ></lib-dropdown>
          </div>
          <lib-dropdown
            [actions]="viewControls"
            (handleBulkActionClick)="clickViewControls($event)"
            [classes]="'mr-3'"
            [icon]="svgIcons.settings2"
          ></lib-dropdown>
          <lib-dropdown
            [actions]="displayControls"
            (handleBulkActionClick)="selectDisplay($event)"
            [classes]="'mr-3'"
            [selection]="true"
            [icon]="svgIcons.settings"
          ></lib-dropdown>
        </ng-container>
        <ng-container *ngIf="gridHeader?.actions && gridHeader?.actions.length">
          <ng-container *ngFor="let action of gridHeader?.actions">
            <ng-container
              *ngIf="action.permission"
              [ngTemplateOutlet]="
                action?.dropdownActions && action.dropdownActions.length ? dropdownOrGroup : singleButton
              "
              [ngTemplateOutletContext]="{ action: action }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #editable>
  <lib-grid-header-edit-mode
    (exitEditMode)="editableSection = false"
    [name]="hiredAction.key === 'rename' && placeholder"
    (submission)="handleControl($event)"
  ></lib-grid-header-edit-mode>
</ng-template>

<ng-template #dropdownOrGroup let-action="action">
  <ng-container
    [ngTemplateOutlet]="action.type !== 'btn-group' ? dropdown : groupButtons"
    [ngTemplateOutletContext]="{ action: action }"
  ></ng-container>
</ng-template>

<ng-template #dropdown let-action="action">
  <button [matMenuTriggerFor]="menu" [class]="action.type" class="flex ml-2">
    {{ action.name }}
    <span class="arrow-down" [innerHTML]="svgIcons.arrowDown2 | safe"></span>
  </button>
  <mat-menu #menu="matMenu" [hasBackdrop]="true" xPosition="before">
    <div class="py-1 flex flex-col justify-start inner-actions">
      <ng-container *ngFor="let innerAction of action.dropdownActions">
        <label
          *ngIf="innerAction.label"
          class="text-gray-500 font-medium px-5 mt-3 mb-1 first:mt-0"
          [style.fontSize]="'12px'"
          >{{ innerAction.label | uppercase }}
        </label>
        <div *ngIf="innerAction.key && innerAction.name" class="px-3">
          <button
            [style.fontSize]="'14px'"
            (click)="clickAction(innerAction)"
            [class]="innerAction['classes']"
            class="px-2 py-2 text-left w-full rounded font-medium text-gray-800 hover:bg-primary-light hover:text-primary"
          >
            {{ innerAction.name }}
          </button>
        </div>
      </ng-container>
    </div>
  </mat-menu>
</ng-template>

<ng-template #groupButtons let-action="action">
  <div class="relative z-0 inline-flex shadow-sm rounded-md">
    <button
      type="button"
      (click)="clickAction(action)"
      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-primary text-sm font-medium text-primary bg-primary-light hover:bg-primary hover:text-white focus:z-10 focus:outline-none"
    >
      {{ action.name }}
    </button>
    <div class="-ml-px relative block">
      <button
        type="button"
        [matMenuTriggerFor]="menu"
        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-primary bg-primary-light text-sm font-medium text-primary hover:bg-primary hover:text-white focus:z-10 focus:outline-none"
        id="option-menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <span class="sr-only">Open options</span>
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <mat-menu #menu="matMenu" [hasBackdrop]="true" xPosition="before">
        <div class="py-1 flex flex-col justify-start inner-actions">
          <ng-container *ngFor="let innerAction of action.dropdownActions">
            <label
              *ngIf="innerAction.label"
              class="text-gray-500 font-medium px-5 mt-3 mb-1 first:mt-0"
              [style.fontSize]="'12px'"
              >{{ innerAction.label | uppercase }}
            </label>
            <div *ngIf="innerAction.key && innerAction.name" class="px-3">
              <button
                [style.fontSize]="'14px'"
                (click)="clickAction(innerAction)"
                [class]="innerAction['classes']"
                class="px-2 py-2 text-left w-full rounded font-medium text-gray-800 hover:bg-primary-light hover:text-primary"
              >
                {{ innerAction.name }}
              </button>
            </div>
          </ng-container>
        </div>
      </mat-menu>
    </div>
  </div>
</ng-template>

<ng-template #singleButton let-action="action">
  <ng-container
    [ngTemplateOutlet]="action.icon ? buttonWithIcon : defaultButton"
    [ngTemplateOutletContext]="{ action: action }"
  >
  </ng-container>
</ng-template>

<ng-template #buttonWithIcon let-action="action">
  <button class="btn-icon ml-2" [class]="action.type" (click)="clickAction(action)">
    <span class="svg-icon" [innerHTML]="action.icon | safe: 'html'"></span>
    {{ action.name }}
  </button>
</ng-template>

<ng-template #defaultButton let-action="action">
  <button [class]="action.type" class="ml-2" (click)="clickAction(action)">
    {{ action.name }}
  </button>
</ng-template>
