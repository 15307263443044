import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() formControl: FormControl;
  @Input() required: boolean;
  @Input() id;
  @Input() field: FormlyFieldConfig;
  @Input() allowedTypes: string[] = [];

  file: File;
  fileName: string;

  constructor() {
  }

  onTouchedCallback = (_: any) => {
  }

  onChangeCallback = (_: any) => {
  }

  onValidateCallback = (_: any) => {
  }

  ngOnInit() {
  }

  changeEvent(event) {
    this.writeValue(event.target.files[0]);
  }

  openFileBrowser(id: any, event) {
    const element: HTMLElement = document.getElementById(id);
    element.click();
    this.onTouchedCallback(event);
  }

  fileChange(file: File) {
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidateCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value && this.required) {
      return {
        required: false
      };
    }

    if (control.value instanceof File && this.allowedTypes && this.allowedTypes.length
      && !this.allowedTypes.includes(control.value.type.split('/')[1])) {
      return {
        fileType: control.value.type
      };
    }
    return null;
  }

  writeValue(file: File): void {
    this.file = file;
    this.fileName = this.file ? this.file.name : '';
    this.onChangeCallback(this.file);
  }

}
