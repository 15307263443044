<div class="form">
  <h2>Reset Password</h2>
  <form [formGroup]="form" (submit)="submit()" novalidate>
    <formly-form [fields]="formFields" [form]="form" [model]="resetPassword">
      <div class="button">
        <button mat-stroked-button color="warn" class="btn-rounded btn-lg" [libSpinBtn]="saving">Reset</button>
        <button routerLink="/auth/login" class="btn-rounded btn-lg" mat-stroked-button>Cancel</button>
      </div>
    </formly-form>
  </form>
</div>
