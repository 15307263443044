import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseSharingModel } from '../base-models/base-sharing.model';
import { AccountModel } from './account-model';

@Exclude()
export class AccountShareModel extends BaseSharingModel<AccountModel> {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  contactAccessLevel: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountId: string;

  constructor(data: Partial<AccountShareModel>) {
    super(data);
    Object.assign(this, data);
  }
}
