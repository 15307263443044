import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { ProfileModel } from '../profile/profile-model.class';
import { OrgUserModel } from './org-user.model';
import { HierarchyModel } from '../hierarchy/hierarchy.model';

@Exclude()
export class WorkspaceUserModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  userId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  userRoleId: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  photoUrl: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  profileId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  accountId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  contactId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  orgUser?: OrgUserModel;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  contactEmail: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  street: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  city: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  state: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  postalCode: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  phone: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fax: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mobilePhone: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  extension: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  employeeNumber: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  companyName: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  department: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  division: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  managerId: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  locale: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  language: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  timezone: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isActive: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => ProfileModel)
  profile?: Nullable<ProfileModel>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  managerUser?: Nullable<OrgUserModel>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => HierarchyModel)
  userRole?: HierarchyModel;

  get status(): string {
    return this && this.isActive ? 'Active' : 'Inactive';
  }

  static setStatus(isActive: boolean): string {
    return isActive ? 'Active' : 'Inactive';
  }

  constructor(workspaceUser: Partial<WorkspaceUserModel>) {
    super(workspaceUser);
    Object.assign(this, workspaceUser);
  }
}
