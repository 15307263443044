import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPickerComponent } from './currency-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [CurrencyPickerComponent],
  exports: [
    CurrencyPickerComponent
  ]
})
export class CurrencyPickerModule {
}
