import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SvgIcons } from '../../assets/svgs.enum';

@Component({
  selector: 'lib-empty-results-action',
  templateUrl: './empty-results-action.component.html',
  styleUrls: ['./empty-results-action.component.scss'],
})
export class EmptyResultsActionComponent {
  public svgIcons = SvgIcons;

  @Input() classes: string = '';
  @Input() title: string;
  @Input() description: string;
  @Input() svgIcon?: SafeHtml;
  @Input() btnName: string;
  @Input() btnIsVisible: boolean = true;
  @Input() displayBorder = true;
  @Input() hideBoxShadow = false;
  @Input() isError: boolean;

  @Output() emptyButtonAction = new EventEmitter<boolean>();

  constructor() {}

  handleButtonAction(): void {
    this.emptyButtonAction.emit(true);
  }
}
