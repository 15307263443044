<mat-form-field [style.width]="'100%'">
  <input [autocomplete]="to.autocomplete || 'password'"
         [errorStateMatcher]="errorStateMatcher"
         [formControl]="formControl"
         [formlyAttributes]="field"
         [id]="id"
         [placeholder]="to.placeholder"
         [readonly]="to.readonly"
         [tabindex]="to.tabindex || 0"
         [type]="hide ? 'password' : 'text'"
         class="password-hide-input"
         matInput>
  <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
          class="password-hide-button" mat-icon-button matSuffix type="button">
    <mat-icon class="password-hide-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>

  <mat-label *ngIf="to.label && to.hideLabel !== true">
    {{ to.label }}
    <span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker">*</span>
  </mat-label>
  <mat-error [id]="null">
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
  <mat-hint *ngIf="to.description" [id]="null">{{ to.description }}</mat-hint>
</mat-form-field>
