import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { AccountModel } from '../../account-model/account-model';
import { AccountTypeModel } from '../account-type/account-type.model';
import { PlatformServerModel } from '../platform-server/platform-server.model';
import { MarginLevelModel } from '../margin-level.model';
import { BaseOrgWorkspaceAuditModel } from '../../base-models/base-org-workspace-audit-model.class';

@Exclude()
export class TradingAccountModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  login: number;

  @Expose({ groups: [ClassExposeGroups.CREATE] })
  password: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  leverage: number;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountTypeId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isActive: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => PlatformServerModel)
  server?: PlatformServerModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountModel)
  account?: AccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountTypeModel)
  accountType?: AccountTypeModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => MarginLevelModel)
  marginLevels?: MarginLevelModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  platformUser?: any;

  constructor(tradingAccountModel: Partial<TradingAccountModel>) {
    super(tradingAccountModel);
    Object.assign(this, tradingAccountModel);
  }
}
