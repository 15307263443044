import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';

export class KycConfigModel extends BaseOrgWorkspaceModel {
  username: string;
  password: string;
  documentVerification: boolean;
  documentTwoVerification: boolean;
  addressVerification: boolean;
  faceVerification: boolean;
  manualReview: boolean;
  showPrivacyPolicy: boolean;
  withOcr: boolean;
  ttl: number;
  isActive: boolean;
}
