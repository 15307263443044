import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateParser, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import '../../helpers/string-extensions';
import { Router } from '@angular/router';
import { getProperty } from '../../helpers/helpers';
import { LanguageOptions } from './language-options.class';
import { FORMLY_CONFIG } from '../../forms/formly.config';

export class Language {
  code: string;
  name: string;
  nativeName: string;
  countryID: string;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  languageOptions = new LanguageOptions();

  languagesInfo = [
    {code: 'en', name: 'English', nativeName: 'English', countryID: 'gb'},
    {code: 'ru', name: 'Russian', nativeName: 'Pусский', countryID: 'ru'},
    {code: 'de', name: 'German', nativeName: 'Deutsch', countryID: 'de'},
    {code: 'zh', name: 'Chinese', nativeName: '中文', countryID: 'cn'},
    {code: 'es', name: 'Spanish', nativeName: 'Español', countryID: 'es'},
    {code: 'fr', name: 'French', nativeName: 'Français', countryID: 'fr'},
    {code: 'ko', name: 'Korean', nativeName: '한국어', countryID: 'kr'},
    {code: 'it', name: 'Italian', nativeName: 'Italiano', countryID: 'it'},
    {code: 'tr', name: 'Turkish', nativeName: 'Türk', countryID: 'tr'},
    {code: 'vi', name: 'Vietnamese', nativeName: 'Tiếng Việt', countryID: 'vn'},
    {code: 'ja', name: 'Japanese', nativeName: '日本語の', countryID: 'ja'},
    {code: 'ar', name: 'Arabic', nativeName: 'عربى', countryID: 'ar', rtl: true},
    {code: 'el', name: 'Greek', nativeName: 'Ελληνικά', countryID: 'gr'},
    {code: 'pt', name: 'Portuguese', nativeName: 'Portuguesa', countryID: 'pt'},
    {code: 'nl', name: 'Dutch', nativeName: 'Nederlands', countryID: 'nl'},
    {code: 'pl', name: 'Polish', nativeName: 'Polski', countryID: 'pl'},
    {code: 'cs', name: 'Czech', nativeName: 'Český', countryID: 'cs'},
    {code: 'uk', name: 'Ukrainian', nativeName: 'Українська', countryID: 'ua'}
  ];

  get isRtl(): boolean {
    return this._isRtl;
  }

  private _isRtl: boolean = false;

  constructor(public translateService: TranslateService,
              private cookieService: CookieService,
              private router: Router,
              private formlyConfig: FormlyConfig,
              private parser: TranslateParser) {

    this.languageOptions.supportedLanguages = this.setSupportedLanguages();
    this.translateService.setDefaultLang(this.getDefaultLanguage());
  }

  setSupportedLanguages(): string[] {
    return ['en', 'fr'];
  }

  isLanguageSupported(lan: string): boolean {
    return this.languageOptions.supportedLanguages.includes(lan);
  }

  getLanguageOptions(): LanguageOptions {
    return this.languageOptions;
  }

  getLanguage(): string {
    return this.cookieService.get('lang') || this.getDefaultLanguage();
  }

  getDefaultLanguage(): string {
    return this.languageOptions.defaultLanguage || 'en';
  }

  setLanguage(lang?: string): string {
    if (!lang || !this.isLanguageSupported(lang)) {
      lang = this.getLanguage();
    }

    const languageInfo = this.languagesInfo.find(_lang => _lang.code === lang);
    document.body.style.direction = languageInfo.rtl ? 'rtl' : 'ltr';
    this._isRtl = !!languageInfo.rtl;

    this.cookieService.set('lang', lang, 365, '/');
    this.translateService.use(lang);

    return lang;
  }

  onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  get(key: string | string[], interpolateParams?: Object): Observable<string | any> {
    return this.translateService.get(key, interpolateParams);
  }

  instant(key: string | string[], interpolateParams?: Object): string | any {
    if (!key) {
      return '';
    }
    return this.translateService.instant(key, interpolateParams);
  }

  stream(key: string | string[], interpolateParams?: Object): Observable<string | any> { // TODO not working
    return this.translateService.stream(key, interpolateParams);
  }

  initFormTranslations() {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadFormTranslations();
    });
  }

  // tslint:disable-next-line:cognitive-complexity
  private loadFormTranslations() {

    for (const validation of FORMLY_CONFIG.i18nValidationMessages) {
      this.formlyConfig.addValidatorMessage(validation.name, (error: any, field: FormlyFieldConfig) => {
        const expression = this.translateService.instant('form.validations.' + validation.name);
        if (!validation.params) {
          validation.params = [];
        }

        let validationField = {};
        const params = {};

        validation.params.forEach(item => {
          if (item.field === 'field') {
            validationField = field;
          } else {
            validationField = error;
          }

          let property = (getProperty(item.property, validationField) as string);

          if (item.field === 'field') {
            property = this.translateService.instant(property).stripHtmlTags();
          }

          if (field.type === 'checkbox') {
            property = this.translateService.instant('form.this_field');
          }

          params[item.key] = property;
        });
        return this.parser.interpolate(expression, params);
      });
    }
  }

  getData(codes: string[] | string): Language[] {
    if (!Array.isArray(codes)) {
      codes = [codes];
    }
    return codes.map(code => {
      return this.languagesInfo.find(country => country.code === code);
    }).filter(language => !!language);
  }
}
