<div class="py-4 px-2">
  <div class="mb-8 text-center">
    <h1 class="mb-3 font-semibold text-gray-900" [style.fontSize]="'23px'">Change owner</h1>
  </div>

  <form id="form" [formGroup]="form" (submit)="submit()" novalidate>
    <formly-form [fields]="formFields" [form]="form" [model]="entity"></formly-form>
  </form>

  <div class="flex items-center justify-end py-3 mt-3 h">
    <button (click)="cancel()" class="btn-light mr-3">Cancel</button>
    <button mat-stroked-button form="form" class="btn-primary">Submit <span *ngIf="saving">...</span></button>
  </div>
</div>
