import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notSet'
})
export class NotSetPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return (value) ? value : 'Not Available';
  }

}
