import { Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types';
import { BaseModel } from '../../base-models/base-model.class';
import { AccountTypeModel } from '../account-type/account-type.model';

export class GroupModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  serverId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  currencyIsoCode: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  defaultLeverage: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  accountTypeId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountTypeModel)
  accountType: Nullable<AccountTypeModel>;

  constructor(group: Partial<GroupModel>) {
    super(group);
    Object.assign(this, group);
  }
}
