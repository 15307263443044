export class PhoneNumber {
  dialingCode?: string = '';
  number: string = '';
  countryCode?: string = '';
  rawNumber?: string = '';

  constructor(phoneNumber?: string, countryCode?: string, dialingCode?: string, rawNumber?: string) {
    this.dialingCode = dialingCode;
    this.number = phoneNumber;
    this.countryCode = countryCode;
    this.rawNumber = rawNumber;
  }
}
