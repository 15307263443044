<ng-container *ngIf="!error; else errorContainer">
  <div class="w-full">
    <lib-grid-header
      *ngIf="gridHeader?.show"
      [gridApi]="agGridApi"
      [shortMode]="shortMode"
      [gridHeader]="gridHeader"
      [emptyRecords]="showEmpty"
      (handleActionClick)="headerActionClick($event)"
    >
      <div checkButtons>
        <div class="mr-4" *ngIf="isCheckBoxesMode">
          <button class="btn-secondary mr-4" (click)="reloadGrid()" *ngIf="hasUpdatedColumns">Cancel</button>
          <button class="btn-primary" (click)="submitCheckboxes()">Save</button>
        </div>
      </div>
      <div bulkActions *ngIf="selectedRows && bulkIsEnable && selectedRows?.length" class="flex items-center">
        <lib-dropdown
          [actions]="gridHeader.bulkActions"
          name="Bulk actions"
          (handleBulkActionClick)="bulkActionClick($event)"
          xPosition="right"
        ></lib-dropdown>
        <div class="flex items-center ml-4">
          <span class="font-medium text-sm text-gray-700">{{ selectedRows.length }} selected records</span>
          <span
            (click)="clearBulk()"
            class="ml-1 cursor-pointer"
            [innerHTML]="svgIcons.delete2 | safe"
          ></span>
        </div>
      </div>
    </lib-grid-header>
    <div [hidden]="showEmpty">
      <ag-grid-angular
        #agGrid
        [style.height]="shortMode ? '31vh' : '75vh'"
        style="width: 100%"
        class="grid my-grid ag-theme-alpine"
        [class]="classes"
        (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions"
        [sideBar]="sideBar"
        [rowData]="rowData"
        [columnDefs]="gridColumns"
        [defaultColDef]="defaultColDef"
        [rowDragManaged]="true"
        [serverSideStoreType]="serverSideStoreType"
      ></ag-grid-angular>
    </div>
    <!-- <lib-grid-footer [total]="total"></lib-grid-footer> -->
  </div>
</ng-container>

<ng-container *ngIf="showEmpty">
  <lib-empty-results-action
    *ngIf="!filterExist"
    [title]="'The list is empty at the moment'"
    [description]="'Please import your first entry'"
    [btnName]="getEmptyBtnName()"
    [svgIcon]="emptyResults?.icon"
    [btnIsVisible]="emptyResults?.permission"
    (emptyButtonAction)="emptyResultsActionClick()"
  >
  </lib-empty-results-action>

  <lib-empty-results-action
    *ngIf="filterExist"
    [title]="'No search results'"
    [description]="'Change or reset the filters'"
    [btnName]="'Reset filters'"
    [svgIcon]="svgIcons?.directoryDelete"
    [btnIsVisible]="true"
    (emptyButtonAction)="resetEmptyFilters()"
  >
  </lib-empty-results-action>
</ng-container>

<ng-template #errorContainer>
  <lib-empty-results-action
    [title]="'Service setup status'"
    [description]="error.message"
    [svgIcon]="svgIcons?.cog"
    [displayBorder]="true"
    [btnIsVisible]="false"
    [classes]="'my-error'"
    [isError]="true"
  >
  </lib-empty-results-action>
</ng-template>
