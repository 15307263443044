export class KycConnectionModel {
  account: {
    name: string;
    status: string;
    balance: {
      amount: string;
      currency: string;
    };
  };
}
