import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { BaseOrgModel } from './base-org-model.class';

@Exclude()
export class BaseOrgAuditModel extends BaseOrgModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  createdBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  lastModifiedBy: string;

  constructor(data: Partial<BaseOrgAuditModel>) {
    super(data);
    Object.assign(this, data);
  }
}
