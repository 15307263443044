import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';

@Exclude()
export class InvitationUserModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  id?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  profileId: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  firstName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  lastName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  email: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  locale: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  language: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  timezone: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isActive?: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  userRoleId?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  title?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  street?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  city?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  state?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  postalCode?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  companyName?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  department?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  division?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  phone?: Nullable<Nullable<string>>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fax?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mobilePhone?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  extension?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  employeeNumber?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  photoUrl?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  managerId?: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  rowVersion?: number;

  constructor(invitationUser: Partial<InvitationUserModel>) {
    Object.assign(this, invitationUser);
  }
}
