import { AccountTypeResponse } from './API/account-type-response.class';
import { Type } from 'class-transformer';
import { OrganizationModel } from '../organization/organization-model.class';
import { WorkspaceModel } from '../workspace/workspace-model.class';
import { PlatformServerModel } from '../trading-manager/platform-server/platform-server.model';

export class AccountType extends AccountTypeResponse {
  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Type(() => WorkspaceModel)
  workspace?: WorkspaceModel;

  @Type(() => PlatformServerModel)
  server?: PlatformServerModel;

  country?: string;
}
