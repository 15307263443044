<mat-toolbar>
  <a [routerLink]="'/'">
    <img *ngIf="!collapse" src="assets/img/logo-white.png" class="logo" alt="logo">
  </a>

  <div class="collapse-toggle content-end	">
    <button *ngIf="!collapse" class="collapse-toggle" mat-icon-button (click)="toggle(true)">
      <mat-icon class="icon">keyboard_arrow_left</mat-icon>
    </button>
    <button *ngIf="collapse" class="collapse-toggle" mat-icon-button (click)="toggle(false)">
      <mat-icon class="icon">menu</mat-icon>
    </button>
  </div>
</mat-toolbar>

<!-- Menu Items -->
<ng-scrollbar class="side-menu scrollbar-menu">
  <div *ngFor="let menu of menus"
       class="flex flex-col"
       matTooltipPosition="right">
    <!-- Condition on link element. Used to apply dropdown menu listener -->
    <a (click)="this.toggleMenu(menu)"
       *ngIf="!collapse && !menu.isHeading && menu.isVisible"
       [matTooltip]="collapse ? menu.label : ''"
       [routerLink]="menu.route"
       [routerLinkActive]="menu.route ? 'active' : ''"
       class="item flex flex-row"
       matRipple
    >
      <mat-icon *ngIf="menu.hasSubmenu && this.arrowOnLeft"
                class="icon-submenu"
                color="primary">{{menu.isOpen ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>
      <div class="item-container">
        <mat-icon class="icon">{{ menu.icon }}</mat-icon>
        <span *ngIf="!collapse" class="name">{{ menu.label }}</span>
        <mat-icon *ngIf="menu.hasSubmenu && !this.arrowOnLeft"
                  class="icon-submenu right-side-icon material-icons">{{menu.isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
      </div>
    </a>

    <a *ngIf="collapse && !menu.isHeading && menu.hasSubmenu && menu.isVisible"
       [matMenuTriggerFor]="subMenu"
       [matTooltip]="collapse ? menu.label : ''"
       [routerLink]="menu.route"
       class="item flex flex-row"
       matRipple
       matTooltipPosition="right">
      <div class="item-container">
        <mat-icon class="icon" color="primary">{{ menu.icon }}</mat-icon>
        <span *ngIf="!collapse" class="name">{{ menu.label }}</span>
      </div>
    </a>
    <a *ngIf="collapse && !menu.isHeading && !menu.hasSubmenu && menu.isVisible"
       [matTooltip]="collapse ? menu.label : ''"
       [routerLink]="menu.route"
       [routerLinkActive]="'active'"
       class="item flex flex-row"
       matRipple
       matTooltipPosition="right">
      <div class="item-container">
        <mat-icon class="icon" color="primary">{{ menu.icon }}</mat-icon>
        <span *ngIf="!collapse" class="name">{{ menu.label }}</span>
      </div>
    </a>

    <div *ngIf="menu.isHeading && !collapse" class="heading-item flex flex-row">
      <label class="text-xs opacity-40">{{menu.label | uppercase}}</label>
    </div>
    <!-- Submenu items -->
    <div *ngIf="!collapse && menu.isOpen">
      <div *ngFor="let submenu of menu.submenu"
           class="submenu flex flex-col justify-start	">
        <a *ngIf="submenu.isVisible" [matTooltip]="collapse ? submenu.label : ''"
           [routerLink]="submenu.route"
           class="sub-item flex flex-row"
           matTooltipPosition="right"
           routerLinkActive="active">
          <mat-icon class="submenu-item-icon">{{ submenu.icon }}</mat-icon>
          <span class="name">{{submenu.label}}</span></a>
      </div>
    </div>
    <!-- Dropdown Menu -->
    <mat-menu #subMenu="matMenu" [overlapTrigger]="false" backdropClass="custom__menu" class="sidemenu-dropdown">
      <ng-container *ngFor="let hmenu of menu.submenu">
        <a *ngIf="hmenu.isVisible" [routerLink]="hmenu.route" class="sub-item" mat-menu-item>
          <mat-icon class="icon">{{hmenu.icon}}</mat-icon>
          <span class="name">{{hmenu.label}}</span>
        </a>
      </ng-container>
    </mat-menu>
    <span></span>
  </div>
</ng-scrollbar>
