<ng-select
  [items]="currencies | async"
  bindLabel="name"
  bindValue="id"
  [multiple]="multiple"
  [clearable]="clearable"
  [placeholder]="placeholder"
  [(ngModel)]="selected"
  [class]="'my-selector'"
  [searchFn]="filterCurrencies"
  (change)="selectionChanged($event)">

  <ng-template ng-label-tmp let-item="item" let-clear="clear">

    <!--Single Selection -->
    <ng-container *ngIf="!multiple">
      <div class="label-tmp">
        <span class="name"> {{item.shortCode}} - {{item.name}}</span>
      </div>
    </ng-container>

    <!--Multiple Selection -->
    <ng-container *ngIf="multiple">
      <div class="multi-label-tmp">
        <span class="name"> {{item.shortCode}}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
    </ng-container>


  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="option-tmp">
      <span class="name"> {{item.shortCode}} - {{item.name}}</span>
    </div>
  </ng-template>


</ng-select>
