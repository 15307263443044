import { AllowedAction } from '@lib/interfaces';
import { createAction, props } from '@ngrx/store';
import { OrganizationUser } from '@lib/models/logged-user/logged-user-models/organization-user.model';
import { ShortWorkspace } from '@lib/models/logged-user/logged-user-models/short-workspace.model';
import { WorkspaceUserMinimal } from '../../auth/shared//logged-user-models/workspace-user.model';
import { PlatformUser } from '../../auth/shared/logged-user-models/platform-user.model';
import { LoggedUser } from '../../auth/shared/logged-user.model';
import { ShortOrganization } from '../../auth/shared/short-organization.class';
import { IAuthState } from '../models';

const updateSate = createAction('[Auth] Update state', props<{ auth: IAuthState }>());

const setLoggedUser = createAction('[Auth] Logged User', props<{ loggedUser: LoggedUser }>());

const getOrganizationUser = createAction('[Auth] Get organization user');
const setOrganizationUser = createAction(
  '[Auth] Set organization user',
  props<{ organizationUser: OrganizationUser }>(),
);

const setOrganizations = createAction(
  '[Auth] Set organizations',
  props<{ organizations: ShortOrganization[] }>(),
);

const getPlatformUser = createAction('[Auth] Get platform user');
const setPlatformUser = createAction('[Auth] Set platform user', props<{ user: PlatformUser }>());

const getWorkspaces = createAction('[Auth] Get workspaces');
const setWorkspaces = createAction('[Auth] Set workspaces', props<{ workspaces: WorkspaceUserMinimal[] }>());

const getWorkspace = createAction('[Auth] Get selected workspace');
const setWorkspace = createAction(
  '[Auth] Set selected workspace',
  props<{ workspace: WorkspaceUserMinimal }>(),
);

const getWorkspaceID = createAction('[Auth] Get selected workspace ID');
const setWorkspaceID = createAction('[Auth] Set selected workspace ID', props<{ workspaceID: string }>());

const getWsUserActions = createAction('[Auth] Get workspace user`s actions');
const setWsUserActions = createAction(
  '[Auth] Set workspace user`s actions',
  props<{ actions: AllowedAction[] }>(),
);

const switchWorkspace = createAction('[Auth] Switch workspace', props<{ workspace: ShortWorkspace }>());

export const AuthActions = {
  updateSate,
  setWorkspaces,
  getWorkspaces,
  setWorkspace,
  getWorkspace,
  getWorkspaceID,
  setWorkspaceID,
  getWsUserActions,
  setWsUserActions,
  getPlatformUser,
  setPlatformUser,
  getOrganizationUser,
  setOrganizationUser,
  setLoggedUser,
  setOrganizations,
  switchWorkspace,
};
