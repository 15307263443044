import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';
import { Breadcrumb } from './breadcrumbs.class';

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.generateBreadcrumbs();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => this.generateBreadcrumbs());
  }

  private generateBreadcrumbs(): void {
    this.breadcrumbs = [];

    let url: string = '';
    let currentRoute: ActivatedRoute = this.activatedRoute.root;

    do {
      const routeURL: string = currentRoute.snapshot.url
        .map(segment => segment.path)
        .filter(v => !!v)
        .join('/');

      url += routeURL.length ? '/' + routeURL : '';

      const hasData = (currentRoute.routeConfig && currentRoute.routeConfig.data);
      const hasDynamicBreadcrumb: boolean = currentRoute.snapshot.params.hasOwnProperty('breadcrumb');

      if (hasData || hasDynamicBreadcrumb) {
        const breadCrumbLabel = hasDynamicBreadcrumb
          ? currentRoute.snapshot.params['breadcrumb'].replace(/_/g, ' ')
          : currentRoute.snapshot.data['breadcrumb'];

        const breadcrumb: Breadcrumb = {url, label: breadCrumbLabel};

        // remove last breadcrumb if the url is the same as the new breadcrumb
        const last = this.breadcrumbs.slice(-1).pop();
        if (last && last.url === breadcrumb.url) {
          this.breadcrumbs.pop();
        }

        this.breadcrumbs.push(breadcrumb);
      }

      const childrenRoutes: ActivatedRoute[] = currentRoute.children;
      currentRoute = childrenRoutes.find(route => route.outlet === PRIMARY_OUTLET);
    } while (currentRoute);
  }
}
