import { Exclude, Expose, Type } from 'class-transformer';
import { CampaignModel } from '../index';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';

@Exclude()
export class CampaignStatusModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.CREATE] })
  campaignId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.CREATE] })
  status: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  hasResponded: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  isDefault: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => CampaignModel)
  campaign?: CampaignModel;

  constructor(campaignStatus: Partial<CampaignStatusModel>) {
    super(campaignStatus);
    Object.assign(this, campaignStatus);
  }
}
