import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { BaseFormComponent } from '../../../../../lib/src/classes/base-form-component.class';
import { ToastService } from '../../../../../lib/src/services/toast/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseFormComponent {

  constructor(private authService: AuthService,
              private toastService: ToastService,
              private router: Router) {

    super();
    this.createForm();
  }

  createForm(): void {
    this.formFields = [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'Email',
          mask: 'email',
          placeholder: 'Email',
          required: true,
        },
        validators: {
          validation: Validators.compose([Validators.required, Validators.email])
        }
      },
    ];
  }

  save(): void {
    this.setSaving(true);
    const username = this.form.get('username').value;
    this.subscription = this.authService.forgotPassword(username).subscribe(() => {
        this.setSaving(false);
        this.toastService.showSuccess('New verification code have been sent to ' + username);
        this.router.navigate(['auth', 'reset-password', username]).then();
      },
      error => {
        this.toastService.showError(error.error.message);
        this.setSaving(false);
      });
  }

}
