import { SafeHtml } from '@angular/platform-browser';

type ActionType =
  | 'btn-white'
  | 'btn-primary'
  | 'btn-light'
  | 'btn-secondary'
  | 'btn-warning'
  | 'btn-danger'
  | 'btn-error'
  | 'btn-light-primary-light'
  | 'btn-light-primary-warning'
  | 'btn-light-success'
  | 'btn-light-info'
  | 'btn-light-warning'
  | 'btn-light-danger'
  | 'btn-light-error'
  | 'btn-light-primary'
  | 'btn-light-to-primary'
  | 'btn-group';

export class QuickAction {
  key?: string;
  name?: string;
  label?: string;
  type?: ActionType = 'btn-primary';
  dropdownActions?: QuickAction[];
  rowActions?: QuickAction[];
  permission: boolean;
  selected?: boolean;
  disable?: boolean;
  classes?: string;
  icon?: SafeHtml;

  constructor(quickAction: QuickAction) {
    Object.assign(this, quickAction);
  }
}
