import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvgIcons } from '../../../../assets/svgs.enum';
import { BaseFormComponent } from '../../../../classes/base-form-component.class';

class Obj {
  viewName?: string;
}

@Component({
  selector: 'lib-grid-header-edit-mode',
  templateUrl: './grid-header-edit-mode.component.html',
  styleUrls: ['./grid-header-edit-mode.component.scss']
})
export class GridHeaderEditModeComponent extends BaseFormComponent implements OnInit {

  svgIcons = SvgIcons;
  obj: Obj = new Obj();

  @Input() name?: string;
  @Output() exitEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submission: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!!this.name) {
      this.obj.viewName = this.name;
    }
    this.createForm();
  }

  clickBack(): void {
    this.exitEditMode.emit(true);
  }

  createForm(): void {
    this.formFields = [
      {
        key: 'viewName',
        type: 'my-input',
        wrappers: ['horizontal'],
        focus: true,
        templateOptions: {
          placeholder: 'Grid view name',
          required: true,
          hideLabelColumn: true
        }
      },
    ];
  }


  save(): void {
    this.submission.emit(this.obj.viewName);
    this.exitEditMode.emit();
  }

}
