import { Nullable } from '../../../types';

export class ShortWorkspace {
  id: string;
  orgId: string;
  name: string;
  createdDate: Date;
  phone?: Nullable<string>;
  fax?: Nullable<string>;

  constructor(shortWorkspace: ShortWorkspace) {
    Object.assign(this, shortWorkspace);
  }
}
