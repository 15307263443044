import { createFeatureSelector, createSelector } from '@ngrx/store';
import { tradingManagerKey } from '../constants';
import { ITradingManagerState } from '../models';

const selector = createFeatureSelector<ITradingManagerState>(tradingManagerKey);
const platformServer = createSelector(selector, (state: ITradingManagerState) => state.platformServer);
const questionnaire = createSelector(selector, (state: ITradingManagerState) => state.questionnaire);
const accountType = createSelector(selector, (state: ITradingManagerState) => state.accountType);
const accountTypeForm = createSelector(selector, (state: ITradingManagerState) => state.accountTypeForm);
const accountTypeAssignedGroups = createSelector(
  selector,
  (state: ITradingManagerState) => state.platformGroups,
);
const questionnairePickList = createSelector(
  selector,
  (state: ITradingManagerState) => state.questionnairePickList,
);

export const fromTradingManager = {
  platformServer,
  questionnaire,
  accountType,
  accountTypeForm,
  accountTypeAssignedGroups,
  questionnairePickList,
};
