import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types/nullable.type';
import { AccountModel } from '../account-model/account-model';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { TradingAccountModel } from '../trading-manager/trading-account/trading-account.model';
import { TransactionMethodEnum, TransactionStatusPayment, TransactionTypeEnum } from '@org/common-cross-lib';

@Exclude()
export class TransactionModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  tradingAccountId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  transactionType: TransactionTypeEnum;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  processedCurrencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  amount: number;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  accountId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  transactionMethod: TransactionMethodEnum;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  transactionStatus: TransactionStatusPayment;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  currencyIsoCode: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  conversionRate: number;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  processedAmount: number;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  paymentMethod: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  gateway: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  externalPspTransactionId: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  pspTransactionId: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  parentTransactionId: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => TransactionModel)
  referenceTransaction?: TransactionModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountModel)
  account?: AccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => TradingAccountModel)
  tradingAccount?: TradingAccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  pspTransaction?: any;
}
