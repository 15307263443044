import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from './base-component.class';
import { PickList, PickListValue, PickValue, FormlyPickValue } from '../models';
import { map, Observable, of, zip } from 'rxjs';
import { AfterViewInit, Directive } from '@angular/core';
import { goBack } from '../helpers/helpers';

@Directive()
export abstract class BaseFormComponent extends BaseComponent implements AfterViewInit {
  entityName: string;
  pickList$: Observable<PickList>;
  pickLists$: Observable<PickList>[];

  resetAndCreateNew: boolean;

  form = new FormGroup({});
  model: any;
  saving: boolean = false;
  options: FormlyFormOptions = {};
  formFields: FormlyFieldConfig[] = [];

  private defPickValues: FormlyPickValue[];

  ngAfterViewInit(): void {
    if (this.pickList$) {
      this.pickList$.subscribe((res) => {
        !!res && this.defValues(res);
      });
    }
  }

  public cancel(): void {
    goBack();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    this.save();
  }

  update(): void {
    this.model = Object.assign({}, this.model);
  }

  abstract save(): void;

  getContent(isUpdate: boolean): Record<string, string> {
    return isUpdate
      ? {
          title: `Update ${this.entityName.toLowerCase()}`,
          description: `Edit this ${this.entityName.toLowerCase()}`,
          toastResponse: `<b>Done</b>: ${this.entityName} updated`,
        }
      : {
          title: `Create ${this.entityName.toLowerCase()}`,
          description: `Add a new ${this.entityName.toLowerCase()}`,
          toastResponse: `<b>Done</b>: ${this.entityName} created`,
        };
  }

  resetForm(): void {
    this.resetAndCreateNew = false;
    this.form.reset();
  }

  // tslint:disable-next-line:cognitive-complexity
  findField(fieldId: string, formFields: FormlyFieldConfig[] = this.formFields): FormlyFieldConfig {
    let field = null;
    formFields.forEach((item) => {
      let keyParts = [];
      if (item.key && typeof item.key === 'string') {
        keyParts = item.key.split('.');
      }

      if (item.fieldGroup && item.fieldGroup.length > 0) {
        if (!field) {
          field = this.findField(fieldId, item.fieldGroup);
        }
      } else {
        if (
          !field &&
          (item.key === fieldId || (keyParts.length && keyParts[keyParts.length - 1] === fieldId))
        ) {
          field = item;
        }
      }
    });

    return field;
  }

  findFieldByLabel(label: string, formFields: FormlyFieldConfig[]): FormlyFieldConfig {
    return formFields.find((item) => {
      return item.templateOptions.label === label;
    });
  }

  getFieldOptions(field: string): Observable<any> {
    const options = this.findField(field, this.formFields).templateOptions.options;
    return Array.isArray(options) ? of(options) : (options as Observable<any>);
  }

  getFieldOptionModel(field: string, value: unknown): Observable<any> {
    return this.getFieldOptions(field).pipe(
      map((items: object[]) => items.find((item) => item[field] === value)),
    );
  }

  disableForm(formFields: FormlyFieldConfig[]) {
    formFields.forEach((item) => {
      if (item.templateOptions) {
        item.templateOptions.disabled = true;
      }
    });
  }

  getFormKeys(formFields: FormlyFieldConfig[]): string[] {
    const fields = [];

    formFields.forEach((item) => {
      if (item.fieldGroup && item.fieldGroup.length > 0) {
        fields.push(...this.getFormKeys(item.fieldGroup));
      } else {
        if (typeof item.key !== 'undefined') {
          fields.push(item.key);
        }
      }
    });

    return fields;
  }

  setSaving(saving: boolean): void {
    this.saving = saving;
  }

  handleComplete(): void {
    this.setLoading(false);
    this.setSaving(false);
  }

  pick(key: string, exclude?: string, validFor?: string): Observable<PickListValue[]> {
    if (!this.pickList$) {
      console.log('>> Developer Error: You forgot to initialize a pick-list -' + this.entityName);
      console.warn('>> Developer Error: You forgot to initialize a pick-list -' + this.entityName);
      console.error('>> Developer Error: You forgot to initialize a pick-list -' + this.entityName);
    }

    return this.pickList$.pipe(
      map((res: PickList) => {
        const list = !!res
          ? !!exclude
            ? res[key].values.filter((val: PickListValue) => val.value !== exclude)
            : res[key].values
          : [];

        return !!validFor && list.length ? list.filter((v) => v.validFor === validFor) : list;
      }),
    );
  }

  pickBy(key: string, entity: string, validFor?: string): Observable<PickListValue[]> {
    return zip(this.pickLists$).pipe(
      map((res: PickList[]) => {
        const found = res.find((item: PickList) => !!item && item[key] && item[key].entity === entity);

        return !!found
          ? !!validFor && found[key].values
            ? found[key].values.filter((v) => v.validFor === validFor)
            : found[key].values
          : [];
      }),
    );
  }

  pickBypassing(key: string, pickList: Observable<PickList>): Observable<PickListValue[]> {
    return pickList.pipe(
      map((res: PickList) => {
        return !!res ? res[key].values : [];
      }),
    );
  }

  defValues(pickList: PickList): void {
    const keys: string[] = Object.keys(pickList);
    const values: PickValue[] = Object.values(pickList);
    const convertedPickList = keys.map((key: string, index: number) => ({
      key,
      definition: values[index],
    }));

    this.defPickValues = convertedPickList.filter((list: FormlyPickValue) => list.definition.defaultValue);
    this.formFields.map((formField) => this._updateFieldValue(formField));
  }

  public detectPickList = (field: FormlyFieldConfig, relatedFieldKey: string, pickList: string) =>
    this.detectPickListChanges(field, relatedFieldKey, pickList);

  public detectPickListChanges(
    field: FormlyFieldConfig,
    relatedFieldKey: string,
    pickList: string,
    pickBy?: string,
  ) {
    const relatedField = field.form.get(relatedFieldKey);
    this.subscription = relatedField.valueChanges.subscribe((val) => {
      field.formControl.setValue(null);
      field.templateOptions.options = val
        ? !!pickBy
          ? this.pickBy(pickList, pickBy, val)
          : this.pick(pickList, undefined, val)
        : [];
    });
  }

  private _updateFieldValue(formlyField: FormlyFieldConfig): void {
    const _defField = this.defPickValues.find((defVal: FormlyPickValue) => defVal.key === formlyField.key);
    _defField && formlyField.formControl.setValue(_defField.definition.defaultValue);

    formlyField.fieldGroup &&
      formlyField.fieldGroup.length &&
      formlyField.fieldGroup.map((childField: FormlyFieldConfig) => this._updateFieldValue(childField));
  }
}
