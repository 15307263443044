import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types';
import { BaseOrgWorkspaceAuditModel } from '../../base-models/base-org-workspace-audit-model.class';

@Exclude()
export class QuestionnaireModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isDefault: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  extFormId: string;
}
