<div class="flex flex-col relative items-center" [class.has-error]="showError">
  <input
    [autocomplete]="to.autocomplete || 'password'"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [id]="id"
    [type]="hide ? 'password' : 'text'"
    [formlyAttributes]="field"
    [readonly]="to.readonly"
    class="password-hide-input w-full"
    [tabindex]="to.tabindex || 0"
    [required]="required"
  />

  <div class="password-hide-button">
    <button
      (click)="hide = !hide"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide"
      class=" "
      mat-icon-button
      matSuffix
      type="button"
    >
      <mat-icon class="password-hide-icon" [style.fontSize]="'15px'">{{
        hide ? 'visibility_off' : 'visibility'
      }}</mat-icon>
    </button>
  </div>

  <div class="input-error">
    <formly-validation-message *ngIf="showError && !field.wrappers.length" [field]="field">
    </formly-validation-message>
  </div>
</div>
