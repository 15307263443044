import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SvgIcons } from '@lib/assets/svgs.enum';
import { ShortOrganization } from '@lib/models/logged-user/logged-user-models/short-organization.class';
import { RouterConstant } from '../../core/route-constants';
import { AuthService } from '../shared/auth.service';
import { PlatformUser } from '../shared/logged-user-models/platform-user.model';
import { WorkspaceUserMinimal } from '../shared/logged-user-models/workspace-user.model';
import { OrganizationUser } from '../shared/organization-user.model';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.scss'],
})
export class SelectOrganizationComponent {
  public user: PlatformUser;
  public workspaces: WorkspaceUserMinimal[];

  public selectedOrgWorkspaces: WorkspaceUserMinimal[];
  public selectedOrgUser: OrganizationUser;
  public selectedOrg: ShortOrganization;

  public svgIcons = SvgIcons;

  constructor(private authService: AuthService, private router: Router) {
    this._loadUserAccount();
  }

  handleOrgSelection(orgUser: OrganizationUser): void {
    const workspaces: WorkspaceUserMinimal[] = this.authService.getWorkspaces();
    this.selectedOrgWorkspaces = workspaces.filter((w) => w.orgId === orgUser.orgId);
    this.selectedOrg = this.selectedOrgWorkspaces[0].organization;
    this.selectedOrgUser = orgUser;
  }

  chooseWorkspace(workspace: WorkspaceUserMinimal): void {
    this.authService.selectWorkspace(workspace);
    this.router.navigate([RouterConstant.home.routePath]).then();
  }

  avatar(str: string): string {
    const matches = str.match(/\b(\w)/g);
    return matches.join('');
  }

  backToOrgList(): void {
    this.selectedOrg = null;
    this.selectedOrgUser = null;
    this.selectedOrgWorkspaces = [];
  }

  logOut(): void {
    this.authService.logout();
  }

  private _loadUserAccount(): void {
    this.user = this.authService.getUser();
    const workspaces = this.authService.getWorkspaces();
    this.workspaces = [...new Map(workspaces.map((item) => [item.orgId, item])).values()];
  }
}
