import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent extends FieldType {
  get placeholder(): string {
    return this.to.placeholder;
  }

  get label(): string {
    return this.to.label;
  }

  get required(): boolean {
    return this.to.required;
  }

  get wrapper(): string {
    return this.field.wrappers[0];
  }
}
