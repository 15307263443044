import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { Nullable } from '../../types/nullable.type';
import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';
import { UserLicenseModel } from '../user-license/user-license-model.class';

@Exclude()
export class ProfileModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  userLicenseId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  isCustom: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  userLicense: UserLicenseModel;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  description: Nullable<string>;

  constructor(data: Partial<ProfileModel>) {
    super(data);
    Object.assign(this, data);
  }
}
