import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-tag-input',
  template: `
    <lib-tag-input [required]="required"
                   [placeholder]="placeholder"
                   [formControl]="formControl">
    </lib-tag-input>
  `
})
export class TagInputComponent extends FieldType {

  get required() {
    return this.to.required;
  }

  get placeholder() {
    return this.to.placeholder;
  }
}
