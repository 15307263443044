import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Input, Renderer2, ViewContainerRef } from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatIcon} from '@angular/material/icon';


@Directive({
  selector: '[libSpinBtn]'
})
export class SpinBtnDirective {

  @Input() set libSpinBtn(val: boolean) {
    if (val) {
      this.startSpinner();
    } else {
      this.stopSpinner();
    }
  }

  private element: HTMLButtonElement;
  private spinnerRef: ComponentRef<MatProgressSpinner>;
  private spinnerContainer: ComponentRef<MatIcon>;

  constructor(elRef: ElementRef,
              private resolver: ComponentFactoryResolver,
              public vcRef: ViewContainerRef,
              private renderer: Renderer2) {
    this.element = elRef.nativeElement;
  }

  startSpinner() {
    // Create components
    const spinnerFactory = this.resolver.resolveComponentFactory(MatProgressSpinner);
    this.spinnerRef = this.vcRef.createComponent(spinnerFactory);
    const iconFactory = this.resolver.resolveComponentFactory(MatIcon);
    this.spinnerContainer = this.vcRef.createComponent(iconFactory);

    // Configure spinner
    this.spinnerRef.instance.diameter = 20;
    this.spinnerRef.instance.mode = 'indeterminate';
    this.spinnerRef.instance.color = 'accent';

    this.renderer.appendChild(
      this.spinnerContainer.location.nativeElement,
      this.spinnerRef.location.nativeElement
    );

    const btnWrapper = this.element.getElementsByClassName('mat-button-wrapper')[0];

    this.renderer.appendChild(
      btnWrapper,
      this.spinnerContainer.location.nativeElement
    );

    this.element.disabled = true;
  }

  stopSpinner() {

    if (!this.spinnerContainer) {
      return;
    }

    setTimeout(() => {

      const btnWrapper = this.element.getElementsByClassName('mat-button-wrapper')[0];
      this.renderer.removeChild(
        btnWrapper,
        this.spinnerContainer.location.nativeElement
      );
      this.element.disabled = false;
    }, 600);
  }
}
