import { createFeatureSelector, createSelector } from '@ngrx/store';
import { workspaceKey } from '../constants';
import { IWorkspaceState } from '../models';

const selectedWorkspaceState = createFeatureSelector<IWorkspaceState>(workspaceKey);

const selectedWorkspace = createSelector(selectedWorkspaceState, (state: IWorkspaceState) => state.workspace);

const selectedWorkspaceUser = createSelector(
  selectedWorkspaceState,
  (state: IWorkspaceState) => state.workspaceUser,
);

const existingOrgUser = createSelector(
  selectedWorkspaceState,
  (state: IWorkspaceState) => state.inviteExistingUser,
);

const selectedWorkspaceUserPickList = createSelector(
  selectedWorkspaceState,
  (state: IWorkspaceState) => state.workspaceUserPickList,
);

const permissionSet = createSelector(selectedWorkspaceState, (state: IWorkspaceState) => state.permissionSet);

const getRoleMoveMode = createSelector(
  selectedWorkspaceState,
  (state: IWorkspaceState) => state.isRoleMoveMode,
);
const getSite = createSelector(selectedWorkspaceState, (state: IWorkspaceState) => state.site);

const getRole = createSelector(selectedWorkspaceState, (state: IWorkspaceState) => state.roleMove);
const reqRoleUpdate = createSelector(selectedWorkspaceState, (state: IWorkspaceState) => state.reqRoleUpdate);
const getRoleParent = createSelector(
  selectedWorkspaceState,
  (state: IWorkspaceState) => state.roleMoveParent,
);

const selectedWorkspaceUserAssignedPermissionSets = createSelector(
  selectedWorkspaceState,
  (state: IWorkspaceState) => state.workspaceUserAssignedPermissionSets,
);

export const fromWorkspace = {
  selectedWorkspaceState,
  selectedWorkspace,
  selectedWorkspaceUser,
  selectedWorkspaceUserPickList,
  selectedWorkspaceUserAssignedPermissionSets,
  existingOrgUser,
  permissionSet,
  getRoleMoveMode,
  getRole,
  getRoleParent,
  reqRoleUpdate,
  getSite,
};
