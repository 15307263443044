/* tslint:disable:no-unused-expression */
import { ICellRenderer } from 'ag-grid-community';
import { CellRendererClickHandler } from '../shared/cell-click-params.interface';
import { DropdownButton } from '../../buttons/dropdown/shared/dropdown-button';

const BUTTON_SELECTOR = '.select .dropdown a';

export default class DropdownActions implements ICellRenderer {
  public eGui: any;
  public params: CellRendererClickHandler;

  public init(params: CellRendererClickHandler): void {
    this.params = params;

    this.eGui = params.data ? this.generateTemplate(params) : undefined;
    params.data && this.addListeners();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: CellRendererClickHandler): any {
    this.params = params;
    this.eGui = this.generateTemplate(params);
  }

  public generateTemplate = (params: CellRendererClickHandler): HTMLElement => {
    const { buttons, data } = params;
    const eGui = document.createElement('div');
    eGui.classList.add('flex');

    const compileButtons = buttons.map((btn) => (!!btn?.condition ? { ...btn, name: data[btn.condition.key] ? btn.condition.truthyLabel : btn.condition.falsyLabel } : btn));

    eGui.innerHTML = `
      <div class="select relative inline-block text-left ">
        <div class="flex items-center">
          <button
          type="button"
          class="inline-flex justify-center w-full flex items-center focus:outline-none "
          aria-expanded="true"
          aria-haspopup="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
            <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="#a1a5b7"/>
            <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="#a1a5b7"/>
          </svg>
          </button>
        </div>

        <div class="dropdown origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1" role="none">
           ${renderButtons(compileButtons)}
          </div>
        </div>
      </div>
    `;
    return eGui;
  };

  public addListeners(): void {
    this.clickedHandler = this.clickedHandler.bind(this);
    const buttons = this.eGui.querySelectorAll(BUTTON_SELECTOR);

    buttons.forEach((button) => button.addEventListener('click', this.clickedHandler));
  }

  public clickedHandler(event: PointerEvent): void {
    const key: string = (event.target as HTMLElement).id;
    this.params.clicked &&
      this.params.clicked({
        key,
        data: this.params.data,
        gridRowIndex: this.params.node.getRowIndexString()
      });
  }

  public destroy(): void {
    !!this.eGui && this.eGui.querySelectorAll(BUTTON_SELECTOR).forEach((button) => button.addEventListener('click', this.clickedHandler));
  }
}

const renderButtons = (buttons: DropdownButton[]): string => {
  return buttons.map((btn: DropdownButton) => renderButton(btn)).join('');
};

const renderButton = (btn: DropdownButton): string => `
   <a  class="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
    role="menuitem"
    tabindex="-1"
    id="${btn.key}">${btn.name}</a>
`;
