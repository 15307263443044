import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PhoneNumber } from '../shared/phone/phone-number';
import { PhoneNumber as libPhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import { Country } from '../country-picker/shared/country.class';
import { GeoService } from '../shared/phone/geo.service';
import { CountryService } from '../country-picker/shared/country.service';
import { BaseComponent } from '../../classes/base-component.class';

@Component({
  selector: 'lib-third-phone',
  templateUrl: './third-phone.component.html',
  styleUrls: ['./third-phone.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ThirdPhoneComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ThirdPhoneComponent)
    }
  ]
})
export class ThirdPhoneComponent extends BaseComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() formControl: FormControl;
  @Input() formlyAttributes: any;
  @Input() labelForId: string;
  @Input() placeholder: string = '';
  @Input() autodetect: boolean = false;
  @Input() validatePhone: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() errorRef: TemplateRef<any>;
  @Input()
  hideLocalErrorDetection: boolean;


  @Input() useSvg = false;

  @ViewChild('phoneCodeSelect', {static: false}) phoneCodeSelect: NgSelectComponent;
  @ViewChild('filterInput', {static: false}) filterInput;
  @ViewChild('phoneInput', {static: false}) phoneInput;

  phonePrefix: string = '';

  model: PhoneNumber = new PhoneNumber();
  phoneParser = PhoneNumberUtil.getInstance();
  countries: Country[] = [];
  countriesFiltered: Country[] = [];
  searchTerm = new EventEmitter<string>();

  constructor(private geoService: GeoService,
              private cdRef: ChangeDetectorRef,
              private countryService: CountryService) {
    super();
  }

  get phoneNumber(): string {
    if (this.model.number && !this.model.number.includes('+')) {
      return this.phonePrefix + this.model.number;
    }

    if (this.model.number && this.model.number.includes(this.phonePrefix)) {
      return this.model.number;
    }

    if (this.model.rawNumber) {
      return this.phonePrefix + this.model.rawNumber;
    } else {
      return this.phonePrefix;
    }

  }


  ngOnInit(): void {
    this.loadCountries();

    this.subscription = this.searchTerm
      .subscribe(term => {
          this.customSearchMatchCriteria(term);
        }
      );
  }

  onPrefixChange(phoneDetails: any): void {
    if (!phoneDetails || phoneDetails instanceof Event) {
      return;
    }
    this.phonePrefix = phoneDetails.dialingCode;
    this.writeValue(new PhoneNumber(this.phoneNumber, phoneDetails.code, this.phonePrefix, null));
    // focus on number field
    this.phoneInput.nativeElement.focus();
  }

  onInputChange(phone: string): void {
    this.writeValue(new PhoneNumber(phone, this.model.countryCode, this.model.dialingCode, null));
  }

  writeValue(value: PhoneNumber): void {
    if (!value) {
      return;
    }

    if (!value.number) {
      Object.assign(this.model, new PhoneNumber());
      // null changes
      this.propagateChange(null);
      return;
    }

    // phone information from google phone lib
    this.getPhoneDetails(value);

    this.model.number = value.number;

    this.propagateChange(new PhoneNumber(this.model.number, this.model.countryCode, this.model.dialingCode, this.model.rawNumber));

  }

  validate(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value ? control.value.number : null;

    if (!phoneNumber || !this.validatePhone) {
      return null;
    }

    try {
      if (!this.phoneParser.isValidNumberForRegion(this.phoneParser.parse(phoneNumber), control.value.countryCode)) {
        return {
          validatePhone: false
        };
      } else {
        return null;
      }
    } catch (e) {
      return {
        validatePhone: false
      };
    }
  }

  customSearchMatchCriteria(searchTerm = ''): any {
    this.countriesFiltered = this.countries
      .filter(({code = '', name = '', dialingCode = ''}) => {
        return `${code} ${name.toLowerCase()} ${dialingCode}`.includes(searchTerm.toLowerCase());
      });
  }

  onTouchedCallback = (_: any) => {
  }

  propagateChange = (_: PhoneNumber) => {
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  filterOptions(e): void {
    this.searchTerm.emit(e.target.value);
  }

  onInputBlur(event): void {
    this.onTouchedCallback(event);
  }

  switchFocus(): void {
    this.phoneCodeSelect.itemsList.setItems(this.countries);
    this.phoneCodeSelect.items = [...this.phoneCodeSelect.items];
    if (this.filterInput) {
      this.filterInput.nativeElement.focus();
    }
  }

  // get phone details from google library
  private getPhoneDetails(value: PhoneNumber): void {
    try {
      const parsedInput = this.phoneParser.parseAndKeepRawInput(value.number);

      // model values assignment
      Object.assign(this.model, {
        dialingCode: `+${parsedInput.getCountryCode().toString()}`,
        phoneNumber: parsedInput.getRawInput(),
        rawNumber: parsedInput.getNationalNumber(),
        countryCode: this.getCountryCode(value, parsedInput)
      });
    } catch (e) {
      Object.assign(this.model, new PhoneNumber(value.number, this.model.countryCode, value.dialingCode, value.rawNumber));
    }
  }

  private getCountryCode(value: PhoneNumber, parsedInput: libPhoneNumber): string {
    if (!value.countryCode) {
      return this.phoneParser.getRegionCodeForNumber(parsedInput).toLowerCase();
    }
    return value.countryCode;
  }

  private autodetectCountry(): void {

    this.subscription = this.geoService.getCountry().subscribe(geoData => {
      const code = geoData.country.toLowerCase();

      if (this.model.countryCode) {
        return;
      }
      const countryExist = this.countries.findIndex(item => item.code === code);
      if (countryExist === -1) {
        return;
      }

      this.model.countryCode = code;
      this.phonePrefix = geoData.country_calling_code;
      this.model.number = this.phoneNumber;
    });
  }

  private loadCountries(): void {
    this.countries = this.countryService.getAll();
    this.countriesFiltered = this.countries.slice();
    if (this.autodetect) {
      this.autodetectCountry();
    }
    if (!this.model.countryCode || (this.model && !this.model.dialingCode)) {
      this.writeValue(this.model);
    }
  }

}
