import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseSharingModel } from '../base-models/base-sharing.model';
import { LeadModel } from './lead.model';

@Exclude()
export class LeadShareModel extends BaseSharingModel<LeadModel> {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  leadId: string;
}
