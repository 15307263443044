import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';

export class SmtpConfigModel extends BaseOrgWorkspaceModel {
  host: string;
  port: string;
  secure: boolean;
  username: string;
  password: string;
  defaultEmail: string;
  emailSenderName: string;
  isActive: boolean;
}
