export class OrganizationUser {
  id: string;
  orgId: string;
  name: string;
  title: string | null;
  firstName: string;
  lastName: string;
  isSuperAdmin: boolean;

  createdDate: Date;
  lastLoginDate: Date | null;

  constructor(shortOrgUser: OrganizationUser) {
    Object.assign(this, shortOrgUser);
  }
}
