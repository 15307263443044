<a [routerLink]="['/']">
  <mat-icon>home</mat-icon>
  <mat-icon class="icon" *ngIf="breadcrumbs.length"> chevron_right</mat-icon>
</a>

<ng-container *ngIf="breadcrumbs.length">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <a *ngIf="!last" [routerLink]="breadcrumb.url">
      <span>{{breadcrumb.label}}</span>
      <mat-icon class="icon"> chevron_right</mat-icon>
    </a>
    <span class="last" *ngIf="last">{{ breadcrumb.label }}</span>
  </ng-container>
</ng-container>
