import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastTemplateComponent } from './toast-template/toast-template.component';
import { ToastType } from './toast-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  static DURATION: number = 7000;

  constructor(private snackBar: MatSnackBar) {}

  showError(msg: string, title?: string): void {
    this.show(ToastType.ERROR, msg, title);
  }

  showSuccess(msg: string, title?: string): void {
    this.show(ToastType.SUCCESS, msg, title);
  }

  showWarning(msg: string, title?: string): void {
    this.show(ToastType.WARNING, msg, title);
  }

  private show(type: ToastType, msg: string, title?: string): void {
    this.snackBar.openFromComponent(ToastTemplateComponent, {
      data: { type, msg, title },
      duration: ToastService.DURATION,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: 'toast-container',
    });
  }
}
