import { date } from './date';
import { userRelation } from './userRelation';
import { yesNo } from './yesNo';
import { callPhone } from './callPhone';
import Redirection from './redirection';
import DropdownActions from './dropdownActions';
import AssignUnassignedButton from './assignUnassignedButton';
import DocumentRelated from './document-related';
import Checkbox from './checkbox.cell';
import ObjectPermissionCheckbox from './objectPermissionCheckbox';
import { Numeric } from './numeric.cell';
import CampaignRelated from './campaign-related';

const CellRendering = {
  date,
  userRelation,
  yesNo,
  callPhone,
  redirection: Redirection,
  dropdownActions: DropdownActions,
  assignUnassignedButton: AssignUnassignedButton,
  documentRelated: DocumentRelated,
  campaignRelated: CampaignRelated,
  checkBox: Checkbox,
  objectPermissionCheckbox: ObjectPermissionCheckbox,
  numeric: Numeric,
};

export default CellRendering;
