import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpBackendClientService} from '../../../services/http-backend-client/http-backend-client.service';

export interface CountryResponse {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  continent_code: string;
  in_eu: boolean;
  postal: boolean;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  languages: string;
  asn: string;
  org: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  private url = 'https://ipapi.co/json/';

  constructor(private http: HttpClient,
              private httpBackendClient: HttpBackendClientService) {
  }

  getCountry(): Observable<CountryResponse> {
    return this.httpBackendClient.get<CountryResponse>(this.url).pipe(map(res => {
      return res;
    }));
  }
}
