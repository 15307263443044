import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabBarUnderlineComponent } from './tabs/tab-bar-underline/tab-bar-underline.component';
import { TabUnderlineComponent } from './tabs/tab-underline/tab-underline.component';
import { RouterModule } from '@angular/router';
import { VerticalNavigationComponent } from './vertical-navigation/vertical-navigation.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [TabBarUnderlineComponent, TabUnderlineComponent, VerticalNavigationComponent],
  exports: [TabBarUnderlineComponent, TabUnderlineComponent, VerticalNavigationComponent],
  imports: [CommonModule, RouterModule, PipesModule],
})
export class NavigationModule {}
