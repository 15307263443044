import { Directive, HostListener, Type } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseFormComponent } from '../classes/base-form-component.class';
import { ResultDialog } from '../components/dialog/dialog/shared/result-dialog.interface';
import { QuickPanelCloseStatus } from '../layout/quick-panel/shared/quick-panel-close-status.enum';

@Directive()
export abstract class BaseDialogForm<T> extends BaseFormComponent {
  responseType: Type<T>;
  dialogRef: MatDialogRef<T>;

  public isEdit: boolean;

  get label(): string {
    return this.isEdit ? 'update' : 'create';
  }

  public cancel(): void {
    this.close({ status: QuickPanelCloseStatus.CANCEL });
  }

  public close(resultDialog: ResultDialog<T>): void {
    this.dialogRef.close(resultDialog);
  }

  public confirm(resultDialog: ResultDialog<T>): void {
    this.close(resultDialog);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close({ status: QuickPanelCloseStatus.CANCEL });
  }
}
