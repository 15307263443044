import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { ContactModel } from '../contact/contact.model';
import { AccountModel } from './account-model';

@Exclude()
export class AccountContactModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  @Type(() => AccountModel)
  account: AccountModel;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  @Type(() => ContactModel)
  contact: ContactModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  accountId?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  contactId?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isDirect?: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isActive?: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  roles?: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  startDate?: Nullable<Date>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  endDate?: Nullable<Date>;

  constructor(accountContact: Partial<AccountContactModel>) {
    super(accountContact);
    Object.assign(this, accountContact);
  }
}
