import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { getProperty } from '../../../../helpers/helpers';

interface Event {
  name: string;
  id: any;
}

@Component({
  selector: 'lib-selector',
  template: `
    <ng-select
      [items]="allOptions | async"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      (clear)="onClear()"
      [disabled]="isDisabled"
      (change)="onChange($event)"
      [bindValue]="bindValue"
      [bindLabel]="bindLabel"
      class="my-selector"
    >
      <ng-template ng-label-tmp let-item="item">
        <div [innerHTML]="displayLabel(item)"></div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div [innerHTML]="displayLabel(item)"></div>
      </ng-template>
    </ng-select>
  `,
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent extends FieldType implements OnInit {
  selectedValue: string;
  bindLabel: string = 'name';
  bindValue: string = 'id';

  ngOnInit(): void {
    if (!!this.to.bindLabel) {
      this.bindLabel = this.to.bindLabel;
    }

    if (!!this.to.bindValue) {
      this.bindValue = this.to.bindValue;
    }
  }

  displayLabel(item: any): string {
    return !!item ? getProperty(this.bindLabel, item) : '';
  }

  get placeholder(): string {
    return this.to.placeholder || '';
  }

  get multiple(): boolean {
    return this.to.multiple || false;
  }

  get autocomplete(): boolean {
    return this.to.autocomplete || false;
  }

  get allOptions(): Observable<any> {
    return this.to.hidden ? of([]) : Array.isArray(this.to.options) ? of(this.to.options) : this.to.options;
  }

  get value(): string {
    return this.formControl.value || null;
  }

  get isDisabled(): boolean {
    return this.to.disabled;
  }

  set value(e: string) {
    this.selectedValue = e;
  }

  onChange(event: Event): void {
    if (!!event) {
      const id = getProperty(this.bindValue, event);
      this.formControl.setValue(id);
    }
  }

  onClear(): void {
    this.formControl.setValue(null);
  }
}
