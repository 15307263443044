import {APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfigModule} from '@ngx-config/core';
import {AppInitializerService} from './app-initializer.service';
import { DirectivesModule } from '@lib/directives/directives.module';

// @dynamic
// tslint:disable-next-line:typedef
export function appConfigFactory(appInitializerService: AppInitializerService) {
  return () => appInitializerService.loadAppConfig(['./assets/config.json']);
}

@NgModule({
  imports: [
    CommonModule,
    ConfigModule,
    DirectivesModule
  ],
  declarations: [],
  providers: [
    AppInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      multi: true,
      deps: [AppInitializerService]
    }
  ]

})
export class AppInitializerModule { }
