<div class="bg-white pt-3 pb-6 border-b border-solid border-gray-200">
  <div class="flex px-10 items-center">
    <div class="title-description flex items-center flex-col text-center mx-auto">
      <div class="title flex text-center">
        <!-- <i [innerHTML]="svgIcons.bank | safe"></i> -->
        <h1 class="text-xl font-semibold">Setup new workspace</h1>
      </div>
      <div class="description relative text-center" style="top: 1px">
        <h6 class="text-sm font-medium text-gray-400">
          Create additional workspace under current organization
        </h6>
      </div>
    </div>
    <!-- <div>
      <div (click)="goToGrid()" class="cursor-pointer">
        <i [innerHTML]="svgIcons.delete2 | safe"></i>
      </div>
    </div> -->
  </div>
</div>
<div>
  <div class="bg-white py-6 px-10">
    <form id="formSettingPermission" [formGroup]="form" (submit)="submit()" novalidate>
      <formly-form [fields]="formFields" [form]="form" [model]="workspace"></formly-form>
    </form>
  </div>
</div>
<div class="bg-white py-6 toolbar-bellow border-t border-solid border-gray-200 sticky bottom-0 z-50">
  <div class="flex px-10 justify-center">
    <button class="btn-light mr-3" (click)="cancel()">Cancel</button>
    <button form="formSettingPermission" class="btn-primary" [disabled]="saving">
      Create workspace <span *ngIf="saving">...</span>
    </button>
  </div>
</div>
