import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { AccountModel } from '../account-model/account-model';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { OrgUserModel } from '../user/org-user.model';

@Exclude()
export class ContactModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  ownerId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  firstName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  lastName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  email: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountId: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  preferredLanguage: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  title: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  salutation: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  reportsToId: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  phone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  homePhone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mobilePhone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  otherPhone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fax: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  leadSource: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  department: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  assistantName: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  assistantPhone: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mailingStreet: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mailingCity: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mailingCountry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mailingPostalCode: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  mailingState: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  otherStreet: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  otherCity: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  otherCountry: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  otherPostalCode: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  otherState: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: string | null;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  birthday: Date;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  doNotCall: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  emailOptOut: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  faxOptOut: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastActivityDate: Date | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastViewedDate: Date | null;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountModel)
  account?: AccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => ContactModel)
  reportsTo?: ContactModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  owner?: OrgUserModel;

  constructor(contact: Partial<ContactModel>) {
    super(contact);
    Object.assign(this, contact);
  }
}
