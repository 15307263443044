import { Action, createReducer, on } from '@ngrx/store';
import { IOrgState } from '../models';
import { OrgActions } from '../actions';
import { plainToClass } from 'class-transformer';
import { OrgUserModel } from '@lib/models';
import { ClassExposeGroups } from '@lib/enums';

export const initialState: IOrgState = {
  orgUser: null,
};

const orgReducerFn = createReducer<IOrgState>(
  initialState,
  on(OrgActions.setOrgUser, (state, { orgUser }) => ({
    ...state,
    orgUser,
  })),
  on(OrgActions.updateOrgUser, (state, { orgUser }) => ({
    ...state,
    orgUser: {
      ...state.orgUser,
      ...plainToClass(OrgUserModel, orgUser, { groups: [ClassExposeGroups.UPDATE] }),
      name: `${orgUser.firstName} ${orgUser.lastName}`,
    },
  })),
);

export function OrgReducer(state: IOrgState | undefined, action: Action) {
  return orgReducerFn(state, action);
}
