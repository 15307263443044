<ng-container [ngSwitch]="radioMode">
  <!-- Radio mode 1 -->
  <div *ngSwitchCase="1" class="flex">
    <div
      *ngFor="let option of options"
      (click)="changeOption(option)"
      [class.active]="option.value === selectedValue"
      class="box p-3 rounded mr-2 mt-1"
    >
      {{ option.label }}
    </div>
  </div>
</ng-container>
