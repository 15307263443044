export enum GlobalPermissions {
    ADMIN= 'admin_CRM',
}

export enum DocumentsPermissions {
    VIEW_ALL_DOCUMENT = 'view-all-document_CRM',
    VIEW_DOCUMENT = 'view-document_CRM',
    CREATE_DOCUMENT = 'create-document_CRM',
    UPDATE_DOCUMENT = 'update-document_CRM',
    DELETE_DOCUMENT = 'delete-document_CRM',
    DOWNLOAD_DOCUMENT = 'download-document_CRM',
}

export enum ClientPermissions {
    VIEW_ALL_CLIENT = 'view-all-client_CRM',
    VIEW_CLIENT = 'view-client_CRM',
    CREATE_CLIENT = 'create-client_CRM',
    UPDATE_CLIENT = 'update-client_CRM',
    DELETE_CLIENT = 'delete-client_CRM',
    ASSIGN_CLIENT = 'assign-client_CRM',
}

export enum NotePermissions {
    VIEW_ALL_NOTE = 'view-all-note_CRM',
    VIEW_NOTE = 'view-note_CRM',
    CREATE_NOTE = 'create-note_CRM',
    UPDATE_NOTE = 'update-note_CRM',
    DELETE_NOTE = 'delete-note_CRM',
}

export enum UserPermissions {
    VIEW_ALL_USER = 'view-all-user_CRM',
    VIEW_USER = 'view-user_CRM',
}
