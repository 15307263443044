import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { OrganizationModel } from '../organization/organization-model.class';
import { BaseModel } from './base-model.class';

@Exclude()
export class BaseOrgModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  orgId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => OrganizationModel)
  organization?: OrganizationModel | null;

  constructor(data: Partial<BaseOrgModel>) {
    super(data);
    Object.assign(this, data);
  }
}
