import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types/nullable.type';
import { BaseOrgWorkspaceModel } from '../../base-models/base-org-workspace-model.class';
import { PlatformServerModel } from '../platform-server/platform-server.model';

@Exclude()
export class AccountTypeModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  serverId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isActive: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountType: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => PlatformServerModel)
  server?: Nullable<PlatformServerModel>;

  constructor(accountType: Partial<AccountTypeModel>) {
    super(accountType);
    Object.assign(this, accountType);
  }
}
