/* tslint:disable:no-unused-expression */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvgIcons } from '../../../assets/svgs.enum';
import { GridHeader } from './shared/grid-header';
import { EventAction } from '../../../classes/event-action.class';
import { QuickAction } from '../../../classes/quick-action.class';
import { AgGridEvent } from 'ag-grid-community';
import { IView } from './shared/iview.interface';
import { ToastService } from '../../../services/toast/toast.service';
import { GridViewControls } from './shared/grid-view-controls.enum';
import { GridService } from '../shared/grid.service';
import { GridDisplayControls } from './shared/grid-display-controls.enum';

@Component({
  selector: 'lib-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
})
export class GridHeaderComponent implements OnInit {
  @Input() shortMode: boolean = false;
  @Input() emptyRecords: boolean;
  @Input() gridHeader: GridHeader;
  @Input() gridApi: AgGridEvent;
  @Output() handleActionClick: EventEmitter<EventAction<QuickAction>> = new EventEmitter<
    EventAction<QuickAction>
  >();

  placeholder: string;
  views: QuickAction[];
  viewControls: QuickAction[];
  displayControls: QuickAction[];
  hiredAction: QuickAction;
  editableSection: boolean;
  svgIcons = SvgIcons;

  private selectedView: QuickAction;
  private selectedDisplay: GridDisplayControls;

  constructor(private toastService: ToastService, private gridService: GridService) {}

  ngOnInit(): void {
    this.gridService.setGridName(this.gridHeader.name);
    this._generateViewList();
    this._createViewControls();
    this._loadDisplay();
    this._createDisplayControls();
    this._loadDefaultColumns();
  }

  clickAction(button: QuickAction): void {
    this.handleActionClick.emit({ key: button.key, data: button });
  }

  selectView(action: EventAction<QuickAction>): void {
    this.selectedView = action.data;
    this._changeSelectedViewOnLocalStorage(this.selectedView.name);
    this.gridService.updateGridView(this.selectedView.name);

    if (action.key === GridViewControls.RESET_VIEW) {
      this._resetViews();
    } else {
      this.views = this.views.map((i) => ({ ...i, selected: i.key === action.key }));
      const localViews: IView[] = this.gridService.getViewsFromLocalStorage();
      const { columns, filters } = localViews.find((i) => i.viewName === action.key);
      this._applyGridViews(columns, filters);
    }

    this._createViewControls();
  }

  clickViewControls(action: EventAction<QuickAction>): void {
    this.hiredAction = action.data;

    switch (this.hiredAction.key) {
      case GridViewControls.CREATE_VIEW:
      case GridViewControls.RENAME_VIEW:
        this.placeholder =
          this.hiredAction.key === GridViewControls.RENAME_VIEW ? this.selectedView.name : null;
        this.editableSection = true;
        break;
      case GridViewControls.UPDATE_VIEW:
        const columns = this.gridApi.columnApi.getColumnState();
        const filters = this.gridApi.api.getFilterModel();
        this._applyGridViews(columns, filters);
        this.toastService.showSuccess(
          `<b>Done!</b><br><small>Griv view <b>${this.selectedView.name}</b> updated!</small>`,
        );
        break;
      case GridViewControls.DELETE_VIEW:
        this.toastService.showSuccess(
          `<b>Deleted!</b><br>Griv view <b>${this.selectedView.name}</b> deleted completly`,
        );
        const newViews: IView[] = this.gridService
          .getViewsFromLocalStorage()
          .filter((i) => this.selectedView.name !== i.viewName);
        this.gridService.setViewsInLocalStorage(newViews);
        this._resetViews();
        break;
      default:
        console.warn('Dev Warning: Wrong action on Grid view controls');
    }
  }

  selectDisplay(action: EventAction<QuickAction>): void {
    switch (action.key) {
      case GridDisplayControls.TABLE:
      case GridDisplayControls.SPLIT_VIEW:
        if (this.selectedDisplay !== action.key) {
          this.displayControls = this.displayControls.map((item) => ({
            ...item,
            selected: item.key === action.key,
          }));
          this.gridService.updateGridDisplay(action.key);
          this.selectedDisplay = action.key;
          this.gridService.setDisplayInLocalStorage(action.key);
        }
    }
  }

  handleControl(viewName: string): void {
    const localViews: IView[] = this.gridService.getViewsFromLocalStorage();

    switch (this.hiredAction.key) {
      case GridViewControls.CREATE_VIEW:
        const columns = this.gridApi.columnApi.getColumnState();
        const filters = this.gridApi.api.getFilterModel();
        const views = localViews.map((i) => ({ ...i, selected: false }));
        const options: IView = {
          filters,
          columns,
          viewName,
          selected: true,
        };

        this.gridService.setViewsInLocalStorage([...views, options]);
        this._generateViewList();
        this.toastService.showSuccess(`<b>Done!</b><br>Griv view <b>${this.selectedView.name}</b> created!`);
        break;
      case GridViewControls.RENAME_VIEW:
        const newViews: IView[] = localViews.map((i) => ({
          ...i,
          viewName: i.viewName === this.selectedView.name ? viewName : i.viewName,
        }));
        this.gridService.setViewsInLocalStorage(newViews);
        this._generateViewList();
        this.toastService.showSuccess(
          `<b>Done!</b><br>Griv view changed to <b>${this.selectedView.name}</b>`,
        );
        break;
      default:
        console.warn('Dev Warning: Wrong action on Grid view controls');
    }
  }

  private _createViewControls(): void {
    const flag = this.selectedView.key === GridViewControls.RESET_VIEW;

    this.viewControls = [
      new QuickAction({ label: 'Grid view controls', permission: true }),
      new QuickAction({
        key: GridViewControls.UPDATE_VIEW,
        name: 'Update',
        permission: true,
        disable: flag,
      }),
      new QuickAction({
        key: GridViewControls.RENAME_VIEW,
        name: 'Rename',
        permission: true,
        disable: flag,
      }),
      new QuickAction({
        key: GridViewControls.DELETE_VIEW,
        name: 'Delete',
        permission: true,
        disable: flag,
      }),
      new QuickAction({ key: GridViewControls.CREATE_VIEW, name: 'Create grid view', permission: true }),
    ];
  }

  private _createDisplayControls(): void {
    this.displayControls = [
      new QuickAction({ label: 'Display grid as', permission: true }),
      new QuickAction({
        key: GridDisplayControls.TABLE,
        name: 'Table',
        permission: true,
        selected: this.selectedDisplay === GridDisplayControls.TABLE,
      }),
      new QuickAction({
        key: GridDisplayControls.SPLIT_VIEW,
        name: 'Split view',
        permission: true,
        selected: this.selectedDisplay === GridDisplayControls.SPLIT_VIEW,
      }),
    ];
  }

  private _generateViewList(): void {
    const localViews: IView[] = this.gridService.getViewsFromLocalStorage();
    const localViewsButtons: QuickAction[] = (localViews ?? []).map(
      (item) =>
        new QuickAction({
          key: item.viewName,
          name: item.viewName,
          permission: true,
          selected: item.selected,
        }),
    );

    this.views = [
      localViewsButtons.length && new QuickAction({ label: 'Templates', permission: true }),
      ...localViewsButtons,
      new QuickAction({ label: 'Default', permission: true }),
      new QuickAction({
        key: GridViewControls.RESET_VIEW,
        name: 'View all ' + this.gridHeader.name.toLowerCase(),
        permission: true,
        selected: localViewsButtons.every((i) => !i.selected),
      }),
    ];

    this.selectedView = this.views.find((view) => view.selected);
  }

  private _loadDefaultColumns(): void {
    const views = this.gridService.getViewsFromLocalStorage();
    const selected = views.find((v) => v.selected);
    selected && selected.columns && this._applyGridViews(selected.columns);
  }

  private _loadDisplay(): void {
    this.selectedDisplay = this.gridService.getDisplayInLocalStorage() || GridDisplayControls.TABLE;
  }

  private _resetViews(): void {
    this.gridApi.columnApi.resetColumnState();
    this.gridApi.api.resetQuickFilter();
    this._generateViewList();
  }

  private _applyGridViews(columns: any, filters?: any): void {
    this.gridApi.columnApi.applyColumnState({
      state: columns,
      applyOrder: true,
    });
    filters && this.gridApi.api.setFilterModel(filters);
  }

  private _changeSelectedViewOnLocalStorage(selectedName: string): void {
    const localViews: IView[] = this.gridService.getViewsFromLocalStorage();
    const views = localViews.map((i) => ({ ...i, selected: i.viewName === selectedName }));
    this.gridService.setViewsInLocalStorage(views);
  }
}
