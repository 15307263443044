import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions } from '../state-management/actions';
import { IAuthAppState } from '../state-management/models';

import { AuthService } from './shared/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authStore: Store<IAuthAppState>,
  ) {}

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const currentUser = this.authService.getUser();

    if (currentUser) {
      this.authStore.dispatch(AuthActions.setPlatformUser({ user: currentUser }));
      return true;
    }

    this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: state.url } }).then();
    return false;
  }
}
