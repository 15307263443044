import { Type } from 'class-transformer';
import { PhoneNumber } from '../../../../../lib/src/components/shared/phone/phone-number';

export class Register {
    name: string;
    password: string;
    email: string;

    @Type(() => PhoneNumber)
    phone?: PhoneNumber;

    organization: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
}
