import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { BreadcrumbComponent } from './breadcrumbs.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule
  ],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbsModule {
}
