import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-file-upload',
  template: `
    <lib-file-upload
      [required]="required"
      [id]="id"
      [field]="field"
      [allowedTypes]="allowedTypes"
      [formControl]="formaControl"
    >
    </lib-file-upload>
  `,
  styles: [],
})
export class FormlyFileUploadComponent extends FieldType {
  get allowedTypes(): string[] {
    return this.to.allowedTypes || [];
  }

  get required(): boolean {
    return this.to.required;
  }

  get formaControl(): FormControl {
    return this.formControl as FormControl;
  }
}
