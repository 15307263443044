export enum AwsCognitoErrors {
  INTERNAL_ERROR_EXCEPTION = 'InternalErrorException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  PASSWORD_RESET_REQUIRED_EXCEPTION = 'PasswordResetRequiredException',
  RESOURCE_NOT_FOUND_EXCEPTION = 'ResourceNotFoundException',
  TOO_MANY_REQUESTS_EXCEPTION = 'TooManyRequestsException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
  USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
  CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
  INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
  CODE_DELIVERY_FAILURE_EXCEPTION = 'CodeDeliveryFailureException'
}

export enum AwsS3Errors {
  ACCESS_DENIED = 'AccessDenied', // 403
  INVALID_BUCKET_NAME = 'InvalidBucketName', // 400
  INVALID_OBJECT_STATE = 'InvalidObjectState', // 403
  INVALID_POLICY_DOCUMENT = 'InvalidPolicyDocument', // 400
  NO_SUCH_KEY = 'NoSuchKey', // 404
  OPERATION_ABORTED = 'OperationAborted', // 409
  REQUEST_TIMEOUT = 'RequestTimeout', // 400
  SERVICE_UNAVAILABLE = 'ServiceUnavailable', // 503
  USER_KEY_MUST_BE_SPECIFIED = 'UserKeyMustBeSpecified' // 400
}

export enum GenericErrors {
  DEFAULT = 'InternalServerError',
  BAD_REQUEST = 'BadRequestError',
  NEW_PASSWORD_REQUIRED = 'NewPasswordRequired',
  AUTH_HEADERS_MISSING = 'AuthHeadersMissing',
  ACTION_ERROR = 'ActionError',
  UNAUTHORIZED = 'Unauthorized',
  ORG_USER_EXISTS = 'OrgUserExists',
  NOT_FOUND = 'NotFoundError',
  SERVICE_SETUP_MISSING = 'ServiceSetupMissing',
}
