import { Exclude, Expose } from 'class-transformer';
import { TargetModel } from './target.model';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { ContactModel } from '../contact/contact.model';
import { LeadModel } from '../lead/lead.model';

@Exclude()
export class TargetMemberModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  language: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  status: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  targetListId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  leadId?: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  contactId?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  contact?: Nullable<ContactModel>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lead?: Nullable<LeadModel>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  targetList?: Nullable<TargetModel>;
}
