import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthGuard } from './auth.guard';
import { AuthRouting } from './auth.routing';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthService } from './shared/auth.service';
import { RegisterComponent } from './register/register.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { FormModule } from '@lib/forms/form.module';
import { ToastModule } from '@lib/services/toast/toast.module';
import { MaterialModule } from '@lib/material.module';
import { SelectOrganizationComponent } from './select-organization/select-organization.component';
import { PipesModule } from '@lib/pipes/pipes.module';
import { AuthUserActionsService } from './shared/auth-user-actions.service';

@NgModule({
  imports: [
    FormModule,
    ToastModule,
    AuthRouting,
    CommonModule,
    MaterialModule,
    NgScrollbarModule,
    PipesModule,
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ConfirmRegistrationComponent,
    SelectOrganizationComponent,
  ],
  providers: [AuthGuard, AuthService, AuthUserActionsService],
})
export class AuthModule {}
