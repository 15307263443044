import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';
import { EmailIdentifierModel } from './identifier-model';

@Exclude()
export class SystemEmailModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  title: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  identifierId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  language: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  config?: Record<string, unknown>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isActive: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  @Type(() => EmailIdentifierModel)
  emailIdentifier?: EmailIdentifierModel;

  constructor(systemEmail: Partial<SystemEmailModel>) {
    super(systemEmail);
    Object.assign(this, systemEmail);
  }
}
