import { Component } from '@angular/core';
import { ResetPassword } from '../shared/reset-password.class';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { BaseFormComponent } from '../../../../../lib/src/classes/base-form-component.class';
import { ToastService } from '../../../../../lib/src/services/toast/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseFormComponent {

  resetPassword: ResetPassword = new ResetPassword();
  userEmail = '';

  constructor(private authService: AuthService,
              private toastService: ToastService,
              private router: Router,
              private route: ActivatedRoute) {

    super();
    this.userEmail = this.route.snapshot.params.email;
    this.createForm();
  }

  createForm(): void {
    this.formFields = [
      {
        key: 'username',
        type: 'input',
        hide: true,
        defaultValue: this.userEmail,
        templateOptions: {
          type: 'text',
          label: 'Email',
          placeholder: 'Email',
          required: true,
          readonly: true,
          mask: 'email'
        },
        validators: {
          validation: [Validators.compose([Validators.required, Validators.email])]
        }
      },
      {
        key: 'password',
        type: 'lib-password',
        focus: false,
        templateOptions: {
          label: 'New Password',
          placeholder: 'New Password',
          description: 'Password must have at least one letter, one number character and one special character',
          required: true,
          minLength: 8
        },
        validators: {
          validation: [
            'password-pattern',
          ]
        }
      },
      {
        key: 'confirmationCode',
        type: 'input',
        focus: false,
        templateOptions: {
          label: 'Code',
          placeholder: 'Code',
          description: 'Insert received code to confirm, Valid for 24h',
          required: true,
        },
        validators: {
          validation: Validators.compose([Validators.required])
        }
      },
    ];
  }

  save(): void {
    this.resetUserPassword();
  }

  private resetUserPassword(): void {
    this.setLoading(true);

    this.subscription = this.authService.resetPassword(this.resetPassword)
      .subscribe(() => {
        this.setLoading(false);
        this.toastService.showSuccess('Password has been reset successfully.');
        this.router.navigate(['auth', 'login']).then();
      }, error => {
        this.toastService.showError(error.error.message);
        this.setLoading(false);
      });
  }

}
