<div>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select id="tabs"
            name="tabs"
            class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
      <option selected>My Account</option>
      <option>Company</option>
      <option>Team Members</option>
      <option>Billing</option>
    </select>
  </div>

  <div class="hidden sm:block">
    <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
      <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->

      <a *ngFor="let tab of tabs"
         (click)="tabSelected(tab)"
         [ngClass]="tab.selected ? 'text-gray-900 rounded-l-lg' : 'text-gray-500 hover:text-gray-700 '"
         class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer">
        <span>{{tab.label}}</span>
        <span aria-hidden="true"
              [ngClass]="tab.selected ? 'bg-primary' : 'bg-transparent'"
              class=" absolute inset-x-0 bottom-0 h-0.5"></span>
      </a>
    </nav>
  </div>
</div>
