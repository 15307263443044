import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../classes/base-component.class';
import { SvgIcons } from '../../../assets/svgs.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResultDialog } from '../dialog/shared/result-dialog.interface';
import { QuickPanelCloseStatus } from '../../../layout/quick-panel/shared/quick-panel-close-status.enum';
import { Observable } from 'rxjs';
import { ToastService } from '../../../services/toast/toast.service';

export interface ConfirmationPopup {
  key?: string;
  title?: string;
  message: string;
  confirmActionLabel?: string;
  cancelActionLabel?: string;
  icon?: 'warning' | 'success';
  hideTitle?: boolean;
  service?: Observable<any>;
  response?: {
    success?: string;
    error?: string;
  };
}

@Component({
  selector: 'lib-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent extends BaseComponent implements OnInit {
  public svgIcons = SvgIcons;

  private popupResult: ResultDialog<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationPopup,
    private toastService: ToastService,
    private mdDialogRef: MatDialogRef<any>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.popupResult = {
      result: null,
      key: this.data.key,
      status: QuickPanelCloseStatus.CANCEL,
      isNew: false,
    };
  }

  cancel(): void {
    this.mdDialogRef.close(this.popupResult);
  }

  save(): void {
    this.setLoading(true);

    this.subscription = this.data.service.subscribe(
      (result) => {
        this.setLoading(false);
        this.toastService.showSuccess(this.successResponseMsg);
        this.popupResult = {
          ...this.popupResult,
          result,
          status: QuickPanelCloseStatus.SUCCESS,
        };
        this.mdDialogRef.close(this.popupResult);
      },
      (err) => {
        this.setLoading(false);
        this.toastService.showError(`${this.errorResponseMsg}<br> ${err.error.message}`);
        this.popupResult = {
          ...this.popupResult,
          result: err,
          status: QuickPanelCloseStatus.FAIL,
        };
        this.mdDialogRef.close(this.popupResult);
      },
    );
  }

  get title(): string {
    return this.data && this.data.title ? this.data.title : 'Confirmation Required';
  }

  get message(): string {
    return this.data && this.data.message ? this.data.message : 'Are you sure you want to proceed?';
  }

  get confirmActionLabel(): string {
    return this.data && this.data.confirmActionLabel ? this.data.confirmActionLabel : 'Confirm';
  }

  get cancelActionLabel(): string {
    return this.data && this.data.cancelActionLabel ? this.data.cancelActionLabel : 'Cancel';
  }

  get successResponseMsg(): string {
    return this.data && this.data?.response?.success ? this.data?.response?.success : `<b>Done!</b>`;
  }

  get errorResponseMsg(): string {
    return this.data && this.data?.response?.error
      ? this.data?.response?.error
      : `<b>Something is wrong:</b>`;
  }
}
