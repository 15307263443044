<div class="form">
  <h2>Sign Up</h2>
  <form [formGroup]="form" (submit)="submit()" novalidate>
    <formly-form [fields]="formFields" [form]="form" [model]="register">
      <div class="button">
        <button class="btn-rounded btn-lg" mat-stroked-button color="primary" [libSpinBtn]="saving">Sign Up</button>
        <button class="btn-rounded btn-lg" routerLink="/auth/login" mat-stroked-button>Cancel</button>
      </div>
    </formly-form>
  </form>
</div>
