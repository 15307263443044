<div class="flex flex-col relative" [class.has-error]="showError">
  <input
    [id]="id"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="to.placeholder"
    [readonly]="to.readonly"
    [tabindex]="to.tabindex || 0"
    [matDatepicker]="picker"
    (dateChange)="selectDate($event)"
  />

  <ng-container matSuffix>
    <div class="absolute right-0.5">
      <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
    </div>
  </ng-container>
  <mat-datepicker #picker></mat-datepicker>

  <!--<mat-label *ngIf="to.label && to.hideLabel !== true">-->
  <!--  {{ to.label }}-->
  <!--  <span *ngIf="to.required && to.hideRequiredMarker !== true" class="mat-form-field-required-marker">*</span>-->
  <!--</mat-label>-->

  <!--<mat-hint *ngIf="to.description" [id]="null">{{ to.description }}</mat-hint>-->

  <div class="input-error">
    <formly-validation-message *ngIf="showError && !field.wrappers.length" [field]="field">
    </formly-validation-message>
  </div>
</div>
