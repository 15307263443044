import { Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToastType } from '../toast-type.enum';

@Component({
  selector: 'lib-toast-template',
  templateUrl: './toast-template.component.html',
  styleUrls: ['./toast-template.component.scss'],
})
export class ToastTemplateComponent {
  toastType = ToastType;

  // @ts-ignore
  get icon(): string {
    switch (this.data.type) {
      case ToastType.SUCCESS:
        return 'check_circle';
      case ToastType.WARNING:
        return 'warning';
      case ToastType.ERROR:
        return 'error_outline';
    }
  }

  // @ts-ignore
  get color(): string {
    switch (this.data.type) {
      case ToastType.SUCCESS:
        return 'success';
      case ToastType.WARNING:
        return 'warning';
      case ToastType.ERROR:
        return 'danger';
    }
  }

  get backgroundColor(): string {
    switch (this.data.type) {
      case ToastType.SUCCESS:
        return 'success-light';
      case ToastType.WARNING:
        return 'warning-light';
      case ToastType.ERROR:
        return 'error-light';
    }
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { type; msg }, private _snackRef: MatSnackBar) {}

  dismiss(): void {
    this._snackRef.dismiss();
  }
}
