export class Menu {
  label?: string;
  route?: string;
  icon?: string;
  type?: 'item' | 'heading' = 'item';
  submenu?: Menu[] = [];
  isOpen?: boolean;
  show: (() => boolean) | boolean = true;
  permission: boolean = true;

  constructor(menu: Partial<Menu>) {
    Object.assign(this, menu);
  }

  get hasSubmenu(): boolean {
    return this.submenu.length > 0 && this.submenu.some((menu) => menu.isVisible);
  }

  get isHeading(): boolean {
    return this.type === 'heading';
  }

  get isVisible(): boolean {
    if (this.permission) {
      return true;
    } else {
      let show;
      show = this.show instanceof Function ? this.show() : this.show;

      // hide parent if childs not exist
      if (this.submenu.length) {
        return this.hasSubmenu && show;
      }

      return show;
    }
  }
}
