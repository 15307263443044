import { createAction, props } from '@ngrx/store';
import { PickList } from '@lib/models';

const setForPlatformServer = createAction('[Pick list] Platform server', props<{ pickList: PickList }>());
const setForBank = createAction('[Platform server] Banks', props<{ pickList: PickList }>());
const setForSite = createAction('[Platform server] Sites', props<{ pickList: PickList }>());

export const PickListActions = {
  setForPlatformServer,
  setForBank,
  setForSite,
};
