import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

  get regButtonShow(): boolean {
    return !this.location.path().contains('/register');
  }

  constructor(private location: Location) {
  }


}
