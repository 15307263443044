import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authKey } from '../constants';
import { IAuthState } from '../models';

const selectWorkspaceState = createFeatureSelector<IAuthState>(authKey);

const selectAll = createSelector(selectWorkspaceState, (state: IAuthState) => state.workspaces);
const selectWorkspace = createSelector(selectWorkspaceState, (state: IAuthState) => state.workspace);
const selectWorkspaces = createSelector(selectWorkspaceState, (state: IAuthState) => state.workspaces);
const selectSelected = createSelector(selectWorkspaceState, (state: IAuthState) => state.workspace);
const selectWorkspaceID = createSelector(selectWorkspaceState, (state: IAuthState) => state.workspaceID);
const selectWsUserActions = createSelector(selectWorkspaceState, (state: IAuthState) => state.actions);
const selectPlatformUser = createSelector(selectWorkspaceState, (state: IAuthState) => state.platformUser);

export const fromAuth = {
  selectAll,
  selectWorkspace,
  selectWorkspaces,
  selectSelected,
  selectWorkspaceID,
  selectWsUserActions,
  selectPlatformUser,
};
