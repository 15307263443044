import { TagInputComponent } from './types/advanced/tag-input.component';
import { NumberInputComponent } from './types/basic/number-input.component';
import { CountryPickerComponent } from './types/advanced/country-picker.component';
import { CurrencyPickerComponent } from './types/advanced/currency-picker.component';
import { FormControl, ValidationErrors } from '@angular/forms';
import { PasswordComponent } from './types/basic/password/password.component';
import { FormlyFormFieldWrapperComponent } from './wrappers/formly-form-field-wrapper.component';
import { FormlyFileUploadComponent } from './types/advanced/formly-file-upload.component';
import { DatePickerComponent } from './types/basic/date-picker/date-picker.component';
import { SecondPhoneSelectComponent } from './types/advanced/second-phone-select.component';
import { HorizontalInputComponent } from './wrappers/horizontal-input.component';
import { TextInputComponent } from './types/basic/text-input/text-input.component';
import { NumberInputComponent as MyNumberInputComponent } from './types/basic/number-input/number-input.component';
import { VerticalInputComponent } from './wrappers/vertical-input.component';
import { RadioComponent } from './types/basic/radio/radio.component';
import { SelectorComponent } from './types/advanced/selector/selector.component';
import { PasswordInputComponent } from './types/basic/password-input/password-input.component';
import { AdditionalFieldsComponent } from './types/basic/additional-fields/additional-fields.component';
import { PanelWrapperComponent } from './wrappers/panel/panel.component';
import { AnimationWrapperComponent } from './wrappers/animation-wrapper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ToggleFormlyComponent } from './types/basic/toggle/toggle.component';
// import { FormlyDateTimePickerComponent } from './types/advanced/date-time-picker/date-time-picker.component';

export const FORMLY_CONFIG = {
  types: [
    { name: 'tag-input', component: TagInputComponent },
    { name: 'number-input', component: NumberInputComponent },
    { name: 'country-picker', component: CountryPickerComponent },
    { name: 'currency-picker', component: CurrencyPickerComponent },
    { name: 'lib-password', component: PasswordComponent },
    { name: 'phone-select', component: SecondPhoneSelectComponent },
    { name: 'file-upload', component: FormlyFileUploadComponent, wrappers: ['form-field'] },
    { name: 'date-picker', component: DatePickerComponent },
    // { name: 'date-time-picker', component: FormlyDateTimePickerComponent },
    { name: 'my-input', component: TextInputComponent },
    { name: 'number', component: MyNumberInputComponent },
    { name: 'my-number', component: MyNumberInputComponent },
    { name: 'radio', component: RadioComponent },
    { name: 'toggle', component: ToggleFormlyComponent },
    { name: 'selector', component: SelectorComponent },
    { name: 'my-password', component: PasswordInputComponent },
    { name: 'optionals', component: AdditionalFieldsComponent },
  ],
  wrappers: [
    { name: 'panel', component: PanelWrapperComponent },
    { name: 'form-field', component: FormlyFormFieldWrapperComponent },
    { name: 'horizontal', component: HorizontalInputComponent },
    { name: 'vertical', component: VerticalInputComponent },
    { name: 'animation', component: AnimationWrapperComponent },
  ],
  validators: [
    { name: 'ip', validation: ipValidator },
    { name: 'password-pattern', validation: passwordPatternValidator },
    { name: 'password-reactive-pattern', validation: passwordReactiveValidator },
    { name: 'alfa-numeric', validation: alfaNumericValidator },
    { name: 'numeric', validation: numericValidator },
  ],
  validationMessages: [
    { name: 'email', message: emailError },
    { name: 'date', message: dateError },
    { name: 'required', message: requiredError },
    { name: 'equal', message: requiredError },
    { name: 'equalTo', message: notEqualError },
    { name: 'validatePhone', message: phoneError },
    { name: 'fileType', message: fileTypeError },
    { name: 'url', message: urlError },
    { name: 'invalidEmailAddress', message: 'Invalid Email Address' },
    { name: 'maxlength', message: 'Maximum Length Exceeded' },
    { name: 'minlength', message: minLengthError },
    { name: 'not_matching', message: 'Password Not Matching' },
    { name: 'zipCode', message: 'ZIP code should be 5 characters' },
    { name: 'ip', message: ipError },
    { name: 'alfa-numeric', message: alfaNumericError },
    { name: 'numeric', message: numericError },
    { name: 'password-pattern', message: passwordPatternError },
    { name: 'password-reactive-pattern', message: passwordReactiveError },
    { name: 'server-error', message: errorMsg },
    { name: 'client-error', message: errorMsg },
  ],
  extensions: [{ name: 'animation', extension: { onPopulate: animationExtension } }],
  i18nValidationMessages: [
    {
      name: 'between',
      params: [
        { key: 'min', field: 'err', property: 'min' },
        { key: 'max', field: 'err', property: 'max' },
        { key: 'label', field: 'field', property: 'templateOptions.label' },
        { key: 'value', field: 'field', property: 'templateOptions.label' },
      ],
    },
    {
      name: 'email',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'date',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'required',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'equal',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'equalTo',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'validatePhone',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'url',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'invalidEmailAddress',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'maxlength',
      params: [{ key: 'value', field: 'err', property: 'requiredLength' }],
    },
    {
      name: 'minlength',
      params: [{ key: 'value', field: 'err', property: 'requiredLength' }],
    },
    {
      name: 'not_matching',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'zipCode',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'contains_symbol',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'contains_number',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'contains_lowercase',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'contains_uppercase',
      params: [{ key: 'value', field: 'field', property: 'templateOptions.label' }],
    },
    {
      name: 'max',
      params: [{ key: 'value', field: 'err', property: 'max' }],
    },
    {
      name: 'min',
      params: [{ key: 'value', field: 'min', property: 'min' }],
    },
  ],
};

export function animationExtension(field: FormlyFieldConfig) {
  if (field.wrappers && field.wrappers.includes('animation')) {
    return;
  }

  field.wrappers = ['animation', ...(field.wrappers || [])];
}

export function errorMsg(err): string {
  return err.toString();
}

export function fileTypeError(err: string, field: any): string {
  return 'File input does not accept file of type ' + err;
}

export function emailError(err: any, field: any): string {
  return field.templateOptions.label + ' is not a valid email';
}

export function alfaNumericError(err: any, field: any): string {
  return field.templateOptions.label + ' has an alfa numeric word';
}

export function numericError(err: any, field: any): string {
  return field.templateOptions.label + ' is not numeric word';
}

export function dateError(err: any, field: any): string {
  return field.templateOptions.label + ' is not a valid date';
}

export function requiredError(err: any, field: any): string {
  return field.templateOptions.label + ' is required';
}

export function phoneError(err: any, field: any): string {
  return field.templateOptions.label + ' is not a valid phone';
}

export function notEqualError(err: any, field: any): string {
  return field.templateOptions.label + ' do not match';
}

export function minLengthError(err: any, field: any): string {
  return 'Should have at least ' + err.requiredLength + ' characters.';
}

export function urlError(err: any, field: any): string {
  return field.templateOptions.label + ' is not a valid url.';
}

export function passwordPatternError(err: any, field: any): string {
  return `Must have at least one letter and one number character`;
}

export function ipError(err: any, field: any): string {
  return `Wrong IP format`;
}

export function passwordReactiveError(err: any, field: any): string {
  return err;
}

export function ipValidator(control: FormControl): ValidationErrors {
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
  return !control.value || regexExp.test(control.value) ? null : { ip: true };
}

export function alfaNumericValidator(control: FormControl): ValidationErrors {
  const regexExp = /[^a-zA-Z0-9_ ]/g;
  return !control.value || regexExp.test(control.value) ? { 'alfa-numeric': true } : null;
}

export function numericValidator(control: FormControl): ValidationErrors {
  const regexExp = /[^0-9]/g;
  return control.value && regexExp.test(control.value) ? { numeric: true } : null;
}

export function passwordPatternValidator(control: FormControl): ValidationErrors {
  return !control.value || /([0-9].*[a-zA-Z])|([a-zA-Z].*[0-9])/.test(control.value)
    ? null
    : { 'password-pattern': true };
}

export function passwordReactiveValidator(control: FormControl): ValidationErrors {
  const table: string[] = [];

  if (control.value) {
    const formatSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!/[A-Z]/.test(control.value)) {
      table.push('capital letter');
    }

    if (!/\d/.test(control.value)) {
      table.push('number');
    }

    if (!formatSpecialCharacter.test(control.value)) {
      table.push('special');
    }

    if (table.length > 1) {
      table.splice(table.length - 1, 0, 'and one');
    }

    const message =
      'Must have at least one ' + table.join(', ').replace('and one,', 'and one') + ' character.';

    return table.length ? { 'password-reactive-pattern': message } : null;
  } else {
    return null;
  }
}
