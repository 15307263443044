export enum AppHeaders {
    X_DOMAIN = 'x-domain',
    X_ORG_ID = 'x-org-id',
    X_BUNDLE_ID = 'x-bundle-id',
    X_WORKSPACE_ID = 'x-workspace-id',
    X_ORG_SCOPED = 'x-org-scoped',
    X_ORG_WORKSPACE_SCOPED = 'x-org-workspace-scoped',
    X_Request_ID = 'x-request-id',
    X_ACTION_SCOPED = 'x-action-scoped',
}
