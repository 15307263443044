import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CountryService } from './shared/country.service';
import { Country } from './shared/country.class';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoService } from '../shared/phone/geo.service';
import { BaseComponent } from '../../classes/base-component.class';

@Component({
  selector: 'lib-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CountryPickerComponent)
    }
  ]
})
export class CountryPickerComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  @Input()
  autocomplete: boolean = false;

  @Input()
  multiple: boolean = false;

  @Input()
  clearable: boolean = true;

  @Input()
  placeholder: string;

  @Input()
  formlyAttributes: any;

  @Input()
  formControl: FormControl;

  @Input()
  hideLocalErrorDetection: boolean;

  countries: Country[] = [];
  selected: string | string[];

  constructor(private countryService: CountryService,
              private geoService: GeoService) {
    super();
    this.countries = this.countryService.getAll();
  }

  ngOnInit(): void {
    if (this.autocomplete && !this.multiple && !this.selected) {
      this.subscription = this.geoService.getCountry().subscribe(geoData => {
        if (!geoData.country) {
          return;
        }
        this.selected = geoData.country.toLowerCase();
        this.propagateChange(this.selected);
      });
    }

    if (!this.placeholder) {
      this.placeholder = this.multiple ? 'Select Countries' : 'Select Country';
    }
  }

  selectionChanged(country: Country | Country[]): void {

    if (country instanceof Array) {
      this.propagateChange(country.map(item => item.code));
    } else if (!country) {
      this.propagateChange(null);
    } else {
      this.propagateChange(country.code);
    }
  }

  propagateChange = (_: any) => {
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: string | string[]): void {

    if (value !== undefined) {
      this.selected = value;
    }
  }
}
