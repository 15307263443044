import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { BaseModel } from '../base-models/base-model.class';

@Exclude()
export class SystemUserLicenseModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  masterLabel: boolean;
  constructor(data: Partial<SystemUserLicenseModel>) {
    super(data);
    Object.assign(this, data);
  }
}
