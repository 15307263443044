<div [ngClass]="color" class="toast flex toast-service">
  <div class="icon-section flex items-center w4rAnimated_checkmark" [class]="backgroundColor">
    <ng-container *ngIf="data.type === toastType.SUCCESS; else showIcon">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle
          class="path circle"
          fill="none"
          stroke="#35881B"
          stroke-width="06"
          stroke-miterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          class="path check"
          fill="none"
          stroke="#35881B"
          stroke-width="06"
          stroke-linecap="round"
          stroke-miterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
    </ng-container>
  </div>
  <div class="content-section rounded-r flex items-center">
    <span [innerHTML]="data.msg | safe"></span>
  </div>

  <div class="close" (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </div>
</div>

<ng-template #showIcon>
  <mat-icon [color]="color">{{ icon }}</mat-icon>
</ng-template>
