/* tslint:disable:no-unused-expression */
import { ICellRenderer } from 'ag-grid-community';
import { AccountModel, ContactModel, DocumentContentModel } from '../../../models';
import { OrgUserModel } from '../../../models/user/org-user.model';
import { CellRendererClickHandler } from '../shared/cell-click-params.interface';

type Doc<T> = Pick<T, keyof T> & {
  userId: string;
  accountId: string;
  contactId: string;
  contact?: ContactModel;
  account?: AccountModel;
  user?: OrgUserModel;
};

export default class DocumentRelated implements ICellRenderer {
  public eGui: any;
  public eValue: any;
  public params: CellRendererClickHandler;

  public init(params: CellRendererClickHandler): void {
    this.params = params;
    const value = !!params.getValue();

    this.eGui = value ? this.generateTemplate(params) : undefined;
    value && this.addListeners();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: CellRendererClickHandler): any {
    this.params = params;
    this.eGui = params.getValue() ? this.generateTemplate(params) : undefined;
  }

  public generateTemplate = (params: CellRendererClickHandler): HTMLElement => {
    const eGui = document.createElement('div');

    const { name, entity } = this.getDocumentValue(params.data);

    eGui.innerHTML =
      '<div class="flex flex-col cursor-pointer text-gray-800 leading-none	"> ' +
      `<a class="text-gray-800">${name}</a>
       <span class="text-xs text-gray-600">${entity}</span>
      </div>`;
    return eGui;
  };

  public getDocumentValue(doc: DocumentContentModel) {
    if (!!doc.accountId) {
      return {
        name: doc.account.name,
        entity: 'Account',
      };
    }

    if (!!doc.contactId) {
      return {
        name: doc.contact.name,
        entity: 'Contact',
      };
    }

    if (!!doc.userId) {
      return {
        name: doc.user.name,
        entity: 'User',
      };
    }
  }

  public addListeners(): void {
    this.clickedHandler = this.clickedHandler.bind(this);
    this.eGui.addEventListener('click', this.clickedHandler);

    this.middleClickHandler = this.middleClickHandler.bind(this);
    this.eGui.addEventListener('auxclick', this.middleClickHandler);
  }

  public clickedHandler(): void {
    this.params.clicked(this.params.data);
  }

  public middleClickHandler(): void {
    this.params.middleClicked(this.params.data);
  }

  public destroy(): void {
    if (!!this.eGui) {
      this.eGui.removeEventListener('click', this.clickedHandler);
      this.eGui.removeEventListener('auxclick', this.middleClickHandler);
    }
  }
}
