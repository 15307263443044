import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums/api-crud-mode.enum';
import { Nullable } from '../../../types';
import { BaseOrgWorkspaceAuditModel } from '../../base-models/base-org-workspace-audit-model.class';
import { PermissionSetLicenseModel } from '../permission-set-license/permission-set-license-model.class';
import { ProfileModel } from '../../profile/profile-model.class';
import { UserLicenseModel } from '../../user-license/user-license-model.class';

@Exclude()
export class PermissionSetModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  label: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  userLicenceId?: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  permissionSetLicenseId: Nullable<string>;

  @Expose({ groups: [ClassExposeGroups.CREATE, ClassExposeGroups.UPDATE] })
  PermissionSetDescription: string;

  @Expose({ groups: [ClassExposeGroups.CREATE, ClassExposeGroups.UPDATE] })
  permissionSetName: string;

  @Expose({ groups: [ClassExposeGroups.CREATE, ClassExposeGroups.UPDATE] })
  permissionSetLabel: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  profileId?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isCustom: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  isOwnedByProfile: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionSetType: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  description?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  systemPermissionSetId?: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => UserLicenseModel)
  userLicense?: UserLicenseModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => PermissionSetLicenseModel)
  permissionSetLicense?: PermissionSetLicenseModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => ProfileModel)
  profile?: ProfileModel;

  constructor(permissionSet: Partial<PermissionSetModel>) {
    super(permissionSet);
    Object.assign(this, permissionSet);
  }
}
