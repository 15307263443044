import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITab } from '../shared/tab.interface';

@Component({
  selector: 'lib-tab-bar-underline',
  templateUrl: './tab-bar-underline.component.html',
  styleUrls: ['./tab-bar-underline.component.scss']
})
export class TabBarUnderlineComponent {

  @Input() tabs: ITab[];
  @Output() handleTabSelection: EventEmitter<ITab> = new EventEmitter<ITab>();


  tabSelected(tab: ITab): void {
    this.tabs = this.tabs.map(item => ({...item, selected: tab.key === item.key}));
    this.handleTabSelection.emit(tab);
  }

}
