import { Component } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BaseComponent } from '@lib/classes/base-component.class';
import { TranslationService } from '@lib/i18n/language-translation/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseComponent {
  language: string;

  constructor(private translationService: TranslationService, private router: Router) {
    super();

    // this.initializeTranslations();
  }

  private initializeTranslations(): void {
    // Set language from url parameter
    this.subscription = this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        this.language = val.state.root.firstChild.params.lang;
        if (this.language) {
          this.translationService.setLanguage(this.language);
        } else {
          // Set Default language
          this.translationService.setLanguage();
        }
      }
    });

    this.translationService.initFormTranslations();
  }
}
