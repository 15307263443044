import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types/nullable.type';
import { BaseOrgWorkspaceModel } from '../../base-models/base-org-workspace-model.class';

@Exclude()
export class PlatformServerModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  host: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  port: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  login: number;

  @Expose({ groups: [ClassExposeGroups.CREATE, ClassExposeGroups.UPDATE] })
  password: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  environment: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  platformType: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  serverFull: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  minDeposit: number;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  maxDeposit: number;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  maxAutoWithdrawal: number;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  defaultLeverage: number;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  minTradingAccount: Nullable<number>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  maxTradingAccount: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  windowsUrl: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  macUrl: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  iosUrl: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  androidUrl: string;

  constructor(platformServer: Partial<PlatformServerModel>) {
    super(platformServer);
    Object.assign(this, platformServer);
  }
}
