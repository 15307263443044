import { Injectable } from '@angular/core';
import { IView } from '../grid-header/shared/iview.interface';
import { Observable, Subject } from 'rxjs';
import { GridDisplayControls } from '../grid-header/shared/grid-display-controls.enum';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  gridName: string;
  private selectedGridView = new Subject<string>();
  private selectedDisplayView = new Subject<GridDisplayControls>();

  constructor() {
  }

  setGridName(gridName: string): void {
    this.gridName = gridName;
  }

  // START GRID_VIEW
  setViewsInLocalStorage(views: IView[]): void {
    localStorage.setItem('GRID_' + this.gridName, JSON.stringify(views));
  }

  getViewsFromLocalStorage(): IView[] {
    const getViews = localStorage.getItem('GRID_' + this.gridName);
    return getViews ? JSON.parse(getViews) : [];
  }

  getSelectedViewFromLocalStorage(): IView {
    return this.getViewsFromLocalStorage().find(view => view.selected);
  }

  updateGridView(name: string): void {
    this.selectedGridView.next(name);
  }

  getGridView(): Observable<string> {
    return this.selectedGridView.asObservable();
  }

  // -------- END GRID_VIEW


  // START GRID_DISPLAY
  setDisplayInLocalStorage(name: GridDisplayControls): void {
    localStorage.setItem('GRID_DISPLAY_' + this.gridName, name);
  }

  getDisplayInLocalStorage(): GridDisplayControls | undefined {
    return localStorage.getItem('GRID_DISPLAY_' + this.gridName) as GridDisplayControls;
  }

  updateGridDisplay(key: GridDisplayControls): void {
    this.selectedDisplayView.next(key);
  }

  getGridDisplay(): Observable<GridDisplayControls> {
    return this.selectedDisplayView.asObservable();
  }

  // --------- END GRID_DISPLAY

}
