import { Action, createReducer, on } from '@ngrx/store';
import { IEmailState } from '../models';
import { EmailActions } from '../actions';

export const initialState: IEmailState = {
  pickList: null,
};

const EmailReducerFn = createReducer<IEmailState>(
  initialState,
  on(EmailActions.setEmailPickList, (state: IEmailState, { pickList }) => ({
    ...state,
    pickList,
  })),
);

export function EmailReducer(state: IEmailState | undefined, action: Action) {
  return EmailReducerFn(state, action);
}
