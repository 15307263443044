import { Action, createReducer, on } from '@ngrx/store';
import { ITradingManagerState } from '../models';
import { TradingManagerActions } from '../actions';

export const initialState: ITradingManagerState = {
  accountType: null,
  platformGroups: undefined,
  platformServer: null,
  questionnaire: null,
  questionnairePickList: null,
  accountTypeForm: null,
};

const TradingManagerReducerFn = createReducer<ITradingManagerState>(
  initialState,

  on(TradingManagerActions.setPlatformServer, (state, { server }) => ({
    ...state,
    platformServer: server,
  })),
  on(TradingManagerActions.setAccountType, (state, { accountType }) => ({
    ...state,
    accountType,
  })),
  on(TradingManagerActions.setQuestionnaire, (state, { questionnaire }) => ({
    ...state,
    questionnaire,
  })),
  on(TradingManagerActions.setAccountTypeGroups, (state, { groups }) => ({
    ...state,
    platformGroups: groups,
  })),
  on(TradingManagerActions.assignGroup, (state, { groups }) => ({
    ...state,
    platformGroups: [...state.platformGroups, ...groups],
  })),
  on(TradingManagerActions.unassignGroup, (state, { groupId }) => ({
    ...state,
    platformGroups: state.platformGroups.filter((g) => g.id !== groupId),
  })),
  on(TradingManagerActions.accountTypeForm, (state, { accountType }) => ({
    ...state,
    accountTypeForm: accountType,
  })),
  on(TradingManagerActions.setQuestionnairePickList, (state, { pickList }) => ({
    ...state,
    questionnairePickList: pickList,
  })),
);

export function TradingManagerReducer(state: ITradingManagerState | undefined, action: Action) {
  return TradingManagerReducerFn(state, action);
}
