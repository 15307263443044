import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums/api-crud-mode.enum';
import { BaseOrgWorkspaceModel } from '../../base-models/base-org-workspace-model.class';
import { SystemPermissionSetLicenseModel } from '../../system-permission-set-license/system-permission-set-license-model.class';

@Exclude()
export class PermissionSetLicenseModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  isActive: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  totalLicenses: number;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  usedLicenses: number;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  systemPermissionSetLicenseId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  @Type(() => SystemPermissionSetLicenseModel)
  systemPermissionSetLicense?: SystemPermissionSetLicenseModel;

  constructor(data: Partial<PermissionSetLicenseModel>) {
    super(data);
    Object.assign(this, data);
  }
}
