import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateMoment'
})
export class DateMomentPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string | Date, dateFormat: string): any {
    return moment(value).format(dateFormat).trim();
  }

}
