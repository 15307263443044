import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';
import { FieldDefinitionModel } from '../common-models/field-definition.model';

@Exclude()
export class SiteRegistrationFieldModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  fieldDefinitionId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  networkId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  sortOrder: number;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isRequired: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isEnabled: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  @Type(() => FieldDefinitionModel)
  fieldDefinition: FieldDefinitionModel;
}
