import { Injectable } from '@angular/core';
import { Country } from './country.class';
import { COUNTRIES } from '@org/common-cross-lib';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countries: Country[] = [];

  constructor() {
    COUNTRIES.forEach(country => {
      this.countries.push(new Country(country.code, country.name, country.dialingCode));
    });
  }

  get(code: string): Country {
    return this.countries.find(item => item.code.toLowerCase() === code.toLowerCase());
  }

  getAll(): Country[] {
    return this.countries;
  }

}
