import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { Nullable } from '../../types';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { OrgUserModel } from '../user/org-user.model';

@Exclude()
export class CampaignModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  ownerId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  parentCampaignId: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  type: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  active: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  status: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  expectedRevenue: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  budgetedCost: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  actualCost: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  expectedResponse: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfLeads: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfConvertedLeads: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfContacts: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberOfResponses: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  numberSend: Nullable<number>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: Nullable<string>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  startDate: Nullable<Date>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  endDate: Nullable<Date>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  lastActivityDate: Nullable<Date>;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  lastViewedDate: Nullable<Date>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  parentCampaign?: Nullable<CampaignModel>;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  owner?: Nullable<OrgUserModel>;

  constructor(campaign: Partial<CampaignModel>) {
    super(campaign);
    Object.assign(this, campaign);
  }
}
