import {
  AccountTypeModel,
  PickList,
  PlatformGroupModel,
  PlatformServerModel,
  QuestionnaireModel,
} from '@lib/models';
import { createAction, props } from '@ngrx/store';

const setQuestionnaire = createAction(
  '[Trading Manager] Set questionnaire',
  props<{ questionnaire: QuestionnaireModel }>(),
);
const setQuestionnairePickList = createAction(
  '[Trading Manager] Set questionnaire pickList',
  props<{ pickList: PickList }>(),
);

const setPlatformServer = createAction(
  '[Trading Manager] Set server',
  props<{ server: PlatformServerModel }>(),
);

const setAccountType = createAction(
  '[Trading Manager] Set account type',
  props<{ accountType: AccountTypeModel }>(),
);

const accountTypeForm = createAction(
  '[Trading Manager] Account type form',
  props<{ accountType: AccountTypeModel }>(),
);

const setAccountTypeGroups = createAction(
  '[Trading Manager] Set account type assigned groups',
  props<{ groups: PlatformGroupModel[] }>(),
);

const unassignGroup = createAction('[Trading Manager] Unassign group', props<{ groupId: string }>());

const assignGroup = createAction('[Trading Manager] Assign group', props<{ groups: PlatformGroupModel[] }>());

export const TradingManagerActions = {
  setQuestionnaire,
  setQuestionnairePickList,
  setPlatformServer,
  setAccountType,
  setAccountTypeGroups,
  assignGroup,
  unassignGroup,
  accountTypeForm,
};
