import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseModel } from './base-model.class';
import { BaseOrgWorkspaceAuditModel } from './base-org-workspace-audit-model.class';
import { OrgUserModel } from '../user/org-user.model';

@Exclude()
export class BaseSharingModel<T extends BaseModel> extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accessLevel: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  parentId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  rowCause: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  userId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  groupId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => BaseModel)
  parent?: BaseModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  user?: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  // @Type(() => any)
  group?: any;

  constructor(sharing: Partial<BaseSharingModel<T>>) {
    super(sharing);
    Object.assign(this, sharing);
  }
}
