import { Component } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { BaseFormComponent } from '@lib/classes/base-form-component.class';
import { ToastService } from '@lib/services/toast/toast.service';

class Confirmation {
  username: string;
  confirmationCode: string;
}

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent extends BaseFormComponent {

  confirmCode: Confirmation = new Confirmation();
  userEmail = '';
  confButton = false;

  constructor(private authService: AuthService,
              private toastService: ToastService,
              private router: Router,
              private route: ActivatedRoute) {

    super();
    this.userEmail = this.route.snapshot.params.email;
    this.createForm();
  }

  createForm(): void {
    this.formFields = [
      {
        key: 'username',
        type: 'input',
        hide: true,
        defaultValue: this.userEmail,
        templateOptions: {
          type: 'text',
          label: 'Email',
          placeholder: 'Email',
          required: true,
          readonly: true,
          mask: 'email'
        },
        validators: {
          validation: Validators.compose([Validators.required, Validators.email])
        }
      },
      {
        key: 'confirmationCode',
        type: 'input',
        focus: false,
        templateOptions: {
          label: 'Code',
          placeholder: 'Code',
          description: 'Insert received code to confirm, Valid for 24h',
          required: true,
        },
        validators: {
          validation: Validators.compose([Validators.required])
        }
      },
    ];
  }

  save(): void {
    this.confirmRegistration();
  }

  resendConfCode(): void {
    this.subscription = this.authService.resentConfirmationCode(this.userEmail).subscribe();
  }

  private confirmRegistration(): void {
    this.setLoading(true);

    this.subscription = this.authService.confirmRegistration(this.confirmCode).subscribe(() => {
      this.setLoading(false);
      this.toastService.showSuccess('Registration confirmed successfully.');
      this.router.navigate(['auth', 'login']).then();
    }, error => {
      this.toastService.showError(error.error?.message);
      if (error.error && error.error.error === 'ExpiredCodeException') {
        this.confButton = true;
      }
      this.setLoading(false);
    });
  }
}
