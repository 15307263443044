export type Maybe<T> = T | void;

export const isDefined = <T>(x: Maybe<T>): x is T => {
  return x !== undefined && x !== null;
};

export const isUndefined = <T>(x: Maybe<T>): x is void => {
  return x === undefined || x === null;
};

export const getOrElse = <T>(x: Maybe<T>, defaultValue: T): T => {
  return isDefined(x) ? x : defaultValue;
};

export const getOrElseDefault = <T, U>(x: Maybe<T>, defaultValue: U): T | U => {
  return isDefined(x) ? x : defaultValue;
};

export const getOrElseThrow = <T>(x: Maybe<T>, exception: Error): T => {
  if (isDefined(x)) {
    return x;
  } else {
    throw exception;
  }
};

export const getOrElseAction = <T, U>(x: Maybe<T>, action: Function): T | U => {
  if (isDefined(x)) {
    return x;
  } else {
    return action();
  }
};

export const getOrElseAsyncFn = async <T>(x: Maybe<T>, fn: () => Promise<T>): Promise<T> => {
  if (isDefined(x)) {
    return x;
  } else {
    return await fn();
  }
};
