import { Exclude, Expose } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseModel } from '../base-models/base-model.class';

@Exclude()
export class EntityDefinitionModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  label: string;

  constructor(entityDefinition: EntityDefinitionModel) {
    super(entityDefinition);
    Object.assign(this, entityDefinition);
  }
}
