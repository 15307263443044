import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface IRadioOption {
  label: string;
  value: string | number | boolean;
  active?: boolean;
  description?: string;
}

@Component({
  selector: 'lib-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit {
  @Input() options: IRadioOption[];
  @Input() defValue: string | number | boolean;
  @Input() radioMode: number = 1;

  @Output() handleSelectedRadio: EventEmitter<IRadioOption> = new EventEmitter<IRadioOption>();

  public selectedValue: string | number | boolean;

  ngOnInit(): void {
    this.selectedValue = !!this.defValue || typeof this.defValue === 'boolean' ? this.defValue : null;
  }

  changeOption(option: IRadioOption): void {
    this.selectedValue = option.value;
    this.handleSelectedRadio.emit(option);
  }
}
