import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types';
import { BaseOrgWorkspaceModel } from '../../base-models/base-org-workspace-model.class';
import { EntityDefinitionModel } from '../../shared/shared-models';

@Exclude()
export class ObjectPermissionModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdDate: Date;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionSetId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  entityDefinitionId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionRead: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionCreate: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionEdit: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionDelete: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionViewAllRecords: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  permissionModifyAllRecords: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => EntityDefinitionModel)
  entityDefinition?: EntityDefinitionModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  systemObjectPermissionId: Nullable<string>;

  constructor(objectPermission: Partial<ObjectPermissionModel>) {
    super(objectPermission);
    Object.assign(this, objectPermission);
  }
}
