import { Injectable, Type } from '@angular/core';
import { AllowedAction } from '@lib/interfaces';
import { InvitationUserModel, PermissionSetAssignment, WorkspaceUserModel } from '@lib/models';
import { EntityCrudService } from '@lib/services/api/entity-crud.service';
import { map, Observable } from 'rxjs';
import { ClassExposeGroups } from '@lib/enums';
import { environment } from '../../../environments/environment';
import { RouterConstant } from '../../core/route-constants';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceUserService extends EntityCrudService<WorkspaceUserModel> {
  public baseUrl: string = environment.apiBaseHref;
  public resourceType: Type<WorkspaceUserModel> = WorkspaceUserModel;
  public resourcePath: string = RouterConstant.workspaceUser.apiEndpoint;

  updateUser(newUser: InvitationUserModel, userId: string): Observable<WorkspaceUserModel> {
    const url = `${this.getPath()}/${userId}`;
    return this.http
      .put<WorkspaceUserModel>(url, newUser)
      .pipe(map((response) => this.handleResponse(response, [ClassExposeGroups.UPDATE])));
  }

  updateTheUser(wsUser: WorkspaceUserModel, userId: string): Observable<WorkspaceUserModel> {
    const url = `${this.getPath()}/${userId}`;
    const _wsUser = this.handleResponse(wsUser, [ClassExposeGroups.UPDATE]);
    return this.http
      .put<WorkspaceUserModel>(url, _wsUser)
      .pipe(map((response) => this.handleResponse(response, [ClassExposeGroups.UPDATE])));
  }

  allowedActions(): Observable<AllowedAction[]> {
    const url = `${this.getPath()}/allowed-actions`;
    return this.http.get<AllowedAction[]>(url);
  }

  invite(newUser: InvitationUserModel): Observable<boolean> {
    const url = `${this.getPath()}/invite`;
    return this.http.post<boolean>(url, newUser);
  }

  activeInactiveUser(wsUser: WorkspaceUserModel, active = true): Observable<WorkspaceUserModel> {
    const { userId, rowVersion } = wsUser;
    const url = `${this.getPath()}/${userId}/status`;
    const body = {
      rowVersion,
      isActive: active,
    };
    return this.http.put<WorkspaceUserModel>(url, body);
  }

  permissionSetAssignment(permissionSetAssignment: PermissionSetAssignment): Observable<boolean> {
    const url: string = `${this.getPath()}/permission-set`;
    return this.http.post<boolean>(url, permissionSetAssignment);
  }
}
