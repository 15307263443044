<mat-sidenav-container [hasBackdrop]="false" [autosize]="true">
  <mat-sidenav mode="side" [opened]="true" [disableClose]="true">
    <lib-side-menu [menus]="menus"></lib-side-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header-toolbar></app-header-toolbar>

    <ng-scrollbar track="all" pointerEventsMethod="scrollbar" [class.default]="true">
      <div class="page">
        <div class="page-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-scrollbar>
  </mat-sidenav-content>
</mat-sidenav-container>
