import { Component } from '@angular/core';
import { AgFilterComponent } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { SingleFilter } from '../../../../filters/filter-model.class';


@Component({
  selector: 'lib-boolean-component',
  templateUrl: './boolean-template.component.html',
  styleUrls: ['./boolean-template.component.scss']
})
export class BooleanTemplateComponent implements AgFilterComponent {
  params: IFilterParams;
  active: boolean;
  flag: boolean = false;
  activeFilter: SingleFilter = {
    filter: false,
    type: 'equals',
    filterType: 'boolean'
  };

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    this.flag = true;
    return !!this.active;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return !!params.data.active;
  }

  getModel(): any {
    return this.flag ? this.activeFilter : null;
  }

  setModel(model: any): void {
  }

  updateFilter(): void {
    this.active = !this.active;
    this.activeFilter.filter = this.active;
    this.params.filterChangedCallback();
  }

}
