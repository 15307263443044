import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalErrorHandler } from '@lib/services/global-error-handler';
import { HttpInterceptorService } from '@lib/services/server-error.interceptor';
import { ToastModule } from '@lib/services/toast/toast.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { RouterConstant } from './core/route-constants';
import { ErrorModule } from './error/error.module';
import { LayoutModule } from './layout/layout.module';
import { AuthReducer } from './state-management/reducers';
import { WorkspaceStateModule } from './workspace-state.module';

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/general/', suffix: '.json' },
    { prefix: './assets/i18n/dashboard/', suffix: '.json' },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AuthModule,
    ErrorModule,
    BrowserAnimationsModule,
    ToastModule,
    LayoutModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    WorkspaceStateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(
      [
        {
          path: '**',
          pathMatch: 'full',
          redirectTo: '/' + RouterConstant.home.routePath,
        },
      ],
      { enableTracing: false },
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    StoreModule.forRoot([AuthReducer]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    ,
    { provide: 'environment', useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
