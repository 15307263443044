import { ICellRendererParams } from 'ag-grid-community';

export const yesNo = (params): HTMLElement =>
  params.data ? generateTemplate(params) : undefined;


const generateTemplate = (params: ICellRendererParams): HTMLElement => {
  const eGui = document.createElement('div');
  const value = params.getValue();

  eGui.innerHTML = value
    ? `<span class="text-success">Yes</span>`
    : `<span class="text-danger">No</span>`;
  return eGui;
};

