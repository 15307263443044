import { Exclude, Expose, Type } from "class-transformer";
import { ClassExposeGroups } from "../../enums/api-crud-mode.enum";
import { BaseModel } from "../base-models/base-model.class";
@Exclude()
export class OrganizationStatusModel extends BaseModel {
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  value: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  label: string;
  
  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.UPDATE] })
  isDefault: string;

  constructor(data: Partial<OrganizationStatusModel>) {
    super(data);
    Object.assign(this, data);
  }
}
