import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceModel } from '../base-models/base-org-workspace-model.class';
import { EntityDefinition } from './entity-definition.model';

@Exclude()
export class FieldDefinitionModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  entityDefinitionId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  qualifiedFieldName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  label: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  dataType: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  databaseDatatype: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isNullable: boolean;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  @Type(() => EntityDefinition)
  entityDefinition: EntityDefinition;
}
