import * as moment from 'moment/moment';
import { ICellRendererParams } from 'ag-grid-community';

export const date = (params): HTMLElement => (params.data ? generateTemplate(params) : undefined);

const generateTemplate = (params: ICellRendererParams): HTMLElement => {
  const eGui = document.createElement('div');
  eGui.innerHTML = getDate(params.getValue());
  return eGui;
};

const getDate = (value): any => {
  return !!value
    ? moment(value).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MMM DD, YYYY',
      })
    : '-';
};
