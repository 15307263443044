import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums/api-crud-mode.enum';
import { OrgUserModel } from '../user/org-user.model';
import { BaseOrgWorkspaceModel } from './base-org-workspace-model.class';

@Exclude()
export class BaseOrgWorkspaceAuditModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  createdBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  lastModifiedBy: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  createdByUser?: OrgUserModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  lastModifiedByUser?: OrgUserModel;

  constructor(data: Partial<BaseOrgWorkspaceAuditModel>) {
    super(data);
    Object.assign(this, data);
  }
}
