import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseFormComponent } from '../../../classes/base-form-component.class';
import { QuickDialog } from './shared/quick-dialog.class';

@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent extends BaseFormComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuickDialog,
    private mdDialogRef: MatDialogRef<DialogComponent>,
  ) {
    super();
  }

  ngOnInit(): void {}

  public cancel(): void {
    this.close(false);
  }

  public close(value): void {
    this.mdDialogRef.close(value);
  }

  public confirm(): void {
    this.close(true);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close(false);
  }

  save(): void {}
}
