import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../enums';
import { AccountModel } from '../account-model/account-model';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { OrgUserModel } from '../user/org-user.model';

@Exclude()
export class BankModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountNumber: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  iban: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  swift: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  bankCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  locationCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  beneficiaryName: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  beneficiaryAddress: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  currencyIsoCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  address: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  city: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  routingCode: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  country: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  isActive: boolean;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountModel)
  account?: AccountModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  owner?: OrgUserModel;

  constructor(bank: Partial<BankModel>) {
    super(bank);
    Object.assign(this, bank);
  }
}
