import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'lib-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent extends FieldType {

  hide = true;

  get placeholder(): string {
    return this.to.placeholder;
  }

  get label(): string {
    return this.to.label;
  }

  get required(): boolean {
    return this.to.required;
  }

  get mask(): string {
    return this.field.templateOptions.mask || '';
  }

  get type(): string {
    return this.to.type;
  }

  get wrapper(): string {
    return this.field.wrappers[0];
  }
}
