import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogForm } from '../../base';
import { WorkspaceUserExpandable } from '../../enums/list-expands/workspace-user-expandable';
import { QuickPanelCloseStatus } from '../../layout/quick-panel/shared/quick-panel-close-status.enum';
import { WorkspaceUserModel } from '../../models';
import { EntityCrudService } from '../../services/api/entity-crud.service';
import { ToastService } from '../../services/toast/toast.service';
import { EntityModel } from '../../types/entity.type';
import { QuickDialog } from '../dialog/dialog/shared/quick-dialog.class';

@Component({
  selector: 'lib-change-owner',
  templateUrl: './change-owner.component.html',
})
export class ChangeOwnerComponent extends BaseDialogForm<EntityModel> implements OnInit {
  public entity: EntityModel = new EntityModel({});
  private service: EntityCrudService<EntityModel>;
  private wsUserService: EntityCrudService<WorkspaceUserModel>;
  private key: string;
  private currentOwnerId: string;

  constructor(
    private toastService: ToastService,
    private mdDialogRef: MatDialogRef<EntityModel>,
    @Inject(MAT_DIALOG_DATA) public data: QuickDialog,
  ) {
    super();
    const { entity, service, key, wsUserService } = this.data;

    this.dialogRef = this.mdDialogRef;
    this.key = key;
    this.service = service;
    this.wsUserService = wsUserService;
    this.currentOwnerId = this.data.entity.ownerId;
    this.entity = {
      ...this.entity,
      rowVersion: entity.rowVersion,
      id: entity.id,
      ownerId: entity.ownerId,
    };
  }

  ngOnInit(): void {
    this._createForm();
  }

  save(): void {
    this.currentOwnerId === this.entity.ownerId
      ? this.toastService.showWarning('You have selected the same owner')
      : this._changeOwner();
  }

  private _changeOwner(): void {
    this.subscription = this.service.changeOwner(this.entity).subscribe({
      next: (res) => {
        this.toastService.showSuccess('<b>Success:</b> Owner changed');
        this.close({ status: QuickPanelCloseStatus.SUCCESS, result: res, key: this.key, reload: true });
      },
      error: (error) => {},
    });
  }

  private _createForm(): void {
    this.formFields = [
      {
        fieldGroupClassName: 'flex w-flex flex-wrap  ',
        fieldGroup: [
          {
            key: 'ownerId',
            className: 'flex-half',
            type: 'selector',
            wrappers: ['vertical'],
            templateOptions: {
              label: 'Assigned to',
              placeholder: 'Select an owner to assign current entity',
              options: this.wsUserService.getList(undefined, [WorkspaceUserExpandable.ORG_USER]),
              required: true,
              bindLabel: 'orgUser.name',
              bindValue: 'userId',
            },
          },
        ],
      },
    ];
  }
}
