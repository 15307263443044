import { Exclude, Expose, Type } from 'class-transformer';
import { OrgUserModel } from '../user/org-user.model';
import { ClassExposeGroups } from '../../enums';
import { BaseOrgWorkspaceAuditModel } from '../base-models/base-org-workspace-audit-model.class';
import { AccountModel } from '../account-model/account-model';
import { LeadModel } from '../lead/lead.model';
import { ContactModel } from '../contact/contact.model';

@Exclude()
export class DocumentModel extends BaseOrgWorkspaceAuditModel {
  @Expose({ groups: Object.values(ClassExposeGroups) })
  name: string;

  @Expose({ groups: [ClassExposeGroups.VIEW, ClassExposeGroups.CREATE] })
  @Type(() => Object)
  file: File;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  filePrivacy?: boolean | string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  description?: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  expiresAt: Date | string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  fileType?: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  documentStatus: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  accountId: string;

  @Expose({ groups: Object.values(ClassExposeGroups) })
  contactId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  fileExtension: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => OrgUserModel)
  owner?: OrgUserModel;

  // @Expose({ groups: [ClassExposeGroups.VIEW] })
  // @Type(() => AccountModel)
  // account?: AccountModel;

  // @Expose({ groups: [ClassExposeGroups.VIEW] })
  // @Type(() => LeadModel)
  // lead?: LeadModel;

  // @Expose({ groups: [ClassExposeGroups.VIEW] })
  // @Type(() => ContactModel)
  // contact?: ContactModel;

  constructor(document: Partial<DocumentModel>) {
    super(document);
    Object.assign(this, document);
  }
}
