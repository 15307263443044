import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventAction } from '../../../classes/event-action.class';
import { QuickAction } from '../../../classes/quick-action.class';
import { SvgIcons } from '../../../assets/svgs.enum';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'lib-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() actions?: QuickAction[];
  @Input() name?: string;
  @Input() classes?: string = '';
  @Input() xPosition?: 'left' | 'right' = 'left';
  @Input() selection?: boolean;
  @Input() icon?: SafeHtml;
  @Input() img?: string;

  @Output() handleBulkActionClick: EventEmitter<EventAction<QuickAction>> = new EventEmitter<
    EventAction<QuickAction>
  >();

  svgIcons = SvgIcons;

  clickBulkAction(button: QuickAction): void {
    this.handleBulkActionClick.emit({ key: button.key, data: button });
  }
}
