/* tslint:disable:no-unused-expression */
import { ICellRenderer } from 'ag-grid-community';
import { CellRendererClickHandler } from '../shared/cell-click-params.interface';

export default class Redirection implements ICellRenderer {
  public eGui: any;
  public eValue: any;
  public params: CellRendererClickHandler;

  public init(params: CellRendererClickHandler): void {
    this.params = params;
    const value = !!params.getValue();

    this.eGui = value ? this.generateTemplate(params) : this.generateNone();
    value && this.addListeners();
  }

  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params: CellRendererClickHandler): any {
    this.params = params;
    this.eGui = params.getValue() ? this.generateTemplate(params) : this.generateNone();
  }

  public generateTemplate = (params: CellRendererClickHandler): HTMLElement => {
    const eGui = document.createElement('div');
    const value = params?.fullName ? `${params.data.firstName} ${params.data.lastName}` : params.getValue();

    eGui.innerHTML = !!value
      ? '<div class="flex cursor-pointer text-gray-800 "> ' + `<a class="text-gray-800">${value}</a></div>`
      : '-';
    return eGui;
  };

  public generateNone = (): HTMLElement => {
    const eGui = document.createElement('div');
    eGui.innerHTML = '-';
    return eGui;
  };

  public addListeners(): void {
    this.clickedHandler = this.clickedHandler.bind(this);
    this.eGui.addEventListener('click', this.clickedHandler);

    this.middleClickHandler = this.middleClickHandler.bind(this);
    this.eGui.addEventListener('auxclick', this.middleClickHandler);
  }

  public clickedHandler(): void {
    this.params.clicked(this.params.data);
  }

  public middleClickHandler(): void {
    this.params.middleClicked(this.params.data);
  }

  public destroy(): void {
    if (!!this.eGui) {
      this.eGui.removeEventListener('click', this.clickedHandler);
      this.eGui.removeEventListener('auxclick', this.middleClickHandler);
    }
  }
}
