import { Injectable, Type } from '@angular/core';
import { AppHeaders } from '@org/common-cross-lib';
import { plainToClass } from 'class-transformer';
import { BaseComponent } from '../../base';
import { EntityCrudService } from './entity-crud.service';

@Injectable({
  providedIn: 'root',
})
export abstract class UtilsService<T> extends BaseComponent {
  abstract baseUrl: string;
  abstract resourcePath: string;
  abstract resourceType: Type<T>;
  abstract nestedEntityCrudService: EntityCrudService<T>;
  abstract nestedId: string;

  public setActionScope(action: string): Record<string, string> {
    return {
      [AppHeaders.X_ACTION_SCOPED]: action,
    };
  }

  public getPathId(id: string): string {
    return this.getPath() + `/${id}`;
  }

  public getPath(nested?: string): string {
    return !!nested ? `${nested}/${this.resourcePath}` : `${this.baseUrl}/${this.resourcePath}`;
    // return !!this.nestedId && !!this.nestedEntityCrudService
    //   ? `${this.nestedEntityCrudService.getPath()}/${this.nestedId}/${this.resourcePath}`
    //   : `${this.baseUrl}/${this.resourcePath}`;
  }

  public handleManyResponse(response: unknown, exposeGroups: string[] = []): T[] {
    return exposeGroups.length
      ? plainToClass(this.resourceType, response as T[], { groups: exposeGroups })
      : plainToClass(this.resourceType, response as T[]);
  }

  public handleResponse(response: unknown, exposeGroups: string[] = []): T {
    return exposeGroups.length
      ? (plainToClass(this.resourceType, response, { groups: exposeGroups }) as T)
      : (plainToClass(this.resourceType, response) as T);
  }
}
