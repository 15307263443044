import { Exclude, Expose, Type } from 'class-transformer';
import { ClassExposeGroups } from '../../../enums';
import { Nullable } from '../../../types';
import { BaseOrgWorkspaceModel } from '../../base-models/base-org-workspace-model.class';
import { AccountTypeModel } from '../account-type/account-type.model';
import { GroupModel } from './group-model';

@Exclude()
export class PlatformGroupModel extends BaseOrgWorkspaceModel {
  @Expose({ groups: [ClassExposeGroups.VIEW] })
  currencyIsoCode: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  accountTypeId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  platformGroupId: string;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => GroupModel)
  platformGroup?: GroupModel;

  @Expose({ groups: [ClassExposeGroups.VIEW] })
  @Type(() => AccountTypeModel)
  accountType?: Nullable<AccountTypeModel>;

  constructor(group: Partial<PlatformGroupModel>) {
    super(group);
    Object.assign(this, group);
  }
}
