import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAuthAppState } from '../../state-management/models';
import { fromAuth } from '../../state-management/selectors';
import { AuthService } from '../shared/auth.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  private workspaceId: string | null;

  constructor(private inj: Injector, private authStore: Store<IAuthAppState>) {
    this.authStore.select(fromAuth.selectWorkspaceID).subscribe((workspaceId) => {
      this.workspaceId = workspaceId;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip if is request to load config
    if (req.url.endsWith('config.json') || req.url.includes('auth/')) {
      return next.handle(req);
    }

    const authService = this.inj.get(AuthService);
    const token = authService.getAuthToken();
    const accessToken = authService.getAccessToken();
    const org = authService.getOrganization();

    if (token) {
      const initHeaders = {
        Authorization: 'Bearer ' + token,
        'x-auth-token': 'Bearer ' + accessToken,
        'x-org-id': org,
      };
      const headers = this.workspaceId ? { ...initHeaders, 'x-workspace-id': this.workspaceId } : initHeaders;

      req = req.clone({
        setHeaders: headers,
      });
    }

    return next.handle(req);
  }
}
