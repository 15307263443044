import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

import { Menu } from '../shared/menu.class';

const SIDE_NAV_COLLAPSED = 'SIDE_NAV_COLLAPSED';

@Component({
  selector: 'lib-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() menus: Menu[] = [];

  @Input() arrowOnLeft = false;
  @Output() collapseChange = new EventEmitter<boolean>();
  @HostBinding('class') className = 'sidenav dark closed';

  private _collapse = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    if (width <= 960 && !this._collapse) {
      this.toggle(true);
    }
  }

  @Input() set collapse(val) {
    this._collapse = !!val;
    this.className = this._collapse ? 'sidenav dark closed' : 'sidenav dark';
  }

  get collapse(): boolean {
    return this._collapse;
  }

  constructor() {
    this.collapse = localStorage.getItem(SIDE_NAV_COLLAPSED) === 'true';
    this.sizeOnLoad();
  }

  toggle(collapse: boolean) {
    this.collapse = collapse;
    this.collapseChange.emit(collapse);
    localStorage.setItem(SIDE_NAV_COLLAPSED, collapse.toString());
  }

  toggleMenu(menu) {
    menu.isOpen = !menu.isOpen;
  }

  sizeOnLoad() {
    if (window.innerWidth <= 960 && !this._collapse) {
      this.toggle(true);
    }
  }
}
